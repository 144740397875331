import AppLayout from "@container/app-layout";
import pageNotFound404 from "@asset/images/page_not_found_404.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function PageNotFound() {
  return (
    <AppLayout>
      <Stack
        direction="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        gap="8px"
        sx={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <img style={{ width: "350px" }} src={pageNotFound404} alt="404" />
        <Stack
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          gap="24px"
          sx={{
            width: {
              lg: "382px",
              sm: "382px",
              xs: "100%",
            },
          }}
        >
          <Typography variant="h3">Page not found</Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            It seems the page you’re looking for doesn’t exist. Don’t worry,
            let’s get you back on track!
          </Typography>
        </Stack>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          size="large"
          sx={{ height: "42px !important" }}
          variant="contained"
        >
          Return to Dashboard
        </Button>
      </Stack>
    </AppLayout>
  );
}

export default PageNotFound;
