import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    // @ts-ignore
    values: {
      xl: 1536,
      lg: 1240,
      md: 800,
      sm: 386,
      xs: 0,
    },
  },
  palette: {
    primary: {
      light: "#5BE49B",
      main: "#2F4B73",
      dark: "#1E3455",
    },
    secondary: {
      main: "#FFFFFF80",
      dark: "#FFFFFF",
    },
    error: {
      main: "#870909",
    },
    warning: {
      main: "#ED9B00",
    },
    success: {
      main: "#94BE1A",
    },
  },
  typography: {
    h1: {
      fontSize: "60px",
      lineHeight: "72px",
      fontWeight: "700",
      fontFamily: `BasisBold, sans-serif`,
    },
    h2: {
      fontSize: "42px",
      lineHeight: "50.4px",
      fontWeight: "700",
      fontFamily: `BasisBold, sans-serif`,
    },
    h3: {
      fontSize: "32px",
      lineHeight: "38.4px",
      fontWeight: "700",
      fontFamily: `BasisBold, sans-serif`,
    },
    h4: {
      fontSize: "25px",
      lineHeight: "30px",
      fontWeight: "700",
      fontFamily: `BasisBold, sans-serif`,
    },
    h5: {
      fontSize: "20px",
      lineHeight: "24px",
      fontFamily: `BasisBold, sans-serif`,
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      fontFamily: `Inter, sans-serif`,
    },
    // @ts-ignore
    body14: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      letterSpacing: "0.15px",
      fontFamily: `Inter, sans-serif`,
    },
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      letterSpacing: "0.15px",
      fontFamily: `Inter, sans-serif`,
    },
    caption: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "18px",
      fontFamily: `Inter, sans-serif`,
      letterSpacing: "0.15px",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
          borderRadius: "8px",
          padding: "8px",
          "&.MuiAlert-colorError": {
            border: "1px solid #D9232329",
            background: "#D9232329",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "21px",
          letterSpacing: "0.15px",
          color: "#000000DE",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
          borderRadius: "8px",
          boxShadow: "none",
          borderColor: "#D8D8D8",
          background: "#FFFFFF",
          "&.Mui-disabled": {
            borderColor: "#00000014",
            background: "#0000000A",
          },
          "&.Mui-focused": {
            borderWidth: "1px",
            outlined: "1px solid #222222",
            borderColor: "#222222",
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #222222",
            },
            "&.Mui-error": {
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D92323",
              },
            },
          },
          "&.Mui-error": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#D92323",
            },
          },
        },
        input: {
          padding: "12px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
          borderRadius: "8px",
          boxShadow: "none",
          textTransform: "inherit",
          "&:hover": {
            boxShadow: "none",
          },

          "&.MuiButton-containedSecondary": {
            border: "1px solid #2222223D",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21px",
          },
          "&.MuiButton-colorPrimary .Mui-disabled": {
            background: "#DED9D2",
          },
        },
        sizeLarge: {
          padding: "16px 24px",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "24px",
        },
        sizeMedium: {
          padding: "9px 16px",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "21px",
          height: "36px",
          letterSpacing: "0.15px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          fontFamily: `Inter, sans-serif`,
          borderRadius: "8px",
          boxShadow: "0px 4px 8px 0px #00000029",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
          color: "#2F4B73",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "21px",
          letterSpacing: "0.15px",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.MuiMenuItem-root:hover": {
            backgroundColor: "#2F4B73",
          },
          "&.Mui-selected .MuiTypography-root, &.MuiMenuItem-root:hover .MuiTypography-root":
            {
              color: "#FFFFFF ",
            },
          "&.Mui-selected": {
            backgroundColor: "#2F4B73",
            color: "#FFFFFF !important",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: `Inter, sans-serif`,
        },
        sizeSmall: {
          fontSize: "10px",
        },
      },
    },
  },
});

export default theme;
