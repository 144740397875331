import { ChartBarIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import { getUserLifeStatus, getUserSummary } from "@service/user";
import { UserLifeStatus, UserSummary } from "@type/user";
import { useUserProfileContext } from "context/user-profile-context";
import { parseFormat } from "@util/date";
import { useTheme } from "@mui/material/styles";
import { getCoachInfo } from "@service/coach";
import { CoachInfo } from "@type/coach";
import { useUserSubscriptionContext } from "context/user-subscription-context";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { catchErrorLog } from "@util/sentry";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Alert from "components/alert";
import BoxProgress from "components/box-progress";
import LandscapeRounded from "@asset/images/landscape-rounded.svg";
import LandscapeRoundedIcon from "@mui/icons-material/LandscapeRounded";
import RateSession from "@container/rate-session";
import GrowthGuide from "@container/growth-guide";
import BookGrowthGuide from "@container/book-growth-guide";
import LifeFocus from "@container/life-focus";
import CoachSchedule from "@container/coach-schedule";
import BookingGrowthGuideDetail from "@container/booking-growth-guide-sidebar";
import YourMilestone from "@container/your-milestone";
import PuzzlePiece from "@asset/images/puzzle-piece-large.svg";
import Skeleton from "@component/skeleton";
import SessionNoteSidebar from "@container/session-note-sidebar";
import MilestoneBox from "@component/milestone-box";
import DrawerBookingGrowthGuide from "@container/drawer-booking-growth-guide";
import DrawerBookingDetailGrowthGuide from "@container/drawer-booking-detail";
import DrawerSessionNote from "@container/drawer-session-note";
import DrawerMilestone from "@container/drawer-milestone";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import AlertSubscription from "@component/alert-subscription";
import BannerLockSubscription from "@component/banner-lock-subscription";
import Link from "@mui/material/Link";

dayjs.extend(relativeTime);

function HomePage() {
  const theme = useTheme();
  const isMediaQuerySM = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const isHideAlertWelcomeSession =
    sessionStorage.getItem("__HIDE_ALERT_WELCOME_") ?? "";
  const [isOpenAlertWelcome, setIsOpenAlertWelcome] = useState<boolean>(
    // eslint-disable-next-line prettier/prettier
    isHideAlertWelcomeSession === "1" ? false : true
  );
  const [retryUserSummaryCall, setRetryUserSummaryCall] = useState<number>(0);
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const [sidebarType, setSidebarType] = useState<string>("");
  const [isLoadingUserSummary, setLoadingUserSummary] =
    useState<boolean>(false);
  const [isLoadingUserSubscription, userSubscriptionInfo] =
    useUserSubscriptionContext();
  const [isLoadingLifeStatus, setLoadingLifeStatus] = useState<boolean>(false);
  const [userSummary, setUserSummary] = useState<UserSummary | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );
  const [userLifeStatusInfo, setUserLifeStatus] = useState<
    UserLifeStatus | undefined
  >(undefined);
  const [isLoadingCoachInfo, setLoadingCoachInfo] = useState<boolean>(true);
  const [coachInfo, setCoachInfo] = useState<CoachInfo | undefined>(undefined);
  const [countReloadBookingInfo, setCountReloadBookingInfo] =
    useState<number>(0);
  const [isOpenDrawerBooking, setOpenDrawerBooking] = useState<boolean>(false);
  const [isOpenDrawerBookingDetail, setOpenDrawerBookingDetail] =
    useState<boolean>(false);
  const [isOpenDrawerSessionNote, setOpenDrawerSessionNote] =
    useState<boolean>(false);
  const [isOpenDrawerMilestone, setOpenDrawerMilestone] =
    useState<boolean>(false);

  const onCloseAlertWelcom = () => {
    sessionStorage.setItem("__HIDE_ALERT_WELCOME_", "1");
    setIsOpenAlertWelcome(false);
  };

  const reloadUserSummary = () => {
    setLoadingUserSummary(true);
    getUserSummary()
      .then((resp) => {
        setLoadingUserSummary(false);
        setUserSummary(resp.data.data);
      })
      .catch(() => {
        setRetryUserSummaryCall((prevState) => {
          return prevState + 1;
        });
      });
  };

  useEffect(() => {
    if (retryUserSummaryCall > 0 && retryUserSummaryCall <= 5) {
      reloadUserSummary();
    }
  }, [retryUserSummaryCall]);

  useEffect(() => {
    reloadUserSummary();

    setLoadingLifeStatus(true);
    getUserLifeStatus()
      .then((resp) => {
        setLoadingLifeStatus(false);
        setUserLifeStatus(resp.data.data);
      })
      .catch((err) => {
        setLoadingLifeStatus(false);
        catchErrorLog(err?.response?.data.message);
      });
  }, []);

  useEffect(() => {
    if (!isLoadingUserProfile) {
      getCoachInfo(userInfo?.booking.assign_growth_guide_id || "")
        .then((resp) => {
          setLoadingCoachInfo(false);
          setCoachInfo(resp.data.data);
        })
        .catch((err) => {
          setLoadingCoachInfo(false);
          catchErrorLog(err?.response?.data.message);
        });
    }
  }, [isLoadingUserProfile, userInfo?.booking.assign_growth_guide_id]);

  const onCloseSidebar = () => {
    setSidebarType("");
    setIsOpenSidebar(false);
  };

  const onHandleBookingSession = () => {
    console.log(isMediaQuerySM);
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("BOOKING_SESSION");
    } else {
      setOpenDrawerBooking(true);
    }
  };

  const onHandleBookingDetail = () => {
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("BOOKING_DETAIL");
    } else {
      setOpenDrawerBookingDetail(true);
    }
  };

  const onHandleSessionNote = () => {
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("SESSION_NOTE");
    } else {
      setOpenDrawerSessionNote(true);
    }
  };

  const onHandleMilestone = () => {
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("MILESTONE");
    } else {
      setOpenDrawerMilestone(true);
    }
  };

  return (
    <>
      <AppLayout
        isOpenSidebar={isOpenSidebar}
        isShowAlertSubscription={
          userSubscriptionInfo?.status !== "active" ||
          !!userSubscriptionInfo.canceled_at ||
          false
        }
        alertSubscription={
          !isLoadingUserSubscription && (
            <AlertSubscription userSubscriptionInfo={userSubscriptionInfo} />
          )
        }
        sidebar={
          <Box sx={{ width: "360px" }}>
            {sidebarType === "BOOKING_SESSION" && (
              <CoachSchedule
                onCloseSidebar={onCloseSidebar}
                onReloadBookingInfo={() => {
                  setCountReloadBookingInfo((prevState) => {
                    return prevState + 1;
                  });
                }}
                notes={userSummary?.users_growth_guide_preparation_brief}
              />
            )}
            {sidebarType === "BOOKING_DETAIL" && (
              <BookingGrowthGuideDetail
                onCloseSidebar={onCloseSidebar}
                onReloadBookingInfo={() => {
                  setCountReloadBookingInfo((prevState) => {
                    return prevState + 1;
                  });
                }}
                isLoadingSummary={isLoadingUserSummary || isLoadingCoachInfo}
                userSummary={userSummary}
              />
            )}
            {sidebarType === "MILESTONE" && (
              <YourMilestone
                lifeStatus={userLifeStatusInfo}
                onCloseSidebar={onCloseSidebar}
              />
            )}
            {sidebarType === "SESSION_NOTE" && (
              <SessionNoteSidebar
                onCloseSidebar={onCloseSidebar}
                onBooking={onHandleBookingSession}
              />
            )}
          </Box>
        }
      >
        <Box
          sx={{
            paddingTop: { md: "40px", xs: "24px" },
            marginBottom: {
              md: "48px",
              xs: "16px",
            },
          }}
        >
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent="space-between"
            alignItems={{ md: "center", xs: "flex-start" }}
            alignContent={{ md: "center", xs: "flex-start" }}
            sx={{ marginBottom: { md: "16px", xs: "16px" } }}
            gap={{ md: "0px", xs: "12px" }}
          >
            {!isLoadingUserProfile ? (
              <Typography variant="h2">
                Hey{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {userInfo?.name ?? ""}
                </span>
              </Typography>
            ) : (
              <Skeleton sx={{ height: "50px", width: "150px" }} />
            )}

            <Stack
              direction={{ md: "column", xs: "row" }}
              justifyItems="flex-end"
              alignItems="flex-end"
              alignContent="flex-end"
              gap="4px"
            >
              {!isLoadingUserProfile ? (
                <Typography variant="caption" sx={{ color: "#00000099" }}>
                  Joined{" "}
                  <b>
                    {parseFormat(userInfo?.createdAt || "", "DD MMMM YYYY")}
                  </b>
                </Typography>
              ) : (
                <Skeleton sx={{ height: "15px", width: "180px" }} />
              )}
              {!isLoadingUserProfile ? (
                <Typography variant="caption" sx={{ color: "#00000099" }}>
                  <Link
                    href={process.env.REACT_APP_REDIRECT_TO_CHAT_BOT}
                    target="_blank"
                    sx={{
                      color: "#00000099",
                      textDecoration: "underline",
                      fontSize: "12px",
                      letterSpacing: "0.15px",
                    }}
                  >
                    Last chat
                  </Link>{" "}
                  {userInfo?.responsiveAt && (
                    <b>{dayjs(userInfo?.responsiveAt).fromNow()}</b>
                  )}
                </Typography>
              ) : (
                <Skeleton sx={{ height: "15px", width: "180px" }} />
              )}
            </Stack>
          </Stack>
          <Alert
            open={isOpenAlertWelcome}
            title="👋 Welcome to your Revelation Dashboard!"
            content="Here you can track your progress, reflect on your growth, and unlock new milestones in your journey with ourcoach"
            sx={{ marginBottom: "16px" }}
            onClose={onCloseAlertWelcom}
          />
          {userInfo && userInfo.booking.is_rate_booking && (
            <RateSession bookingID={userInfo.booking.booking_id} />
          )}
        </Box>
        <Box sx={{ marginBottom: { md: "48px", xs: "16px" } }}>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            justifyContent="space-between"
            sx={{
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                marginBottom: {
                  md: "0px",
                  xs: "16px",
                },
              }}
            >
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                gap="8px"
                sx={{
                  marginBottom: "8px",
                }}
              >
                {(userSubscriptionInfo?.status === "canceled" ||
                  userSubscriptionInfo?.status === "not_subscribed") && (
                  <LockClosedIcon
                    style={{ width: "24px", color: "#000000DE" }}
                  />
                )}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      md: "25px",
                      xs: "20px",
                    },
                    fontWeight: "700",
                    lineHeight: {
                      md: "30px",
                      xs: "24px",
                    },
                  }}
                >
                  Your Growth Guide
                </Typography>
              </Stack>
              <Typography variant="body2" sx={{ color: "#000000DE" }}>
                Get tailored insights from your Growth Guide: your personal
                guide for your journey.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                endIcon={<DocumentTextIcon style={{ width: "20px" }} />}
                onClick={onHandleSessionNote}
                sx={{
                  padding: "8px 16px",
                  height: "36px",
                  letterSpacing: "0.15px",
                }}
              >
                Your session notes
              </Button>
            </Box>
          </Stack>
          {userSubscriptionInfo?.status === "canceled" ||
            (userSubscriptionInfo?.status === "not_subscribed" && (
              <BannerLockSubscription />
            ))}
          <Box
            sx={{
              marginBottom: "16px",
              borderRadius: "8px",
              background: "#FFFCFA",
              border: "1px solid #2F4B73",
              overflow: "hidden",
              opacity:
                userSubscriptionInfo?.status === "canceled" ||
                userSubscriptionInfo?.status === "not_subscribed"
                  ? 0.6
                  : 1,
            }}
          >
            <Stack direction="row">
              <Box sx={{ flex: 1, height: "8px", background: "#557097" }}></Box>
              <Box
                sx={{ flex: 1, height: "8px", background: "#103138 " }}
              ></Box>
              <Box sx={{ flex: 1, height: "8px", background: "#DFA17C" }}></Box>
            </Stack>
            <Stack
              direction={{ md: "row", xs: "column" }}
              sx={{
                height: {
                  md: "328px",
                  xs: "100%",
                },
              }}
            >
              <GrowthGuide
                coachInfo={coachInfo}
                isLoading={isLoadingCoachInfo}
              />
              <BookGrowthGuide
                isLoadingSummary={
                  isLoadingUserProfile ||
                  isLoadingUserSummary ||
                  isLoadingCoachInfo ||
                  isLoadingUserSubscription
                }
                isLock={
                  userSubscriptionInfo?.status === "canceled" ||
                  userSubscriptionInfo?.status === "not_subscribed"
                }
                userSubscriptionInfo={userSubscriptionInfo}
                userSummary={userSummary}
                sessionSlot={userInfo?.session_slot || 0}
                countReload={countReloadBookingInfo}
                onBooking={onHandleBookingSession}
                onBookingDetail={onHandleBookingDetail}
              />
            </Stack>
          </Box>
        </Box>
        {/* HIDE */}
        <Box sx={{ marginBottom: "48px" }}>
          <Box mb="16px">
            <Stack
              direction="row"
              gap="8px"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              flexWrap="wrap"
              sx={{
                marginBottom: {
                  md: "8px",
                  xs: "8px",
                },
              }}
            >
              <Stack
                direction="row"
                gap="8px"
                alignContent="center"
                alignItems="center"
              >
                {(userSubscriptionInfo?.status === "canceled" ||
                  userSubscriptionInfo?.status === "not_subscribed") && (
                  <LockClosedIcon
                    style={{ width: "24px", color: "#000000DE" }}
                  />
                )}
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: {
                      md: "25px",
                      xs: "20px",
                    },
                    fontWeight: "700",
                    lineHeight: {
                      md: "30px",
                      xs: "24px",
                    },
                  }}
                >
                  Your Growth Journey
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                gap="6px"
                sx={{
                  display: "none",
                  border: "1px solid #00000014",
                  background: "#0000000A",
                  padding: "4px 8px",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="caption" sx={{ color: "#000000DE" }}>
                  Current growth score:
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#000000DE" }}
                >
                  {userLifeStatusInfo?.life_score.overall}
                </Typography>
              </Stack>
            </Stack>
            {/* @ts-ignore */}
            <Typography variant="body14">
              Celebrate your growth as you progress towards your goals and
              achievements.
            </Typography>
          </Box>
          {userSubscriptionInfo?.status === "canceled" ||
            (userSubscriptionInfo?.status === "not_subscribed" && (
              <BannerLockSubscription />
            ))}
          <Box
            sx={{
              opacity:
                userSubscriptionInfo?.status === "canceled" ||
                userSubscriptionInfo?.status === "not_subscribed"
                  ? 0.6
                  : 1,
            }}
          >
            <LifeFocus
              isLoading={isLoadingLifeStatus}
              userLifeStatus={userLifeStatusInfo}
            />
          </Box>
          {!isLoadingLifeStatus && (
            <Typography variant="caption" sx={{ color: "#00000099" }}>
              Last updated:{" "}
              {`${parseFormat(userLifeStatusInfo?.last_updated_at || "", "DD MMMM YYYY")}, ${parseFormat(userLifeStatusInfo?.last_updated_at || "", "hA")}`}
              , refreshed daily.
            </Typography>
          )}
        </Box>
        {/* HIDE */}
        <Box
          sx={{
            display: "none",
            marginBottom: {
              md: "48px",
              xs: "32px",
            },
          }}
        >
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            justifyContent="space-between"
            gap={{
              md: "0px",
              xs: "16px",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  marginBottom: {
                    md: "0px",
                    xs: "8px",
                  },
                }}
              >
                Your milestone
              </Typography>
              <Typography variant="body2" sx={{ color: "#000000DE" }}>
                Keep track on your <b>growth journey</b> here and earn special
                benefits as you progress.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                endIcon={<ChartBarIcon style={{ width: "20px" }} />}
                onClick={onHandleMilestone}
              >
                Milestones
              </Button>
            </Box>
          </Stack>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            gap={{
              md: "16px",
              xs: "8px",
            }}
            sx={{ marginTop: "16px" }}
          >
            <MilestoneBox
              title="Foundation I"
              currentPoint={1}
              maxPointMission={3}
              progressBar={50}
              titleLocked="🎉 Get an initial life assessment to kickstart your growth journey."
              bgIcon={
                <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
                  <ReactSVG src={PuzzlePiece} />
                </Box>
              }
              sx={{
                width: {
                  md: "528px",
                  xs: "100%",
                },
                background: "#0F2C58",
              }}
            />
            <BoxProgress
              sx={{
                width: {
                  md: "256px",
                  xs: "100%",
                },
                background: "#103138",
              }}
              icon={
                <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
                  <ReactSVG src={LandscapeRounded} />
                </Box>
              }
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ height: "100%" }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#FFFFFF61",
                  }}
                >
                  Up Next:
                </Typography>

                <Stack
                  direction="row"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="8px"
                  sx={{ width: "100%" }}
                >
                  <LandscapeRoundedIcon sx={{ color: "#FFFFFF" }} />
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: `BasisBold, sans-serif`,
                      color: "#FFFFFFDE",
                    }}
                  >
                    Growth
                  </Typography>
                </Stack>
              </Stack>
            </BoxProgress>
          </Stack>
        </Box>
      </AppLayout>
      <DrawerMilestone
        open={isOpenDrawerMilestone}
        userLifeStatusInfo={userLifeStatusInfo}
        onClose={() => setOpenDrawerMilestone(false)}
      />
      <DrawerBookingGrowthGuide
        open={isOpenDrawerBooking}
        notes={userSummary?.users_growth_guide_preparation_brief}
        onClose={() => setOpenDrawerBooking(false)}
        onReloadBookingInfo={() => {
          setCountReloadBookingInfo((prevState) => {
            return prevState + 1;
          });
        }}
      />
      <DrawerBookingDetailGrowthGuide
        open={isOpenDrawerBookingDetail}
        isLoadingSummary={isLoadingUserSummary || isLoadingCoachInfo}
        userSummary={userSummary}
        onClose={() => setOpenDrawerBookingDetail(false)}
        onReloadBookingInfo={() => {
          setCountReloadBookingInfo((prevState) => {
            return prevState + 1;
          });
        }}
      />
      <DrawerSessionNote
        open={isOpenDrawerSessionNote}
        onClose={() => setOpenDrawerSessionNote(false)}
      />
    </>
  );
}

export default HomePage;
