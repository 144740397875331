import { convertToLocalTime, parseFormat } from "@util/date";
import { ScheduleItem } from "@type/coach";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

interface Props {
  isOpen: boolean;
  isWelcomeMessage?: boolean;
  date: string;
  selectedScheduleItem?: ScheduleItem;
  onClose: () => void;
}

function DialogBookingSuccess(props: Props) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#000000DE",
          }}
        >
          Booking confirmed 🎉
        </Typography>
        {!props.isWelcomeMessage && (
          <IconButton onClick={props.onClose}>
            <XMarkIcon
              style={{ width: "16px", height: "16px", color: "#000000DE" }}
            />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: "24px", paddingTop: "16px !important" }}>
        <Typography
          // @ts-ignore
          variant="body14"
          sx={{ color: "#000000DE", fontWeight: "400" }}
        >
          Your Growth Guide session is now booked, get ready to learn more about
          yourself and your goals
        </Typography>
        <Stack
          direction="column"
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            padding: "8px 16px",
            background: "#EFEFEF",
            border: "1px solid #D8D8D8",
            borderRadius: "4px",
          }}
        >
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            <b>Date:</b> {parseFormat(props.date || "", "DD MMM YYYY")}
          </Typography>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            <b>Time:</b>{" "}
            {`${convertToLocalTime(props.selectedScheduleItem?.start_time || "")} - ${convertToLocalTime(props.selectedScheduleItem?.end_time || "")}`}
          </Typography>
        </Stack>
        {!props.isWelcomeMessage ? (
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ marginTop: "24px" }}
          >
            <Button type="button" onClick={props.onClose} variant="contained">
              Okay!
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ marginTop: "24px" }}
          >
            <Button
              fullWidth
              type="button"
              onClick={() => navigate("/")}
              variant="contained"
            >
              Continue to Revelation Dashboard
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DialogBookingSuccess;
