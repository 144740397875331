/* eslint-disable react/jsx-no-target-blank */
import { XMarkIcon } from "@heroicons/react/24/solid";
import { UserLifeStatus } from "@type/user";
import { ReactSVG } from "react-svg";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MilestoneBox from "@component/milestone-box";
import Box from "@mui/material/Box";
import PuzzlePiece from "@asset/images/puzzle-piece-large.svg";
import BuildingLibrary from "@asset/images/building-library.svg";
import LightBulb from "@asset/images/light-bulb.svg";
import LandscapeRounded from "@asset/images/landscape-rounded.svg";

interface Props {
  lifeStatus?: UserLifeStatus;
  onCloseSidebar: () => void;
}

function YourMilestone(props: Props) {
  return (
    <Stack
      direction="column"
      sx={{
        marginTop: {
          md: "40px",
          xs: "0px",
        },
      }}
    >
      <Stack
        direction="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          marginBottom: {
            md: "24px",
            xs: "0px",
          },
          borderBottom: {
            md: "none",
            xs: "1px solid #00000014",
          },
          padding: {
            md: "0px",
            xs: "8px 16px",
          },
        }}
      >
        <Typography variant="h5" sx={{ color: "#000000DE" }}>
          Your milestones
        </Typography>
        <IconButton onClick={props.onCloseSidebar}>
          <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
        </IconButton>
      </Stack>
      <Stack
        direction="column"
        gap="12px"
        sx={{
          padding: {
            md: "0px",
            xs: "12px 16px",
          },
        }}
      >
        <MilestoneBox
          isSidebar
          title="Foundation I"
          currentPoint={1}
          maxPointMission={3}
          titleLocked="🎉 Get an initial life assessment to kickstart your growth journey."
          progressBar={50}
          bgIcon={
            <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
              <ReactSVG src={PuzzlePiece} />
            </Box>
          }
          sx={{
            background: "#0F2C58",
          }}
        />
        <MilestoneBox
          isSidebar
          isLocked
          title="Growth"
          currentPoint={0}
          titleLocked="Unlock your access to exclusive content"
          bgIcon={
            <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
              <ReactSVG src={LandscapeRounded} />
            </Box>
          }
          sx={{
            background: "#103138",
          }}
        />
        <MilestoneBox
          isSidebar
          isLocked
          title="Mastery"
          currentPoint={0}
          titleLocked="Unlock your complimentary 1:1 10mins intro call with a Growth Guide"
          bgIcon={
            <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
              <ReactSVG src={BuildingLibrary} />
            </Box>
          }
          sx={{
            background: "#4A0203",
          }}
        />
        <MilestoneBox
          isSidebar
          isLocked
          title="Transformation"
          currentPoint={0}
          titleLocked="Unlock a Masterclass with us"
          bgIcon={
            <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
              <ReactSVG src={LightBulb} />
            </Box>
          }
          sx={{
            background: "#9B7700",
          }}
        />
      </Stack>
    </Stack>
  );
}

export default YourMilestone;
