import { AuthLoginInput } from "@type/auth";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_BASE_URL;
export const authLogin = (payload: AuthLoginInput) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios({
    url: `${apiURL}/auth/login`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      timezone: timezone,
    },
    data: payload,
  });
};

export const authRequestOTP = (phoneNo: string) => {
  return axios({
    url: `${apiURL}/auth/requestotp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      phoneNo: phoneNo,
    },
  });
};

export const authVerifyOTP = (phoneNo: string, otp: string) => {
  return axios({
    url: `${apiURL}/auth/verifyotp`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      phoneNo: phoneNo,
      otp: otp,
    },
  });
};

export const authResetPassword = (
  phoneNo: string,
  password: string,
  // eslint-disable-next-line prettier/prettier
  tokenResetPassword: string
) => {
  return axios({
    url: `${apiURL}/auth/reset`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      phone_no: phoneNo,
      password: password,
      token: tokenResetPassword,
    },
  });
};
