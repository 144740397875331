/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { catchErrorLog } from "@util/sentry";
import { useNavigate } from "react-router-dom";
import { useUserProfileContext } from "context/user-profile-context";
import { getCoachInfo } from "@service/coach";
import { CoachInfo } from "@type/coach";
import {
  convertToSnakeCase,
  removeAsterisks,
  splitFullName,
} from "@util/string";
import { coachAsset } from "config/coach";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

function YourGrowthGuideTeam() {
  const navigate = useNavigate();
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [isLoadingCoachInfo, setLoadingCoachInfo] = useState<boolean>(true);
  const [coachInfo, setCoachInfo] = useState<CoachInfo | undefined>(undefined);

  useEffect(() => {
    if (!isLoadingUserProfile) {
      getCoachInfo(userInfo?.booking.assign_growth_guide_id || "")
        .then((resp) => {
          setLoadingCoachInfo(false);
          setCoachInfo(resp.data.data);
        })
        .catch((err) => {
          setLoadingCoachInfo(false);
          catchErrorLog(err?.response?.data.message);
        });
    }
  }, [isLoadingUserProfile, userInfo?.booking.assign_growth_guide_id]);

  const coachFullName = splitFullName(coachInfo?.fullName ?? "");

  return (
    <>
      <AppLayout sx={{ padding: "0px" }}>
        <Box
          sx={{
            marginTop: { md: "100px", xs: "84px" },
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          <Stack direction="column" gap="8px">
            <Typography
              variant="h2"
              sx={{
                color: "#000000DE",
                textAlign: {
                  lg: "center",
                  md: "center",
                  xs: "left",
                },
                fontSize: {
                  lg: "42px",
                  md: "42px",
                  xs: "20px",
                },
                lineHeight: {
                  lg: "50.4px",
                  md: "50.4px",
                  xs: "24px",
                },
              }}
            >
              Introducing Your Growth Team
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                textAlign: {
                  lg: "center",
                  md: "center",
                  xs: "left",
                },
              }}
            >
              Meet your <b>AI Coach</b> and{" "}
              <b>Growth Guide {coachFullName.firstName ?? ""}</b> to help you
              with your journey!
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            marginTop: { md: "100px" },
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={{
                width: {
                  lg: "264px",
                  md: "264px",
                  xs: "134px",
                },
              }}
            >
              <Stack
                direction="column"
                gap="16px"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    height: {
                      lg: "96px",
                      md: "96px",
                      xs: "56px",
                    },
                    width: {
                      lg: "96px",
                      md: "96px",
                      xs: "56px",
                    },
                  }}
                >
                  {!isLoadingUserProfile &&
                    userInfo?.personal_info?.legend_persona.coach !== "" && (
                      <img
                        src={`${process.env.REACT_APP_DOMAIN_ASSET_URL}/coach/${
                          coachAsset[
                            convertToSnakeCase(
                              removeAsterisks(
                                userInfo?.personal_info?.legend_persona.coach ??
                                  ""
                              )
                            ) as keyof typeof coachAsset
                          ] ?? ""
                        }`}
                        alt={
                          removeAsterisks(
                            userInfo?.personal_info?.legend_persona.coach ?? ""
                          ) ?? ""
                        }
                        style={{ width: "100%" }}
                      />
                    )}
                </Avatar>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  sx={{
                    background: "#FFFCFA",
                    padding: "16px 12px",
                    border: "1px solid #557097",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        xs: "12px",
                      },
                      lineHeight: {
                        lg: "24px",
                        md: "24px",
                        xs: "18px",
                      },
                      fontWeight: 700,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                    }}
                  >
                    AI Coach -{" "}
                    {removeAsterisks(
                      userInfo?.personal_info?.legend_persona.coach ?? ""
                    ) ?? ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        xs: "10px",
                      },
                      lineHeight: {
                        lg: "21px",
                        md: "21px",
                        xs: "15px",
                      },
                      fontWeight: 400,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                      marginBottom: "8px",
                    }}
                  >
                    {userInfo?.personal_info?.legend_persona.title ?? ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        xs: "10px",
                      },
                      lineHeight: {
                        lg: "21px",
                        md: "21px",
                        xs: "15px",
                      },
                      fontWeight: 400,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                    }}
                  >
                    Your 24/7 AI growth partner on Whatsapp, here to guide you
                    with actionable insights, tailored advice, and a
                    personalized growth plan designed to meet your unique goals
                    and challenges.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                flex: 1,
                padding: {
                  lg: "16px",
                  md: "16px",
                  xs: "5px",
                },
                position: "relative",
                "&:after": {
                  zIndex: "-1",
                  position: "absolute",
                  top: {
                    lg: "50px",
                    md: "50px",
                    xs: "25px",
                  },
                  left: {
                    lg: "-80px",
                    md: "-80px",
                    xs: "-80px",
                  },
                  right: 0,
                  bottom: 0,
                  width: {
                    lg: "500px",
                    md: "500px",
                    xs: "200px",
                  },
                  content: '""',
                  height: "1px",
                  borderBottom: "2px dashed #00000029",
                },
              }}
            >
              <Stack
                direction="column"
                gap="16px"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    height: {
                      lg: "64px",
                      md: "64px",
                      xs: "40px",
                    },
                    width: {
                      lg: "64px",
                      md: "64px",
                      xs: "40px",
                    },
                    background: "transparent",
                  }}
                >
                  <AccountCircleIcon
                    sx={{
                      height: {
                        lg: "64px",
                        md: "64px",
                        xs: "40px",
                      },
                      width: {
                        lg: "64px",
                        md: "64px",
                        xs: "40px",
                      },
                      color: "#000000DE",
                    }}
                  />
                </Avatar>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      xs: "12px",
                    },
                    lineHeight: {
                      lg: "21px",
                      md: "21px",
                      xs: "18px",
                    },
                    fontWeight: 700,
                    letterSpacing: "0.15px",
                  }}
                >
                  You
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                width: {
                  lg: "264px",
                  md: "264px",
                  xs: "134px",
                },
              }}
            >
              <Stack
                direction="column"
                gap="16px"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    height: {
                      lg: "96px",
                      md: "96px",
                      xs: "56px",
                    },
                    width: {
                      lg: "96px",
                      md: "96px",
                      xs: "56px",
                    },
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_DOMAIN_ASSET_URL}/avatar/${coachInfo?.avatar}`}
                    alt={coachInfo?.fullName ?? ""}
                    style={{ width: "100%" }}
                  />
                </Avatar>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  sx={{
                    background: "#FFFCFA",
                    padding: "16px 12px",
                    border: "1px solid #557097",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        xs: "12px",
                      },
                      lineHeight: {
                        lg: "21px",
                        md: "21px",
                        xs: "18px",
                      },
                      fontWeight: 700,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                    }}
                  >
                    Growth Guide - {coachInfo?.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        xs: "10px",
                      },
                      lineHeight: {
                        lg: "21px",
                        md: "21px",
                        xs: "15px",
                      },
                      fontWeight: 400,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                      marginBottom: "8px",
                    }}
                  >
                    Experienced Clinical Psychologist
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "14px",
                        md: "14px",
                        xs: "10px",
                      },
                      lineHeight: {
                        lg: "21px",
                        md: "21px",
                        xs: "15px",
                      },
                      fontWeight: 400,
                      letterSpacing: "0.15px",
                      textAlign: "center",
                    }}
                  >
                    Your dedicated human guide, offering deeper, personalized
                    support for complex challenges, meaningful growth and create
                    lasting strategies tailored to your journey.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
          <Button
            fullWidth
            variant="contained"
            disabled={isLoadingUserProfile || isLoadingCoachInfo}
            onClick={() => navigate("/welcome")}
            sx={{ height: "48px", fontSize: "16px !important" }}
          >
            Great, let’s continue
          </Button>
        </Box>
      </AppLayout>
    </>
  );
}

export default YourGrowthGuideTeam;
