import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export const TabsStyled = styled(Tabs)({
  ".MuiTabs-flexContainer": {
    gap: "8px",
  },
  ".MuiTabs-indicator": {
    left: "0px",
    width: "4px",
    background: "#2F4B73",
    display: "none",
  },
});

export const TabStyled = styled(Tab)({
  borderRadius: "8px",
  position: "relative",
  padding: "12px 16px",
  height: "45px",
  "&.Mui-selected": {
    overflow: "hidden",
    "::before": {
      content: "''",
      position: "absolute",
      background: "#2F4B73",
      top: "0px",
      left: "0px",
      width: "4px",
      height: "48px",
    },
    background: "#EBE1D3",
    ".MuiTypography-root": {
      fontWeight: "600",
    },
  },
});

export const SidebarStyle = styled(Box)({
  overflow: "hidden",
  width: "0px",
  position: "fixed",
  top: "0px",
  height: "100vh",
  paddingTop: "61px",
  // background: "red",
  transition: "width 300ms",
  transitionTimingFunction: "linear",
  "&.open": {
    width: "400px",
    paddingLeft: "40px",
    borderLeft: "1px solid #00000014",
  },
});
