import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  isSelected?: boolean;
  isEnterprice?: boolean;
  title: React.ReactNode;
  interval?: string;
  subtitle: React.ReactNode;
  features: string[];
  onClick: () => void;
}

function Plan(props: Props) {
  return (
    <Box
      className={props.isSelected ? "active" : ""}
      sx={{
        padding: "16px",
        background: "#FFFFFF66",
        borderRadius: "8px",
        border: props.isSelected
          ? "1px solid #000000DE"
          : "1px solid #00000029",
        minHeight: "364px",
        cursor: "pointer",
        "&.active": {
          ".radio": {
            ":before": {
              content: '""',
              width: "12px",
              height: "12px",
              position: "absolute",
              background: "#000000",
              borderRadius: "100px",
            },
          },
        },
        // hover only when not active
        "&:hover:not(.active)": {
          ".radio": {
            ":before": {
              content: '""',
              width: "12px",
              height: "12px",
              position: "absolute",
              background: "#0000003D",
              borderRadius: "100px",
            },
          },
        },
      }}
      onClick={props.onClick}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="start"
        sx={{
          padding: "16px",
          background: props.isEnterprice ? "#ECE7C8" : "#F6E6CF",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Box>
          {props.title}
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "18px",
              letterSpacing: "0.15px",
              color: "#00000099",
            }}
          >
            {props.subtitle}
          </Typography>
        </Box>
        <Box>
          <Box
            className="radio"
            sx={{
              width: "24px",
              height: "24px",
              borderRadius: "100px",
              padding: "8px",
              border: "1px solid #000000",
              position: "relative",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Box>
      </Stack>
      <Typography
        sx={{
          color: "#00000099",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "21px",
          letterSpacing: "0.15px",
        }}
      >
        {props.interval === "monthly"
          ? "Features:"
          : props.interval === "yearly"
            ? "Everything in monthly, plus:"
            : "Everything in annually, plus:"}
      </Typography>
      <Box
        sx={{
          marginTop: "16px",
        }}
      >
        <ul style={{ padding: "0px", margin: "0px", marginLeft: "16px" }}>
          {props.features.map((feature, index) => (
            <li key={index}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "21px",
                  letterSpacing: "0.15px",
                  color: "#000000DE",
                }}
                dangerouslySetInnerHTML={{ __html: feature }}
              />
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}

export default Plan;
