import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function BannerLockSubscription() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: "16px",
        borderTopLeftRadius: "0px",
        padding: "14px 16px 14px 24px",
        marginBottom: "16px",
        background: "#F8EBD5",
        border: "1px solid #00000066",
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          left: "0px",
          top: "0px",
          height: {
            lg: "80px",
            md: "80px",
            xs: "100%",
          },
          width: "8px",
          background: "#ED9B0099",
        },
      }}
    >
      <Stack
        direction={{
          lg: "row",
          md: "row",
          xs: "column",
        }}
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        gap={{
          lg: "24px",
          md: "24px",
          xs: "14px",
        }}
      >
        <Stack direction="column" gap="4px" sx={{ flex: 1 }}>
          <Typography
            sx={{
              color: "#000000DE",
              fontWeight: 600,
              fongSize: "14px",
              lineHeight: "21px",
              letterSpacing: "0.15px",
            }}
          >
            This section is no longer updated!
          </Typography>
          <Typography
            sx={{
              color: "#000000DE",
              fontWeight: 400,
              fongSize: "14px",
              lineHeight: "21px",
              letterSpacing: "0.15px",
            }}
          >
            Subscribe today to unlock updates and benefits with ourcoach.
          </Typography>
        </Stack>
        <Button
          endIcon={<ArrowRightIcon style={{ width: "20px" }} />}
          variant="contained"
          sx={{
            width: {
              lg: "auto",
              md: "auto",
              xs: "100%",
            },
          }}
          onClick={() => navigate("/subscription/plan")}
        >
          View pricing plans
        </Button>
      </Stack>
    </Box>
  );
}

export default BannerLockSubscription;
