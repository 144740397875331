import { cancelBooking } from "@service/booking";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { BookingInfo } from "@type/booking";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import IconButton from "@mui/material/IconButton";

interface Props {
  isOpen: boolean;
  bookingInfo?: BookingInfo;
  onClose: (event: any, reason: string) => void;
}

function DialogBookingCancel(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onCancelBooking = () => {
    setLoading(true);
    cancelBooking({
      booking_id: props.bookingInfo?.booking_id || "",
    })
      .then((resp) => {
        setLoading(false);
        props.onClose(null, "close_sidebar");
        enqueueSnackbar(resp.data.message, {
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(e?.response?.data.message, {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
          });
        }
      });
  };

  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#000000DE",
          }}
        >
          Cancel your booking
        </Typography>
        <IconButton onClick={() => props.onClose(null, "")}>
          <XMarkIcon
            style={{ width: "16px", height: "16px", color: "#000000DE" }}
          />
        </IconButton>
      </DialogTitle>
      {props.bookingInfo && (
        <DialogContent sx={{ padding: "24px", paddingTop: "16px !important" }}>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            Are you sure you would like to cancel your booking? This action
            cannot be undone.
          </Typography>

          {props.bookingInfo?.remaining_time <
            Number(process.env.REACT_APP_BOOKING_REMAINING_CANCELED) && (
            <>
              <Box
                sx={{
                  padding: "8px",
                  marginBottom: "16px",
                  marginTop: "16px",
                  borderRadius: "4px",
                  color: "#00000099",
                  background: "#EFEFEF",
                }}
              >
                <Typography
                  sx={{
                    color: "#00000099",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Note: As your session is less than 24 hours away, cancelling
                  now means you will forfeit the session for this month.
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.15px",
                  fontWeight: "400",
                  color: "#00000099",
                }}
              >
                For further enquiries or help, please contact our support team
                at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:support@ourcoach.ai"
                  style={{ color: "#2F4B73" }}
                >
                  support@ourcoach.ai
                </a>
                .
              </Typography>
            </>
          )}
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Button
              onClick={() => props.onClose(null, "")}
              disabled={isLoading}
              variant="contained"
              color="secondary"
            >
              No, thanks
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={isLoading}
              onClick={onCancelBooking}
            >
              Cancel booking
            </Button>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default DialogBookingCancel;
