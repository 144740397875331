import { UserLifeStatus } from "@type/user";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import YourMilestone from "@container/your-milestone";

interface Props {
  open?: boolean;
  onClose: () => void;
  userLifeStatusInfo?: UserLifeStatus;
}

function DrawerMilestone(props: Props) {
  return (
    <>
      <Drawer
        open={props.open}
        onClose={props.onClose}
        anchor="bottom"
        sx={{
          position: "relative",
          ".MuiPaper-root": {
            background: "#F4EDE3",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        }}
      >
        <Box
          sx={{
            height: "5px",
            width: "48px",
            borderRadius: "100px",
            background: "#CDCFD0",
            margin: "8px auto 11px",
          }}
        ></Box>
        <Box>
          <YourMilestone
            lifeStatus={props.userLifeStatusInfo}
            onCloseSidebar={props.onClose}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default DrawerMilestone;
