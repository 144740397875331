import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { authRequestOTP } from "@service/auth";
import { AuthVerifyPhone } from "@type/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { listFlags } from "components/flag";
import { CountryFlag } from "@type/country";
import { getCookie } from "@util/cookie";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import axios from "axios";
import PopoverFlag from "components/popover-flag";
import Alert from "@mui/material/Alert";
import { catchErrorLog } from "@util/sentry";

function FormActivatePhone() {
  const selectedCountry = getCookie("selectedCodeCountry") || "US";
  const navigate = useNavigate();
  const [country, setCountry] = useState(
    // eslint-disable-next-line prettier/prettier
    listFlags.find((item) => item.code === selectedCountry)
  );
  const [authPhoneNumber, setAuthPhoneNumber] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [errorForm, setErrorForm] = useState<string | undefined>(undefined);

  const open = Boolean(anchorEl);

  const { handleSubmit, reset } = useForm<AuthVerifyPhone>();

  const handleOpenPhoneFlag = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerClosePhoneFlag = () => {
    setAnchorEl(null);
  };

  const handleChangeCountry = (item: any) => {
    setCountry(item);
    setAnchorEl(null);
  };

  const handleChangePhoneNumber = (
    // eslint-disable-next-line prettier/prettier
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setAuthPhoneNumber(inputValue);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(async () => {
        if (authPhoneNumber === "") {
          setErrorForm("Please enter your phone number.");
          return;
        }
        setLoading(true);
        try {
          const resp = await authRequestOTP(
            // eslint-disable-next-line prettier/prettier
            `${country?.dialCode}${authPhoneNumber}`
          );
          if (resp.status === 200) {
            sessionStorage.setItem("emailVerify", resp.data.data.email);
            sessionStorage.setItem(
              "phoneVerify",
              // eslint-disable-next-line prettier/prettier
              resp.data.data.phoneNo
            );
            reset();
            setAuthPhoneNumber("");

            navigate("/verify");
          } else {
            setErrorForm(resp.data.message);
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            // @ts-ignore
            setErrorForm(err?.response?.data.message);
            catchErrorLog(err?.response?.data.message);
          }
        }
        setLoading(false);
      })}
    >
      <Stack direction="column">
        <FormControl sx={{ marginBottom: "24px" }}>
          <FormLabel color="secondary" sx={{ marginBottom: "8px" }}>
            Phone number
          </FormLabel>
          <Stack alignItems="center" sx={{ width: "100%" }}>
            <OutlinedInput
              color="secondary"
              sx={{ paddingLeft: "0px", width: "100%" }}
              autoComplete="off"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errorForm}
              value={authPhoneNumber}
              onChange={handleChangePhoneNumber}
              startAdornment={
                <>
                  <button
                    type="button"
                    style={{
                      background: "#FFFFFF",
                      border: "none",
                      margin: "0px",
                      display: "flex",
                      flexDirection: "row",
                      width: "72px",
                      height: "46px",
                      padding: "8px 12px",
                      borderRight: "1px solid #D8D8D8",
                      marginRight: "12px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                      cursor: "pointer",
                      gap: "8px",
                      placeContent: "center space-between",
                      alignItems: "center",
                    }}
                    onClick={handleOpenPhoneFlag}
                  >
                    <img
                      src={country?.flag}
                      alt="flag"
                      style={{ userSelect: "none" }}
                    />
                    <ChevronDownIcon
                      style={{ width: "16px", color: "#000000DE" }}
                    />
                  </button>
                  <PopoverFlag
                    open={open}
                    selected={country}
                    anchorEl={anchorEl}
                    onClose={handlerClosePhoneFlag}
                    onChange={(item: CountryFlag) => handleChangeCountry(item)}
                    sxPaper={{
                      marginTop: "8px",
                    }}
                  />
                  <InputAdornment position="start" sx={{ margin: "0px" }}>
                    {country?.dialCode}
                  </InputAdornment>
                </>
              }
            />
          </Stack>
          {errorForm && (
            <Alert
              severity="error"
              variant="standard"
              sx={{
                border: "none !important",
                background: "transparent !important",
                color: "#D92323",
                alignContent: "center",
                alignItems: "flex-start",
                gap: "6px",
                paddingLeft: "0px",
                paddingRight: "0px",
                ".MuiAlert-icon": {
                  padding: "0px",
                  paddingTop: "3px",
                  marginRight: "0px",
                },
                ".MuiAlert-message": {
                  padding: "0px",
                },
              }}
              icon={
                <ExclamationCircleIcon
                  style={{ width: "16px", color: "#D92323" }}
                />
              }
            >
              {errorForm}
            </Alert>
          )}
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
          color="primary"
          size="large"
        >
          Request OTP
        </Button>
      </Stack>
    </form>
  );
}

export default FormActivatePhone;
