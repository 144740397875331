import { CheckIcon } from "@heroicons/react/24/solid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BulletPoint from "@asset/images/bullet-point.png";
import React from "react";

interface Props {
  isCompleted?: boolean;
  children: React.ReactNode;
}

function MissionMilestone(props: Props) {
  const theme = useTheme();
  const isMediaQuerySM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignContent={{
        md: "flex-start",
        xs: "flex-start",
      }}
      alignItems={{
        md: "flex-start",
        xs: "flex-start",
      }}
      gap="2px"
    >
      {props.isCompleted ? (
        <CheckIcon
          style={{
            width: isMediaQuerySM ? "16px" : "20px",
            color: "#FFFFFF99",
          }}
        />
      ) : (
        <Box
          sx={{
            width: "16px",
            height: "18px",
            background: `url(${BulletPoint}) center center no-repeat`,
          }}
        />
      )}

      <Typography
        variant="caption"
        sx={{
          color: "#FFFFFF99",
          textDecoration: props.isCompleted ? "line-through" : "auto",
        }}
      >
        {props.children}
      </Typography>
    </Stack>
  );
}

export default MissionMilestone;
