/* eslint-disable prettier/prettier */
import { XMarkIcon } from "@heroicons/react/24/solid";
import { UserNoteItem } from "@type/user";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { parseFormat } from "@util/date";
import { convertToFormattedText } from "@util/convert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SessionNoteBox from "@component/session-note-box";
import ReactMarkdown from "react-markdown";

interface Props {
  selectedUser: UserNoteItem;
  onBack: () => void;
  onCloseSidebar: () => void;
}

function SessionNoteSidebarDetail(props: Props) {
  return (
    <>
      <Stack
        direction="column"
        sx={{
          marginTop: {
            md: "40px",
            xs: "0px",
          },
        }}
      >
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: {
              md: "24px",
              xs: "0px",
            },
            borderBottom: {
              md: "none",
              xs: "1px solid #00000014",
            },
            padding: {
              md: "0px",
              xs: "8px 16px",
            },
          }}
        >
          <Stack
            direction="row"
            gap="12px"
            alignContent="center"
            alignItems="center"
          >
            <IconButton onClick={props.onBack}>
              <ArrowLeftIcon style={{ width: "20px", color: "#000000DE" }} />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#000000DE" }}>
              {parseFormat(
                props.selectedUser.sessionStartedAt,
                "DD MMM YYYY (dddd)"
              )}
            </Typography>
          </Stack>
          <IconButton onClick={props.onCloseSidebar}>
            <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
          </IconButton>
        </Stack>
      </Stack>
      <Box
        sx={{
          height: {
            md: "calc(100vh - 161px)",
            xs: "auto",
          },
        }}
      >
        <Box
          sx={{
            padding: "16px",
            borderRadius: {
              md: "8px",
              xs: "0px",
            },
            background: "#FFFCFA",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              maxHeight: {
                md: "calc(100vh - 300px)",
                xs: "500px",
              },
              overflowX: "hidden",
              overflowY: "scroll",
              marginRight: "-17px",
              paddingRight: "17px",
            }}
          >
            <Stack direction="column">
              <Typography variant="caption" sx={{ color: "#00000099" }}>
                {parseFormat(
                  props.selectedUser.sessionStartedAt,
                  "ddd, DD MMM YYYY"
                )}
              </Typography>
              <Typography variant="caption" sx={{ color: "#00000099" }}>
                {`Session Notes by ${props.selectedUser.coachName}`}
              </Typography>
            </Stack>

            <SessionNoteBox
              title="Overview"
              content={
                // @ts-ignore
                <Typography variant="body14">
                  {convertToFormattedText(
                    props.selectedUser.ourcoachSummary.overview
                  )}
                </Typography>
              }
            />
            <SessionNoteBox
              title="Action Plans"
              content={
                props.selectedUser.ourcoachSummary.action_plan.length > 0 ? (
                  <ul style={{ margin: "0px", paddingLeft: "20px" }}>
                    {props.selectedUser.ourcoachSummary.action_plan.map(
                      (action_plan, index) => (
                        <li key={index}>
                          {/* @ts-ignore */}
                          <Typography variant="body14">
                            {convertToFormattedText(action_plan)}
                          </Typography>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  // @ts-ignore
                  <Typography variant="body14">No Data Action Plan</Typography>
                )
              }
            />
            <Box sx={{ marginBottom: "24px" }}>
              {/* @ts-ignore */}
              <Typography variant="body14" sx={{ fontWeight: "400" }}>
                <ReactMarkdown>
                  {props.selectedUser.ourcoachSummary.challenges_and_goals}
                </ReactMarkdown>
              </Typography>
              {/* @ts-ignore */}
              <Typography variant="body14" sx={{ fontWeight: "400" }}>
                <ReactMarkdown>
                  {props.selectedUser.ourcoachSummary.discussion_points}
                </ReactMarkdown>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SessionNoteSidebarDetail;
