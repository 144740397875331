/* eslint-disable prettier/prettier */
import { getUserProfile } from "@service/user";
import { UserInfo } from "@type/user";
import { catchErrorLog, setLogUser } from "@util/sentry";
import { createContext, useContext, useEffect, useState } from "react";

const UserProfileContext = createContext<[boolean, UserInfo?]>([
  false,
  undefined,
]);

export const useUserProfileContext = () => {
  return useContext(UserProfileContext);
};

interface Props {
  children: React.ReactNode;
}

function UserProfileProvider(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

  useEffect(() => {
    getUserProfile()
      .then((resp) => {
        setLoading(false);
        setUserInfo(resp.data.data);
        setLogUser(
          resp.data.data.user_id,
          resp.data.data.name,
          resp.data.data.email
        );
      })
      .catch((err) => {
        setLoading(false);
        catchErrorLog(err?.response?.data.message);
      });
  }, []);

  return (
    // @ts-ignore
    <UserProfileContext.Provider value={[isLoading, userInfo]}>
      {props.children}
    </UserProfileContext.Provider>
  );
}

export default UserProfileProvider;
