import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getCookie } from "@util/cookie";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import FormLogin from "@container/form-login";

function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const body = document.body;
    body.classList.add("base");
    const token = getCookie("authToken") ?? "";
    if (token !== "") {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{
        height: "100%",
        padding: { md: "0px", xs: "16px" },
      }}
    >
      <Box
        sx={{
          marginBottom: "50px",
          width: {
            md: "386px",
            xs: "100%",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            marginBottom: "56px",
          }}
        >
          ourcoach
        </Typography>

        <FormLogin />

        <Box>
          <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
          >
            <Typography variant="body2">First time logging in?</Typography>
            <Button
              onClick={() => navigate("/activate-account")}
              variant="contained"
              color="secondary"
            >
              Activate account
            </Button>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}

export default LoginPage;
