import { XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function DialogBookingSkip(props: Props) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
            color: "#000000DE",
          }}
        >
          Are you sure you want to start on your own?
        </Typography>
        <IconButton onClick={props.onClose}>
          <XMarkIcon
            style={{ width: "16px", height: "16px", color: "#000000DE" }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "24px", paddingTop: "16px !important" }}>
        <Stack direction="column" gap="8px">
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            We noticed you’re about to explore on your own—that’s totally okay!
          </Typography>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            But before you dive in, why not take advantage of a completely{" "}
            <b>free session</b> with your <b>Growth Guide</b>?
          </Typography>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            Here’s why it’s worth considering:
          </Typography>
        </Stack>
        <Stack
          direction="column"
          gap="8px"
          sx={{
            border: "1px solid #E5E5E5",
            background: "#0000000A",
            padding: "8px 16px",
            borderRadius: "8px",
            margin: "16px 0px",
          }}
        >
          <Stack direction="row" gap="16px">
            <span>🚀</span>
            <Typography
              // @ts-ignore
              variant="body14"
              sx={{ color: "#000000DE", fontWeight: "400" }}
            >
              Turn your goals into <b>actionable steps</b>
            </Typography>
          </Stack>
          <Stack direction="row" gap="16px">
            <span>🎯</span>
            <Typography
              // @ts-ignore
              variant="body14"
              sx={{ color: "#000000DE", fontWeight: "400" }}
            >
              <b>Avoid trial and error</b> by following a personalized plan
            </Typography>
          </Stack>
          <Stack direction="row" gap="16px">
            <span>💪</span>
            <Typography
              // @ts-ignore
              variant="body14"
              sx={{ color: "#000000DE", fontWeight: "400" }}
            >
              <b>Stay motivated and accountable</b> every step of the way
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap="8px">
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            This free session is designed to make your journey smoother and more
            impactful.
            <br />
          </Typography>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            Let’s <b>book a session</b> to ease your progress!
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap="10px"
          sx={{ marginTop: "24px" }}
        >
          <Button
            type="button"
            onClick={() => navigate("/")}
            variant="contained"
            color="secondary"
          >
            No, let me explore
          </Button>
          <Button type="button" onClick={props.onClose} variant="contained">
            Book free session
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DialogBookingSkip;
