/* eslint-disable prettier/prettier */
import { SparklesIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { UserNoteItem } from "@type/user";
import { parseFormat } from "@util/date";
import { useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import sessionNoteBG from "@asset/images/bg-session-note.png";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogSessionNoteDetail from "@component/dialog/dialog-session-note-detail";

interface Props {
  onSelected: (userNote: UserNoteItem) => void;
  onCloseSidebar: () => void;
  userNotes: UserNoteItem[];
  onBooking: () => void;
}

function SessionNoteSidebarList(props: Props) {
  const theme = useTheme();
  const isMediaQueryHandphone = useMediaQuery(theme.breakpoints.down("md"));
  const [userNoteIndex, setUserNoteIndex] = useState<number>(0);
  const [isOpenDialogSessionNoteDetail, setOpenDialogSessionNoteDetail] =
    useState<boolean>(false);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          marginTop: {
            lg: "40px",
            xs: "0px",
          },
        }}
      >
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: {
              lg: "24px",
              xs: "0px",
            },
            borderBottom: {
              lg: "none",
              xs: "1px solid #00000014",
            },
            padding: {
              lg: "0px",
              xs: "8px 16px",
            },
          }}
        >
          <Stack direction="column" alignItems="center" alignContent="center">
            <Typography variant="h5" sx={{ color: "#000000DE" }}>
              Your session notes
            </Typography>
            <Stack direction="row" gap="8px"></Stack>
          </Stack>
          <IconButton onClick={props.onCloseSidebar}>
            <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
          </IconButton>
        </Stack>
        {props.userNotes.length === 0 && (
          <Stack
            direction="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap="12px"
            sx={{
              height: {
                lg: "calc(100vh - 180px)",
                xs: "calc(100vh - 131px)",
              },
              padding: {
                lg: "0px",
                md: "12px 16px",
                xs: "12px 16px",
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#000000DE", fontWeight: "500 !important" }}
            >
              No session notes found
            </Typography>
            <Typography
              sx={{
                color: "#000000DE",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              If you’ve recently had a session, notes are typically ready after
              24 hours. Check back soon!
            </Typography>
          </Stack>
        )}
      </Stack>
      <Box
        sx={{
          overflow: "hidden",
          padding: {
            lg: "0px",
            md: "12px 0px 12px 16px",
            xs: "12px 16px",
          },
          height: {
            lg: "calc(100vh - 161px)",
            xs: "calc(100vh - 161px)",
          },
        }}
      >
        <Box
          sx={{
            display: {
              lg: "flex",
              md: "grid",
              xs: "flex",
            },
            gridTemplateColumns: {
              lg: "auto auto",
              md: "50% 50%",
              xs: "auto auto",
            },
            flexDirection: {
              lg: "column",
              md: "row",
              xs: "column",
            },
            overflowX: "hidden",
            overflowY: "scroll",
            marginRight: "-17px",
            paddingRight: "17px",
            height: "100%",
          }}
        >
          {props.userNotes.map((item, index) => (
            <Box
              key={index}
              sx={{
                overflow: "hidden",
                cursor: "pointer",
                borderRadius: "16px",
                border: "1px solid transparent",
                height: "138px",
                marginBottom: "12px",
                "&:hover": {
                  border: "1px solid #103138",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (!isMediaQueryHandphone) {
                  setOpenDialogSessionNoteDetail(true);
                } else {
                  props.onSelected(item);
                }
                setUserNoteIndex(index);
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  height: "41px",
                  background: "url(" + sessionNoteBG + ")",
                  padding: "0px 16px",
                }}
              >
                {index === 0 && (
                  <Stack
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                    gap="8px"
                    sx={{
                      background: "#222222",
                      borderRadius: "100px",
                      padding: "4px 12px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.15px",
                        fontWeight: "600",
                      }}
                    >
                      Latest
                    </Typography>
                    <SparklesIcon
                      style={{ width: "16px", color: "#FFFFFFDE" }}
                    />
                  </Stack>
                )}
              </Stack>
              <Stack
                direction="column"
                gap="8px"
                sx={{
                  height: "97px",
                  padding: "16px",
                  background: "#FFFCFA",
                  userSelect: "none",
                }}
              >
                {/* @ts-ignore */}
                <Typography variant="body14" sx={{ color: "#000000DE" }}>
                  {parseFormat(item.sessionStartedAt, "DD MMM YYYY (dddd)")}
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      letterSpacing: "0.15px",
                      color: "#00000099",
                      display: "-webkit-box",
                      "-webkit-line-clamp": "2",
                      "-webkit-box-orient": "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item.ourcoachSummary.overview}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>
      <DialogSessionNoteDetail
        isOpen={isOpenDialogSessionNoteDetail}
        currentIndex={userNoteIndex}
        sessionNotes={props.userNotes || []}
        onClose={() => setOpenDialogSessionNoteDetail(false)}
        onBooking={() => {
          setOpenDialogSessionNoteDetail(false);
          props.onBooking();
        }}
      />
    </>
  );
}

export default SessionNoteSidebarList;
