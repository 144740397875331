import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { authResetPassword } from "@service/auth";
import { AuthResetPassword } from "@type/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import PasswordReset from "@container/password-reset";
import { catchErrorLog } from "@util/sentry";

function FormNewPassword() {
  const navigate = useNavigate();
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] =
    useState<boolean>(false);
  const [showPassword, setShowPassowrd] = useState<boolean>(false);
  const [errorForm, setErrorForm] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AuthResetPassword>();

  if (isResetPasswordSuccess) {
    return <PasswordReset />;
  }

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "16px" }}>
        New password
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "#000000DE", marginBottom: "32px" }}
      >
        Your phone number is confirmed. Now, set up a <b>new</b> password for
        your account.
      </Typography>
      {errorForm && (
        <Box mb="24px">
          <Alert
            variant="standard"
            severity="error"
            icon={
              <ExclamationCircleIcon
                style={{ width: "16px", color: "#B30909" }}
              />
            }
          >
            {errorForm}
          </Alert>
        </Box>
      )}

      <form
        onSubmit={handleSubmit(async (data: AuthResetPassword) => {
          setLoading(true);
          try {
            const tokenBase64 = sessionStorage.getItem("token_reset_password");
            if (!tokenBase64) {
              navigate("/");
              return;
            }
            const tokenPayload = JSON.parse(atob(tokenBase64));
            const phoneNo: string = sessionStorage.getItem("phoneVerify") ?? "";
            const resp = await authResetPassword(
              phoneNo,
              data.password,
              // eslint-disable-next-line prettier/prettier
              tokenPayload.token
            );
            if (resp.status === 200) {
              reset();
              setIsResetPasswordSuccess(true);
            } else {
              setErrorForm(resp.data.message);
            }
            sessionStorage.removeItem("token_reset_password");
          } catch (err) {
            if (axios.isAxiosError(err)) {
              // @ts-ignore
              setErrorForm(err?.response?.data.message);
              catchErrorLog(err?.response?.data.message);
            }
          }
          setLoading(false);
        })}
      >
        <Stack direction="column" gap={4}>
          <FormControl>
            <FormLabel color="secondary" sx={{ marginBottom: "8px" }}>
              Password
            </FormLabel>
            <OutlinedInput
              color="secondary"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              error={!!errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassowrd(!showPassword)}>
                    {showPassword ? (
                      <EyeIcon style={{ width: "20px" }} />
                    ) : (
                      <EyeSlashIcon style={{ width: "20px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <FormHelperText error sx={{ margin: "8px 0px" }}>
                <Stack direction="row" gap="6px">
                  <ExclamationCircleIcon
                    style={{ width: "16px", color: "#B30909" }}
                  />
                  Please enter your password.
                </Stack>
              </FormHelperText>
            )}
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            color="primary"
            size="large"
          >
            Activate account
          </Button>
        </Stack>
      </form>
    </>
  );
}

export default FormNewPassword;
