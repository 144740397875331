import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getCookie, removeCookie } from "@util/cookie";
import UserProfileProvider from "context/user-profile-context";
import UserSubscriptionProvider from "context/user-subscription-context";

// Function to check token validity
const isAuthenticated = (): boolean => {
  const token = getCookie("authToken");

  // Basic check: make sure token exists
  // Replace with actual token validation logic
  if (!token) {
    return false;
  }

  const decodedToken: any = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  // If token is expired, return false
  if (decodedToken.exp < currentTime) {
    removeCookie("authToken");
    return false;
  }

  // For demonstration, we just check if the token is not null
  // In a real scenario, you might want to make an API call to validate the token

  return true;
};

interface RequireAuthProps {}

const RequireAuth: React.FC<RequireAuthProps> = () => {
  const location = useLocation();

  if (!isAuthenticated()) {
    // Redirect to login if not authenticated using Navigate
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render the children if authenticated
  return (
    <UserProfileProvider>
      <UserSubscriptionProvider>
        <Outlet />
      </UserSubscriptionProvider>
    </UserProfileProvider>
  );
};

export default RequireAuth;
