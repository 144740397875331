import { BookingInfo } from "@type/booking";
import { getBookingGrowthGuideInfo } from "@service/booking";
import { useEffect, useState } from "react";
import DialogBookingCancel from "components/dialog/dialog-booking-cancel";
import BookingGrowthGuideDetail from "./info";
import BookingGrowthGuideReschedule from "./reschedule";
import { UserSummary } from "@type/user";
import { catchErrorLog } from "@util/sentry";

interface Props {
  onCloseSidebar: () => void;
  onReloadBookingInfo: () => void;
  isLoadingSummary: boolean;
  userSummary?: UserSummary;
}

function BookingGrowthGuideSidebar(props: Props) {
  const [isRescheduleBooking, setRescheduleBooking] = useState<boolean>(false);
  const [isOpenDialogBookingCancel, setIsOpenDialogBookingCancel] =
    useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getBookingGrowthGuideInfo()
      .then((resp) => {
        setLoading(false);
        setBookingInfo(resp.data.data);
      })
      .catch((err) => {
        setLoading(false);
        catchErrorLog(err?.response?.data.message);
      });
  }, []);

  return (
    <>
      {!isRescheduleBooking && (
        <BookingGrowthGuideDetail
          isLoading={isLoading}
          bookingInfo={bookingInfo}
          isLoadingSummary={props.isLoadingSummary}
          userSummary={props.userSummary}
          onCloseSidebar={props.onCloseSidebar}
          onCancelBooking={() => setIsOpenDialogBookingCancel(true)}
          onRescheduleBooking={() => setRescheduleBooking(true)}
        />
      )}
      {isRescheduleBooking && (
        <BookingGrowthGuideReschedule
          bookingInfo={bookingInfo}
          onReloadBookingInfo={props.onReloadBookingInfo}
          onBack={() => setRescheduleBooking(false)}
          onCloseSidebar={props.onCloseSidebar}
        />
      )}
      <DialogBookingCancel
        bookingInfo={bookingInfo}
        isOpen={isOpenDialogBookingCancel}
        onClose={(_, reason: string) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
          if (reason === "close_sidebar") {
            props.onCloseSidebar();
          }
          setIsOpenDialogBookingCancel(false);
          props.onReloadBookingInfo();
        }}
      />
    </>
  );
}

export default BookingGrowthGuideSidebar;
