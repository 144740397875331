import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormInputOTP from "@container/form-input-otp";
import FormNewPassword from "@container/form-new-password";
import FormResetPassword from "@container/form-reset-password";

function VerificationOTPPage() {
  const navigate = useNavigate();
  const [isShowResetPassword, setIsShowResetPassword] =
    useState<boolean>(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);

  useEffect(() => {
    const body = document.body;
    body.classList.add("base");
    const phone = sessionStorage.getItem("phoneVerify") ?? "";
    if (phone === "" || phone === "undefined") {
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallbackOTPSuccess = () => {
    const isForgotPassword: boolean =
      Boolean(sessionStorage.getItem("is_forgot_password")) ?? false;

    if (!isForgotPassword) {
      setIsShowResetPassword(true);
    } else {
      setIsShowNewPassword(true);
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ height: "100%", padding: "16px" }}
    >
      <Box sx={{ position: "absolute", top: "40px" }}>
        <Typography variant="h5">ourcoach</Typography>
      </Box>
      <Box
        sx={{
          width: {
            md: "386px",
            xs: "100%",
          },
        }}
      >
        {isShowResetPassword ? (
          <FormResetPassword />
        ) : isShowNewPassword ? (
          <FormNewPassword />
        ) : (
          <FormInputOTP onCallback={handleCallbackOTPSuccess} />
        )}
      </Box>
    </Stack>
  );
}

export default VerificationOTPPage;
