/* eslint-disable prettier/prettier */
import { useUserProfileContext } from "context/user-profile-context";
import { getLocalGMT, parseFormat } from "@util/date";
import { removeAsterisks } from "@util/string";
import { useEffect, useState } from "react";
import { getUserCustomerPortal } from "@service/user";
import Skeleton from "@component/skeleton";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import BannerSubscription from "@component/banner-subscription";
import { catchErrorLog } from "@util/sentry";

function ProfilePage() {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [userCustomerPortal, setUserCustomerPortal] = useState<string>("");

  useEffect(() => {
    if (!isLoadingUserProfile) {
      getUserCustomerPortal()
        .then((resp) => {
          setUserCustomerPortal(resp.data.data);
        })
        .catch((e) => {
          catchErrorLog(`failed to getUserCustomerPortal, err: ${e.message}`);
        });
    }
  }, [isLoadingUserProfile]);

  return (
    <AppLayout>
      <Box
        sx={{
          paddingTop: { md: "40px", xs: "24px" },
          marginBottom: {
            md: "48px",
            xs: "16px",
          },
        }}
      >
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems={{ md: "center", xs: "flex-start" }}
          alignContent={{ md: "center", xs: "flex-start" }}
          sx={{ marginBottom: { md: "16px", xs: "16px" } }}
          gap={{ md: "0px", xs: "12px" }}
        >
          <Typography variant="h2">My Profile</Typography>
          <Stack
            direction={{ md: "column", xs: "row" }}
            justifyItems="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
            gap="4px"
          >
            {!isLoadingUserProfile ? (
              <Typography variant="caption" sx={{ color: "#00000099" }}>
                Joined{" "}
                <b>{parseFormat(userInfo?.createdAt || "", "DD MMMM YYYY")}</b>
              </Typography>
            ) : (
              <Skeleton sx={{ height: "15px", width: "180px" }} />
            )}
            {!isLoadingUserProfile ? (
              <Typography variant="caption" sx={{ color: "#00000099" }}>
                <Link
                  href={process.env.REACT_APP_REDIRECT_TO_CHAT_BOT}
                  target="_blank"
                  sx={{
                    color: "#00000099",
                    textDecoration: "underline",
                    fontSize: "12px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Last chat
                </Link>{" "}
                {userInfo?.responsiveAt && (
                  <b>{dayjs(userInfo?.responsiveAt).fromNow()}</b>
                )}
              </Typography>
            ) : (
              <Skeleton sx={{ height: "15px", width: "180px" }} />
            )}
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ marginBottom: "48px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="h4">Current Plan</Typography>
          {userCustomerPortal !== "" && (
            <Link
              sx={{
                color: "#2F4B73",
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "0.15px",
                fontWeight: 500,
                textDecoration: "underline",
              }}
              href={userCustomerPortal}
            >
              Manage Payment Info
            </Link>
          )}
        </Stack>
        <Box sx={{ marginTop: "24px" }}>
          <BannerSubscription />
        </Box>
      </Box>
      <Box sx={{ marginBottom: "48px" }}>
        <Typography variant="h4">Account Information</Typography>
        <Box sx={{ marginTop: "24px" }}>
          <Stack direction="column" gap="16px">
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
              sx={{ width: "100%" }}
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Name
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={userInfo?.name}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Phone Number
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={userInfo?.phoneNumber}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Email
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={userInfo?.email}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Current Timezone
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={`${userInfo?.timezone} (${getLocalGMT()})`}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "48px" }}>
        <Typography variant="h4">Personal Information</Typography>
        <Box sx={{ marginTop: "24px" }}>
          <Stack direction="column" gap="16px" sx={{ width: "100%" }}>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Date of Birth
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={parseFormat(
                        userInfo?.personal_info.datebirth || "",
                        // eslint-disable-next-line prettier/prettier
                        "DD MMMM YYYY"
                      )}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Current Job
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={userInfo?.personal_info.do_living}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              sx={{ width: "100%" }}
              alignItems={{
                lg: "center",
                md: "center",
                xs: "start",
              }}
              alignContent="center"
              gap="16px"
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  MBTI
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={userInfo?.personal_info.mbti}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Stack
              direction={{
                lg: "row",
                md: "row",
                xs: "column",
              }}
              alignItems="start"
              alignContent="center"
              gap="16px"
              sx={{ marginBottom: "48px" }}
            >
              <Box sx={{ width: "178px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Love Language
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
              >
                {!isLoadingUserProfile && (
                  <FormControl fullWidth>
                    <OutlinedInput
                      defaultValue={removeAsterisks(
                        userInfo?.personal_info.love_language.join("\n") || ""
                      )}
                      disabled
                      color="secondary"
                      sx={{
                        ".Mui-disabled": {
                          WebkitTextFillColor: "#00000099",
                        },
                      }}
                      multiline
                      rows={5}
                    />
                  </FormControl>
                )}
              </Box>
            </Stack>
            <Box>
              <Typography
                // @ts-ignore
                variant="body14"
                sx={{ fontWeight: "400", color: "#2F4B73" }}
              >
                For further enquiries or help, please contact us at at{" "}
                <Link
                  href="mailto:support@ourcoach.ai"
                  sx={{ textDecoration: "underline" }}
                >
                  support@ourcoach.ai
                </Link>
                .
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "64px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ fontWeight: "400", color: "#00000099" }}
          >
            Beta Version 1.10
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Link
              target="_blank"
              href="https://ourcoach.ai/privacy-policy"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
                letterSpacing: "0.15px",
                color: "#00000099",
              }}
            >
              Privacy Policy
            </Link>
            <Divider
              sx={{ height: "16px", margin: "0px 12px" }}
              orientation="vertical"
            />
            <Link
              target="_blank"
              href="https://ourcoach.ai/terms-of-service"
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
                letterSpacing: "0.15px",
                color: "#00000099",
              }}
            >
              Terms of Service
            </Link>
          </Stack>
        </Stack>
      </Box>
    </AppLayout>
  );
}

export default ProfilePage;
