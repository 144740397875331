import {
  BookingReportRequest,
  BookingRequest,
  BookingReviewRequest,
  CancelBookingRequest,
  RescheduleBookingRequest,
} from "@type/booking";
import { getCookie } from "@util/cookie";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_BASE_URL;
export const bookingGrowthGuide = (payload: BookingRequest) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/booking/growth-guide`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  });
};

export const reschduleBookingGrowthGuide = (
  // eslint-disable-next-line prettier/prettier
  payload: RescheduleBookingRequest
) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/booking/growth-guide/reschedule`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  });
};

export const getBookingGrowthGuideInfo = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/booking/info`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitBookingReviewGrowthGuide = (
  // eslint-disable-next-line prettier/prettier
  payload: BookingReviewRequest
) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/booking/review`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  });
};

export const cancelBooking = (
  // eslint-disable-next-line prettier/prettier
  payload: CancelBookingRequest
) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/booking/cancel`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  });
};

// getBookingInfoSumary - will be access from coach only
export const getBookingInfoSumary = (bookingID: string) => {
  return axios({
    url: `${apiURL}/booking-summary/${bookingID}/report`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// getBookingInfoSumary - will be access from coach only
export const submitBookingSummaryReport = (
  bookingID: string,
  // eslint-disable-next-line prettier/prettier
  ggReport: BookingReportRequest[]
) => {
  return axios({
    url: `${apiURL}/booking-summary/${bookingID}/report`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      gg_report: ggReport,
    },
  });
};
