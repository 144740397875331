export function hidePhoneNumber(phoneNumber: string) {
  if (typeof phoneNumber !== "string" || phoneNumber.length < 7) {
    return "";
  }

  const prefix = phoneNumber.slice(0, 3);
  const suffix = phoneNumber.slice(-4);
  return `${prefix}******${suffix}`;
}

export function hideEmail(email: string) {
  if (typeof email !== "string") {
    return "";
  }

  const emailArr = email.split("@");

  const prefix = emailArr[0].slice(0, 3);
  const suffix = emailArr[1].slice(-4);
  return `${prefix}***@***${suffix}`;
}

export function genKey(length = 8) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomKey = "";
  for (let i = 0; i < length; i++) {
    randomKey += characters.charAt(
      // eslint-disable-next-line prettier/prettier
      Math.floor(Math.random() * characters.length)
    );
  }
  return randomKey;
}

export function getElementOffset(element: HTMLElement | null): number {
  if (!element) {
    return 0;
  }

  const containerWidth = 1240;
  const windowWidth = window.innerWidth;

  const horizontalOffset = (windowWidth - containerWidth) / 2;

  return horizontalOffset;
}

export function removeAsterisks(str: string): string {
  return str.replace(/\*/g, "");
}

export function splitFullName(fullName: string): {
  firstName: string;
  lastName: string;
} {
  const parts = fullName.trim().split(/\s+/);
  const firstName = parts[0] || "";
  const lastName = parts.slice(1).join(" ") || "";
  return { firstName, lastName };
}

export function convertToSnakeCase(input: string): string {
  return input.toLowerCase().replace(/\s+/g, "_");
}
