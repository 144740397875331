// src/hooks/useSocket.ts
import { getCookie } from "@util/cookie";
import { useState, useEffect } from "react";
import io, { Socket } from "socket.io-client";

export const useSocket = (namespace: string) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  // Function to fetch the token, waiting until it becomes available
  const fetchToken = async (): Promise<string | null> => {
    let token = getCookie("authToken");

    while (!token) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait briefly before checking again
      token = getCookie("authToken");
    }

    return token;
  };

  useEffect(() => {
    const initializeSocket = async () => {
      const token = await fetchToken(); // Wait until the token is available

      if (token) {
        const apiURL = process.env.REACT_APP_API_BASE_URL;

        const newSocket = io(`${apiURL}/${namespace}`, {
          transportOptions: {
            polling: {
              extraHeaders: { Authorization: `Bearer ${token}` },
            },
          },
        });

        newSocket.on("connect", () => setIsConnected(true));
        newSocket.on("disconnect", () => setIsConnected(false));

        setSocket(newSocket);

        return () => {
          newSocket.disconnect();
        };
      }
    };

    initializeSocket();
  }, [namespace]);

  return { socket, isConnected };
};
