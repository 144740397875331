import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router";
import { SocketProvider } from "./context/SocketContext";
import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { catchErrorLog } from "@util/sentry";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import RequireAuth from "./auth/IsAuthenticated";
import LoginPage from "@page/login";
import ActivatePage from "@page/activate-account";
import VerificationOTPPage from "@page/verification-otp";
import ForgotPasswordPage from "@page/forgot-password";
import WelcomeMessagePage from "@page/private/welcome-message";
import HomePage from "@page/private/home";
import ProfilePage from "@page/private/profile";
import BookingSummary from "@page/private/booking-report";
import PageNotFound from "@page/404";
import PageInternaServerError from "@page/500";
import PaymentCompletedPage from "@page/private/payment/completed";
import SubscriptionPlanPage from "@page/private/subscription-plan";
import YourGrowthJourneyPage from "@page/private/your-growth-journey";
import theme from "./theme";
import "App.css";
import YourGrowthGuideTeam from "@page/private/your-growth-guide-team";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG || "");

function App() {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <SocketProvider>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={PageInternaServerError}
          onError={(err: Error) => {
            catchErrorLog(err.message);
          }}
        >
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/activate-account" element={<ActivatePage />} />
              <Route path="/verify" element={<VerificationOTPPage />} />
              <Route
                path="/booking/:bookingid/summary"
                element={<BookingSummary />}
              />
              <Route element={<RequireAuth />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/welcome" element={<WelcomeMessagePage />} />
                <Route
                  path="/your-growth-guide"
                  element={<YourGrowthGuideTeam />}
                />
                <Route
                  path="/your-growth-journey"
                  element={<YourGrowthJourneyPage />}
                />
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/subscription/plan"
                  element={<SubscriptionPlanPage />}
                />
                <Route
                  path="/payment/completed"
                  element={<PaymentCompletedPage />}
                />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </SocketProvider>
  );
}

export default App;
