import { SxProps } from "@mui/material";
import { BoxStyled } from "./styled";
import Box from "@mui/system/Box";

interface Props {
  sx?: SxProps;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

function BoxProgress(props: Props) {
  return (
    <BoxStyled sx={props.sx}>
      {props.children}
      {props.icon && <Box>{props.icon}</Box>}
    </BoxStyled>
  );
}

export default BoxProgress;
