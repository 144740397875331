import dayjs from "dayjs";

export function getTodayDateString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getDateWithDay(dateString: string) {
  const date = new Date(dateString);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayName = days[date.getDay()];
  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${dayName}, ${day} ${month} ${year} ${dayjs(dateString).format("HH:mm A")}`;
}

export function convertToLocalTime(dateTime: string) {
  return dayjs(dateTime).format("HH:mm");
}

export function parseFormat(dateTime: string, format: string) {
  return dayjs(dateTime).format(format);
}

export function getEndOfMonth() {
  const today = new Date();
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const day = lastDay.getDate();
  const month = lastDay.toLocaleString("en-US", { month: "short" });

  return `${day} ${month}`;
}

export function getLocalGMT() {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = -offsetMinutes / 60;
  const sign = offsetHours >= 0 ? "+" : "-";
  const hours = Math.abs(Math.floor(offsetHours));

  return `GMT${sign}${hours}`;
}

export function calculateWeeksInRange(startDate: Date, endDate: Date): number {
  if (endDate < startDate) {
    throw new Error("End date must be greater than or equal to start date");
  }

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const diffInMilliseconds = endDate.getTime() - startDate.getTime();
  const diffInDays = diffInMilliseconds / millisecondsPerDay;

  const weeks = Math.ceil(diffInDays / 7);
  return weeks;
}

export function setSubscriptionPlanEffectiveDate(interval: string) {
  const today = new Date();
  let effectiveDate = new Date(today);

  if (interval === "monthly") {
    effectiveDate.setMonth(today.getMonth() + 1);
  } else if (interval === "yearly") {
    effectiveDate.setFullYear(today.getFullYear() + 1);
  }

  return effectiveDate.toLocaleDateString();
}
