/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { retrieveSessionPayment } from "@service/payment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import AppLayout from "@container/app-layout";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

function PaymentCompletedPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [statusPayment, setStatusPayment] = useState<string>("");

  useEffect(() => {
    const session_id = new URLSearchParams(window.location.search).get(
      "session_id"
    );
    if (!session_id) {
      navigate("/");
      return;
    }

    retrieveSessionPayment(session_id)
      .then((resp) => {
        setStatusPayment(resp.data.data.status);
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(e?.response?.data.message, { variant: "error" });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("status: ", statusPayment);
  return (
    <AppLayout>
      <Box
        sx={{
          paddingTop: "147px",
          margin: {
            lg: "0 auto",
            xs: "0",
          },
          width: {
            lg: "386px",
            xs: "100%",
          },
        }}
      >
        <Stack
          direction="column"
          gap="32px"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "60px" }}>🎉</Typography>
          <Stack
            direction="column"
            gap="16px"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: "24px",
                  lg: "32px",
                },
              }}
            >
              Subscription confirmed!
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                color: "#000000DE",
                textAlign: "center",
              }}
            >
              Your subscription is now active. Start making the most of your
              plan today!
            </Typography>
          </Stack>
          <Button
            size="large"
            variant="contained"
            onClick={() => navigate("/")}
            fullWidth
          >
            Back to Dashboard
          </Button>
        </Stack>
      </Box>
    </AppLayout>
  );
}

export default PaymentCompletedPage;
