import { CoachInfo } from "@type/coach";
import { parseFormat } from "@util/date";
import { useUserProfileContext } from "context/user-profile-context";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Skeleton from "components/skeleton";
import SkeletonMUI from "@mui/material/Skeleton";

interface Props {
  isWelcomeMessage?: boolean;
  coachInfo?: CoachInfo;
  isLoading?: boolean;
}

function GrowthGuide(props: Props) {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();

  return (
    <Box
      sx={{
        // height: "296px",
        width: {
          md: "310px",
          xs: "100%",
        },
        padding: "16px",
        borderRight: {
          md: "1px solid #E5E5E5",
          xs: "none",
        },
        borderBottom: {
          md: "none",
          xs: "1px solid #E5E5E5",
        },
      }}
    >
      <Stack
        direction="column"
        gap="16px"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <Stack direction="column" gap="16px">
          <Stack direction="row" gap="10px">
            {props.isLoading ? (
              <SkeletonMUI variant="circular" width={48} height={48} />
            ) : (
              <Avatar sx={{ width: "68px", height: "68px" }}>
                <img
                  src={`${process.env.REACT_APP_DOMAIN_ASSET_URL}/avatar/${props.coachInfo?.avatar}`}
                  alt={props.coachInfo?.fullName ?? ""}
                  style={{ width: "100%" }}
                />
              </Avatar>
            )}

            <Stack direction="column" gap="4px">
              {props.isLoading ? (
                <Stack direction="column" sx={{ width: "100%" }} gap="8px">
                  <Skeleton sx={{ width: "150px", height: "16px" }} />
                  <Skeleton sx={{ width: "80px", height: "16px" }} />
                </Stack>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#000000DE",
                    }}
                  >
                    {props.coachInfo?.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#000000DE",
                    }}
                  >
                    {props.coachInfo?.specialist}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          <Box
            sx={{
              width: "100%",
              height: { lg: "auto", md: "100%", xs: "100%" },
              overflow: { lg: "hidden", md: "unset", xs: "unset" },
            }}
          >
            {props.isLoading ? (
              <Stack direction="column" gap="8px">
                <Skeleton sx={{ width: "80%", height: "16px" }} />
                <Skeleton sx={{ width: "100%", height: "16px" }} />
                <Skeleton sx={{ width: "40%", height: "16px" }} />
              </Stack>
            ) : (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  letterSpacing: "0.15px",
                  color: "#000000DE",
                }}
              >
                {props.coachInfo?.bio}
              </Typography>
            )}
          </Box>
        </Stack>
        {!props.isWelcomeMessage && (
          <Stack direction="column">
            <Divider
              sx={{
                marginTop: "16px",
                marginBottom: "16px",
              }}
            />
            <Stack direction="row">
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#00000061",
                  }}
                >
                  Session completed
                </Typography>
                {!isLoadingUserProfile && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "21px",
                      letterSpacing: "0.15px",
                    }}
                  >
                    {userInfo?.booking.total_session_completed || 0}
                  </Typography>
                )}
              </Box>
              <Divider
                sx={{ height: "39px", margin: "0px 16px" }}
                orientation="vertical"
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#00000061",
                  }}
                >
                  Last session date
                </Typography>
                {!isLoadingUserProfile && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "21px",
                      letterSpacing: "0.15px",
                    }}
                  >
                    {userInfo?.booking.last_session_at !== ""
                      ? parseFormat(
                          userInfo?.booking.last_session_at || "",
                          // eslint-disable-next-line prettier/prettier
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default GrowthGuide;
