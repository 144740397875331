/* eslint-disable prettier/prettier */
import { useState } from "react";
import { useSnackbar } from "notistack";
import { reactivateUserSubscriptionPlan } from "@service/user";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { parseFormat } from "@util/date";
import { UserSubscriptionInfo } from "@type/user";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

interface Props {
  isOpen: boolean;
  userSubscriptionInfo?: UserSubscriptionInfo;
  onClose: (event: any, reason: string) => void;
}

function DialogSubscriptionReactivate(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingReactivateSubscription, setLoadingReactivateSubscription] =
    useState<boolean>(false);

  const onCancelSubscription = () => {
    setLoadingReactivateSubscription(true);
    reactivateUserSubscriptionPlan()
      .then((resp) => {
        setLoadingReactivateSubscription(false);
        enqueueSnackbar(resp.data.message, {
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
        props.onClose(null, "");
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(e?.response?.data.message, { variant: "error" });
        }
        setLoadingReactivateSubscription(false);
      });
  };

  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          maxWidth: {
            lg: "410px",
            sm: "410px",
            xs: "100%",
          },
          borderRadius: "8px",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#000000DE",
          }}
        >
          Reactivate your subscription?
        </Typography>
        <IconButton onClick={() => props.onClose(null, "")}>
          <XMarkIcon
            style={{ width: "16px", height: "16px", color: "#000000DE" }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "24px", paddingTop: "16px !important" }}>
        <Box sx={{ marginBottom: "16px" }}>
          <Typography
            // @ts-ignore
            variant="body14"
            sx={{ color: "#000000DE", fontWeight: "400" }}
          >
            Your{" "}
            <b style={{ textTransform: "capitalize" }}>
              {props.userSubscriptionInfo?.interval}
            </b>{" "}
            subscription is valid until{" "}
            <b>
              {parseFormat(
                props.userSubscriptionInfo?.current_period_end || "",
                "DD/MM/YYYY"
              )}
            </b>
            . Reactivate now to continue enjoying all the features
            uninterrupted.
          </Typography>
        </Box>

        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.15px",
            fontWeight: "400",
            color: "#00000099",
          }}
        >
          If you need assistance or have any questions, contact our support team
          at{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:support@ourcoach.ai"
            style={{ color: "#2F4B73" }}
          >
            support@ourcoach.ai
          </a>
          .
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap="10px"
          sx={{ marginTop: "24px" }}
        >
          <Button
            onClick={() => props.onClose(null, "")}
            disabled={isLoadingReactivateSubscription}
            variant="contained"
            color="secondary"
          >
            No, thanks
          </Button>
          <Button
            variant="contained"
            disabled={isLoadingReactivateSubscription}
            onClick={onCancelSubscription}
          >
            Reactivate Subscription
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DialogSubscriptionReactivate;
