/* eslint-disable prettier/prettier */
import { calculateWeeksInRange, parseFormat } from "@util/date";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { UserSubscriptionInfo } from "@type/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogSubscriptionReactivate from "@component/dialog/dialog-subscription-reactivate";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface Props {
  userSubscriptionInfo?: UserSubscriptionInfo;
}
function AlertSubscription(props: Props) {
  const navigate = useNavigate();
  const [
    isOpenDialogSubscriptionReactivate,
    setOpenDialogSubscriptionReactivate,
  ] = useState<boolean>(false);

  if (!props.userSubscriptionInfo) {
    return;
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "63px",
          padding: "15px",
          background:
            props.userSubscriptionInfo?.status === "trialing"
              ? "#FFFFFFDE"
              : "#F0CDC4 ",
          borderTop:
            props.userSubscriptionInfo?.status === "trialing"
              ? "1px solid #2F4B7380"
              : "1px solid #000000",
          borderBottom:
            props.userSubscriptionInfo?.status === "trialing"
              ? "1px solid #2F4B7380"
              : "1px solid #000000",
          zIndex: "99",
          position: "fixed",
          top: "-3px",
          left: "0px",
          right: "0px",
        }}
      >
        <Container maxWidth="lg" sx={{ padding: "0px" }}>
          <Stack
            direction={{
              lg: "row",
              md: "row",
              xs: "column",
            }}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap={{
              lg: "24px",
              md: "24px",
              xs: "8px",
            }}
          >
            {(props.userSubscriptionInfo?.status === "not_subscribed" ||
              props.userSubscriptionInfo?.status === "canceled") && (
              <Stack
                direction="row"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                gap="8px"
              >
                <Box
                  sx={{
                    display: {
                      lg: "block",
                      md: "block",
                      xs: "none",
                    },
                  }}
                >
                  <ExclamationCircleIcon
                    style={{ width: "24px", color: "#000000DE" }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      xs: "14px",
                    },
                    lineHeight: {
                      lg: "24px",
                      md: "24px",
                      xs: "21px",
                    },
                    letterSpacing: "0.15px",
                    fontWeight: 600,
                    textAlign: {
                      lg: "left",
                      md: "left",
                      xs: "center",
                    },
                  }}
                >
                  You currently have no access to the services. Subscribe today
                  to unlock exclusive benefits!
                </Typography>
              </Stack>
            )}
            {props.userSubscriptionInfo?.canceled_at &&
              props.userSubscriptionInfo?.status === "active" && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  gap="8px"
                >
                  <ExclamationCircleIcon
                    style={{ width: "24px", color: "#000000DE" }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        lg: "16px",
                        md: "16px",
                        xs: "14px",
                      },
                      lineHeight: {
                        lg: "24px",
                        md: "24px",
                        xs: "21px",
                      },
                      letterSpacing: "0.15px",
                      fontWeight: 600,
                    }}
                  >
                    Your Subscription ends soon, you’ll lose access to the
                    services on{" "}
                    {parseFormat(
                      props.userSubscriptionInfo?.canceled_at || "",
                      "DD/MM/YYYY"
                    )}
                    .
                  </Typography>
                </Stack>
              )}
            {props.userSubscriptionInfo?.status === "trialing" && (
              <Typography
                sx={{
                  fontSize: {
                    lg: "16px",
                    md: "16px",
                    xs: "14px",
                  },
                  lineHeight: {
                    lg: "24px",
                    md: "24px",
                    xs: "21px",
                  },
                  letterSpacing: "0.15px",
                  fontWeight: 600,
                  textAlign: {
                    lg: "left",
                    md: "left",
                    xs: "center",
                  },
                }}
              >
                ✨ You’re on a{" "}
                <span style={{ fontWeight: 700, color: "#000000DE" }}>
                  {calculateWeeksInRange(
                    new Date(props.userSubscriptionInfo?.trial_start || ""),
                    new Date(props.userSubscriptionInfo?.trial_end || "")
                  )}
                  -week Free Trial -
                </span>{" "}
                until{" "}
                {parseFormat(
                  props.userSubscriptionInfo?.trial_end || "",
                  "DD/MM/YYYY"
                )}
                . Subscribe now to keep the benefits rolling!
              </Typography>
            )}

            <Button
              variant="contained"
              color={
                props.userSubscriptionInfo?.status === "active" &&
                props.userSubscriptionInfo?.canceled_at
                  ? "secondary"
                  : "primary"
              }
              onClick={
                props.userSubscriptionInfo?.status === "active" &&
                props.userSubscriptionInfo?.canceled_at
                  ? () => setOpenDialogSubscriptionReactivate(true)
                  : () => navigate("/subscription/plan")
              }
              endIcon={
                (props.userSubscriptionInfo?.status === "not_subscribed" ||
                  props.userSubscriptionInfo?.status === "canceled" ||
                  (props.userSubscriptionInfo?.status === "active" &&
                    props.userSubscriptionInfo?.canceled_at)) && (
                  <ArrowRightIcon style={{ width: "20px" }} />
                )
              }
            >
              {props.userSubscriptionInfo?.status === "active" &&
              props.userSubscriptionInfo?.canceled_at
                ? "Reactivate Subscription"
                : "Upgrade to Premium"}
            </Button>
          </Stack>
        </Container>
      </Box>
      <DialogSubscriptionReactivate
        isOpen={isOpenDialogSubscriptionReactivate}
        userSubscriptionInfo={props.userSubscriptionInfo}
        onClose={() => {
          setOpenDialogSubscriptionReactivate(false);
        }}
      />
    </>
  );
}

export default AlertSubscription;
