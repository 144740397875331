import Cookies from "universal-cookie";

export function setCookie(key: string, data: any) {
  const coookie = new Cookies();
  coookie.set(key, data, { path: "/" });
}

export function removeCookie(key: string) {
  const coookie = new Cookies();
  coookie.remove(key);
}

export function getCookie(key: string) {
  const cookie = new Cookies();
  return cookie.get(key);
}
