import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function PasswordReset() {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          width: "100%",
          fontSize: "80px",
          marginBottom: "62px",
          textAlign: "center",
        }}
      >
        🎉
      </Box>
      <Typography sx={{ textAlign: "center" }} variant="h3">
        Your password has been reset!
      </Typography>

      <Button
        sx={{ marginTop: "32px" }}
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate("/login", { replace: true })}
      >
        Back to Log in
      </Button>
    </Stack>
  );
}

export default PasswordReset;
