/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { catchErrorLog } from "@util/sentry";
import { getUserSummary, getUserWelcomeMessage } from "@service/user";
import { useNavigate } from "react-router-dom";
import { useUserProfileContext } from "context/user-profile-context";
import { convertToSnakeCase, removeAsterisks } from "@util/string";
import { coachAsset } from "config/coach";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";

function WelcomeMessagePage() {
  const navigate = useNavigate();
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [isLoadingWelcomeMessage, setLoadingWelcomeMessage] =
    useState<boolean>(true);
  const [welcomeMessage, setWelcomeMessage] = useState<string>("");
  const [isLoadingUserSummary, setLoadingUserSummary] = useState<boolean>(true);
  const [retryUserSummaryCall, setRetryUserSummaryCall] = useState<number>(0);

  useEffect(() => {
    getUserWelcomeMessage()
      .then((resp) => {
        setWelcomeMessage(resp.data.data.content);
        setLoadingWelcomeMessage(false);
      })
      .catch((e) => {
        catchErrorLog(`failed to get user welcome message, err: ${e.message}`);
        setLoadingWelcomeMessage(false);
      });

    reloadUserSummary();
  }, []);

  const reloadUserSummary = () => {
    setLoadingUserSummary(true);
    getUserSummary()
      .then((resp) => {
        setLoadingUserSummary(false);
      })
      .catch((e) => {
        catchErrorLog(`failed to get user summary, err: ${e.message}`);
        setRetryUserSummaryCall((prevState) => {
          return prevState + 1;
        });
      });
  };

  useEffect(() => {
    if (retryUserSummaryCall > 0 && retryUserSummaryCall <= 5) {
      reloadUserSummary();
    }
  }, [retryUserSummaryCall]);

  return (
    <>
      <AppLayout>
        {isLoadingUserSummary || isLoadingWelcomeMessage ? (
          <Stack
            direction="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            gap="16px"
            sx={{ height: "calc(100vh - 68px)" }}
          >
            <Box>
              {/* @ts-ignore */}
              <CircularProgress color="#222222" size="48px" />
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  lg: "42px",
                  md: "42px",
                  xs: "20px",
                },
                lineHeight: {
                  lg: "50.4px",
                  md: "50.4px",
                  xs: "24px",
                },
              }}
            >
              Setting up your profile...
            </Typography>
            <Typography
              // @ts-ignore
              variant="body14"
              sx={{ color: "#000000DE", fontWeight: 400, textAlign: "center" }}
            >
              Please take a moment — it’ll be ready any minute now. 😊
            </Typography>
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                marginTop: { md: "100px", xs: "84px" },
                marginBottom: { md: "32px", xs: "16px" },
                padding: "32px 48px !important",
                background: "#EBE1D3",
                borderRadius: "8px",
                h1: {
                  fontSize: {
                    lg: "42px",
                    md: "42px",
                    xs: "20px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    lg: "50.4px",
                    md: "50.4px",
                    xs: "24px",
                  },
                  color: "#000000DE",
                  margin: "0px",
                },
                h2: {
                  fontSize: {
                    lg: "32px",
                    md: "32px",
                    xs: "16px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    lg: "38.4px",
                    md: "38.4px",
                    xs: "24px",
                  },
                  color: "#000000DE",
                  marginBottom: "8px",
                },
              }}
            >
              <ReactMarkdown>{welcomeMessage}</ReactMarkdown>
              <Avatar
                sx={{
                  height: "58px",
                  width: "58px",
                }}
              >
                {!isLoadingUserProfile &&
                  userInfo?.personal_info?.legend_persona.coach !== "" && (
                    <img
                      src={`${process.env.REACT_APP_DOMAIN_ASSET_URL}/coach/${
                        coachAsset[
                          convertToSnakeCase(
                            removeAsterisks(
                              userInfo?.personal_info?.legend_persona.coach ??
                                ""
                            )
                          ) as keyof typeof coachAsset
                        ] ?? ""
                      }`}
                      alt={
                        removeAsterisks(
                          userInfo?.personal_info?.legend_persona.coach ?? ""
                        ) ?? ""
                      }
                      style={{ width: "100%" }}
                    />
                  )}
              </Avatar>
            </Box>
            <Box sx={{ marginTop: "32px", marginBottom: "32px" }}>
              <Button
                fullWidth
                variant="contained"
                disabled={isLoadingWelcomeMessage}
                onClick={() => navigate("/your-growth-journey")}
                sx={{ height: "48px", fontSize: "16px !important" }}
              >
                Continue my journey
              </Button>
            </Box>
          </>
        )}
      </AppLayout>
    </>
  );
}

export default WelcomeMessagePage;
