/* eslint-disable prettier/prettier */
import { severityLevelFromString } from "@sentry/core";
import * as Sentry from "@sentry/react";

export function catchErrorLog(
  message: string,
  level: Sentry.SeverityLevel = severityLevelFromString("error")
): void {
  let errorMessage = JSON.parse(JSON.stringify(message));
  Sentry.captureMessage(errorMessage, level);
}

export function setLogUser(
  userId: string,
  name?: string,
  email?: string
): void {
  Sentry.setUser({ id: userId, username: name, email });
}
