/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SidebarStyle } from "./styled";
import { getElementOffset } from "@util/string";
import { SxProps } from "@mui/material";
import Container from "@mui/material/Container";
import Header from "components/header";

interface Props {
  isOpenSidebar?: boolean;
  isFullScreen?: boolean;
  isShowAlertSubscription?: boolean;
  alertSubscription?: React.ReactNode;
  sx?: SxProps;
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}

function AppLayout(props: Props) {
  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    const body = document.body;
    body.classList.remove("base");
    body.classList.add("app");
  }, []);

  useEffect(() => {
    const container = document.getElementById("container");
    setOffset(getElementOffset(container));
  }, [props.isOpenSidebar]);

  return (
    <>
      <Header />
      {props.isShowAlertSubscription && props.alertSubscription}
      <Container
        id="container"
        sx={{ position: "relative", ...props.sx }}
        maxWidth={
          props.isFullScreen ? false : props.isOpenSidebar ? "lg" : "md"
        }
        disableGutters
      >
        <Container
          maxWidth={props.isFullScreen ? false : "md"}
          sx={{
            ...props.sx,
            marginLeft: props.isOpenSidebar ? "0px" : "auto",
            marginRight: props.isOpenSidebar ? "40px" : "0px",
            marginTop: {
              lg: props.isShowAlertSubscription ? "122px" : "61px",
              md: props.isShowAlertSubscription ? "122px" : "61px",
              xs: props.isShowAlertSubscription ? "198px" : "61px",
            },
            padding: {
              lg: "0px",
              md: "0px",
              xs: "0px 16px",
            },
          }}
        >
          {props.children}
        </Container>
        <SidebarStyle
          sx={{
            right: `${offset}px`,
            marginTop: props.isShowAlertSubscription ? "61px" : "0px",
          }}
          className={props.isOpenSidebar ? "open" : ""}
        >
          {props.sidebar}
        </SidebarStyle>
      </Container>
    </>
  );
}

export default AppLayout;
