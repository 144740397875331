import { ModalProps } from "@mui/material/Modal";
import Popover, { PopoverVirtualElement } from "@mui/material/Popover";
import { listFlags } from "components/flag";
import { CountryFlag } from "@type/country";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { SxProps } from "@mui/material";

interface Props {
  selected?: CountryFlag;
  open: boolean;
  anchorEl?:
    | null
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement);
  onClose?: ModalProps["onClose"];
  onChange: any;
  sxPaper: SxProps;
}

function PopoverFlag(props: Props) {
  const containerRef = useRef(null);
  const itemRefs = useRef([]);

  const [listCountries, setListCountries] = useState<CountryFlag[]>(listFlags);
  const onKeyup = (event: any) => {
    const newListCountries = listFlags.filter(
      // eslint-disable-next-line prettier/prettier
      (item) =>
        // eslint-disable-next-line prettier/prettier
        item.label.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setListCountries(newListCountries);
  };

  const scrollToItem = (index: number) => {
    if (itemRefs.current[index]) {
      // @ts-ignore
      itemRefs.current[index].scrollIntoView({
        block: "center",
      });
    }
  };

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        scrollToItem(
          // eslint-disable-next-line prettier/prettier
          listFlags.findIndex((flag) => flag.code === props.selected?.code) ?? 0
        );
      }, 100);
    }
    return () => {
      setListCountries(listFlags);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: props.sxPaper,
        },
      }}
    >
      <FormControl fullWidth>
        <OutlinedInput
          fullWidth
          placeholder="Search"
          onKeyUp={onKeyup}
          sx={{
            borderRadius: "0px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none !important",
              borderBottom: "1px solid #D8D8D8 !important",
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <MagnifyingGlassIcon style={{ width: "20px" }} />
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        sx={{
          width: "386px",
          maxWidth: "100%",
          height: "calc(268px - 47px)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            maxHeight: "300px",
            overflowX: "hidden",
            overflowY: "scroll",
            marginRight: "-16px",
          }}
        >
          <MenuList
            ref={containerRef}
            sx={{
              padding: "0px",
              height: "100%",
              overflowY: "hidden",
              marginRight: "-16px",
            }}
          >
            {listCountries.map((item, index) => (
              <MenuItem
                // @ts-ignore
                ref={(el) => (itemRefs.current[index] = el)}
                key={index}
                sx={{
                  padding: "8px 16px",
                  "&:hover": {
                    background: "#EFEFEF !important",
                    ".MuiTypography-root": {
                      color: "#000000DE !important",
                    },
                  },
                }}
                onClick={() => {
                  props.onChange(item);
                  // setTimeout(() => {
                  //   setListCountries(listFlags);
                  // }, 1500);
                }}
                selected={item.code === props.selected?.code}
              >
                <Stack direction="row" gap={2}>
                  <img src={item.flag} alt={item.code} />
                  <Typography>
                    {item.label} ({item.dialCode})
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Box>
    </Popover>
  );
}

export default PopoverFlag;
