import { getCookie } from "@util/cookie";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_BASE_URL;
export const retrieveSessionPayment = (session_id: string) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/payment/session/${session_id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const paymentCheckout = (priceID: string) => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/payment/checkout`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {
      price_id: priceID,
    },
  });
};
