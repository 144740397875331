/* eslint-disable prettier/prettier */
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSubscriptionPlan } from "@service/subscription";
import { SubscriptionPlanItem } from "@type/subscription";
import { parseFormat } from "@util/date";
import { useUserSubscriptionContext } from "context/user-subscription-context";
import { paymentCheckout } from "@service/payment";
import { useSnackbar } from "notistack";
import DialogSubscriptionEnterprise from "@component/dialog/dialog-subscription-enterprise";
import Plan from "@component/plan";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";

function SubscriptionPlanPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingUserSubscription, userSubscriptionInfo] =
    useUserSubscriptionContext();
  const [
    isOpenDialogSubscriptionEnterprise,
    setIsOpenDialogSubscriptionEnterprise,
  ] = useState(false);
  const [selectedPlanItem, setSelectedPlanItem] = useState<
    SubscriptionPlanItem | undefined
  >(undefined);
  const [subscriptionPlan, setSubscriptionPlan] = useState<
    SubscriptionPlanItem[]
  >([]);

  useEffect(() => {
    getSubscriptionPlan().then((response) => {
      setSubscriptionPlan(response.data.data);
    });
  }, []);

  const onHandleCheckout = () => {
    setLoading(true);
    paymentCheckout(selectedPlanItem?.price_id || "")
      .then((resp) => {
        setLoading(false);
        window.location.href = resp.data.data;
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(e?.response?.data.message, { variant: "error" });
        }
        setLoading(false);
      });
  };

  if (isLoadingUserSubscription) {
    return;
  }

  return (
    <>
      <AppLayout sx={{ maxWidth: "1000px !important" }}>
        <Box
          sx={{
            paddingTop: { md: "40px", xs: "24px" },
            paddingBottom: { md: "40px", xs: "24px" },
          }}
        >
          <Stack
            gap="3px"
            direction="row"
            alignContent="center"
            alignItems="center"
          >
            <IconButton
              sx={{
                svg: {
                  width: {
                    lg: "32px",
                    md: "32px",
                    xs: "24px",
                  },
                  color: "#000000DE",
                },
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  lg: "32px",
                  md: "32px",
                  xs: "25px",
                },
              }}
            >
              Subscription Plans
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            marginBottom: {
              lg: "24px",
              sm: "24px",
              xs: "100px",
            },
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 500, color: "#000000DE" }}
            >
              Please select a plan below:
            </Typography>
            <Typography
              // @ts-ignore
              variant="body14"
              sx={{ fontWeight: 400, color: "#00000099" }}
            >
              For further enquiries or help, please contact our support team at{" "}
              <Link target="_blank" href="mailto:support@ourcoach.ai">
                support@ourcoach.ai
              </Link>
              .
            </Typography>
          </Box>
          <Grid container spacing="12px" columns={21}>
            {subscriptionPlan.map((planItem, index) => (
              <Grid key={index} size={{ lg: 7, sm: 21, xs: 21 }}>
                <Plan
                  interval={planItem.interval}
                  isSelected={
                    selectedPlanItem?.price_id === planItem.price_id ||
                    (userSubscriptionInfo?.price_id === planItem.price_id &&
                      userSubscriptionInfo?.status === "active")
                  }
                  title={
                    <Stack
                      direction="row"
                      gap="6px"
                      alignContent="center"
                      alignItems="center"
                      sx={{ marginBottom: "4px" }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#000000DE",
                          fontSize: "20px",
                          lineHeight: "24px",
                        }}
                      >
                        {`${planItem.symbol}${planItem.amount}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight: "19.2px",
                          letterSpacing: "0.15px",
                          color: "#000000DE",
                        }}
                      >
                        / {planItem.interval}
                      </Typography>
                    </Stack>
                  }
                  subtitle={planItem.description}
                  features={planItem.features}
                  onClick={
                    userSubscriptionInfo?.status !== "active"
                      ? () => setSelectedPlanItem(planItem)
                      : () => {}
                  }
                />
              </Grid>
            ))}
            <Grid size={{ lg: 7, sm: 21, xs: 21 }}>
              <Plan
                isSelected={selectedPlanItem?.price_id === "enterprise"}
                isEnterprice
                title={
                  <Box sx={{ marginBottom: "4px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000000DE",
                        fontSize: "18px",
                        lineHeight: "21.6px",
                      }}
                    >
                      Enterprise: Custom Pricing
                    </Typography>
                  </Box>
                }
                subtitle="Billed annually"
                features={[
                  "<b>Team Insights:</b> Admin portal for tracking and analyzing team performance",
                  "<b>Expert Partnerships:</b> Access to top coaches and psychologists for team support",
                  "<b>Custom Growth Plans:</b> Tailored strategies to foster organizational growth and culture",
                ]}
                onClick={
                  userSubscriptionInfo?.status !== "active"
                    ? () =>
                        setSelectedPlanItem({
                          price_id: "enterprise",
                          currency: "usd",
                          symbol: "$",
                          product: "",
                          description: "",
                          amount: 0,
                          interval: "",
                          features: [],
                        })
                    : () => {}
                }
              />
            </Grid>
          </Grid>
        </Box>
        {userSubscriptionInfo?.status !== "active" && (
          <Box>
            {selectedPlanItem && selectedPlanItem.price_id !== "enterprise" && (
              <Box
                sx={{
                  width: "100%",
                  zIndex: 10,
                  marginBottom: {
                    lg: "64px",
                    md: "64px",
                    xs: "0px",
                  },
                  position: {
                    lg: "relative",
                    md: "relative",
                    xs: "fixed",
                  },
                  left: "0px",
                  bottom: "0px",
                  padding: {
                    lg: "0px",
                    md: "0px",
                    xs: "16px",
                  },
                  background: "#F4EDE3",
                  borderTop: {
                    lg: "none",
                    md: "none",
                    xs: "1px solid #0000001F",
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      flex: 1,
                      width: {
                        lg: "100%",
                        sm: "100%",
                        xs: "211px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "16px",
                          sm: "16px",
                          xs: "14px",
                        },
                        fontWeight: 600,
                        lineHeight: {
                          lg: "24px",
                          sm: "24px",
                          xs: "21px",
                        },
                        letterSpacing: "0.15px",
                        color: "#000000DE",
                      }}
                    >
                      📌 You have selected{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {selectedPlanItem.interval}
                      </span>{" "}
                      subscription -{" "}
                      <span style={{ color: "#2F4B73" }}>
                        effective{" "}
                        {parseFormat(new Date().toISOString(), "D MMMM YYYY")}
                      </span>
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    endIcon={
                      <ArrowRightIcon
                        style={{ width: "20px", color: "#FFFFFFDE" }}
                      />
                    }
                    onClick={onHandleCheckout}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: {
                          lg: "block",
                          sm: "none",
                          xs: "none",
                        },
                      }}
                    >
                      Proceed to checkout
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        display: {
                          lg: "none",
                          sm: "block",
                          xs: "block",
                        },
                      }}
                    >
                      Checkout
                    </Box>
                  </Button>
                </Stack>
              </Box>
            )}
            {selectedPlanItem && selectedPlanItem.price_id === "enterprise" && (
              <Box
                sx={{
                  width: "100%",
                  zIndex: 10,
                  marginBottom: {
                    lg: "64px",
                    md: "64px",
                    xs: "0px",
                  },
                  position: {
                    lg: "relative",
                    md: "relative",
                    xs: "fixed",
                  },
                  left: "0px",
                  bottom: "0px",
                  padding: {
                    lg: "0px",
                    md: "0px",
                    xs: "16px",
                  },
                  background: "#F4EDE3",
                  borderTop: {
                    lg: "none",
                    md: "none",
                    xs: "1px solid #0000001F",
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      flex: 1,
                      width: {
                        lg: "100%",
                        sm: "80%",
                        xs: "211px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "16px",
                          md: "16px",
                          xs: "14px",
                        },
                        fontWeight: 600,
                        lineHeight: {
                          lg: "24px",
                          md: "24px",
                          xs: "21px",
                        },
                        letterSpacing: "0.15px",
                        color: "#000000DE",
                      }}
                    >
                      📌 You have selected Enterprise subscription
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => setIsOpenDialogSubscriptionEnterprise(true)}
                    endIcon={
                      <ArrowRightIcon
                        style={{ width: "20px", color: "#FFFFFFDE" }}
                      />
                    }
                  >
                    Contact us
                  </Button>
                </Stack>
              </Box>
            )}
          </Box>
        )}
      </AppLayout>
      <DialogSubscriptionEnterprise
        isOpen={isOpenDialogSubscriptionEnterprise}
        onClose={(_, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          setIsOpenDialogSubscriptionEnterprise(false);
        }}
      />
    </>
  );
}

export default SubscriptionPlanPage;
