/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { getCoachListSchedule } from "@service/coach";
import { CoachScheduleItem, ScheduleItem } from "@type/coach";
import { convertToLocalTime, getLocalGMT, parseFormat } from "@util/date";
import { BookingInfo } from "@type/booking";
import { useUserProfileContext } from "context/user-profile-context";
import { catchErrorLog } from "@util/sentry";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import DialogBookingSuccess from "components/dialog/dialog-booking-success";
import DialogRescheduleBooking from "@component/dialog/dialog-reschedule-booking";
import TwoWeekCalendar from "@component/two-week-calendar";

interface Props {
  bookingInfo?: BookingInfo;
  onReloadBookingInfo: () => void;
  onCloseSidebar: () => void;
  onBack: () => void;
}

function BookingGrowthGuideReschedule(props: Props) {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [coachSchedules, setCoachSchedules] = useState<CoachScheduleItem[]>([]);
  const [selectedScheduleItem, setSelectedScheduleItem] = useState<
    ScheduleItem | undefined
  >(undefined);
  const [selectedSchedule, setSelectedSchedule] = useState<
    CoachScheduleItem | undefined
  >(undefined);
  const [isOpenDialogBooking, setIsOpenDialogBooking] =
    useState<boolean>(false);
  const [isOpenBookingSuccess, setIsOpenBookingSuccess] =
    useState<boolean>(false);

  const reloadGetCoachList = () => {
    if (!isLoadingUserProfile) {
      getCoachListSchedule(userInfo?.booking.assign_growth_guide_id || "")
        .then((resp) => {
          setCoachSchedules(resp.data.data);
          if (resp.data.data.length > 0) {
            setSelectedSchedule(resp.data.data[0]);
          }
        })
        .catch((err) => {
          catchErrorLog(err?.response?.data.message);
        });
    }
  };

  useEffect(() => {
    reloadGetCoachList();
  }, []);

  return (
    <>
      <Stack direction="column" sx={{ marginTop: { md: "40px", xs: "0px" } }}>
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: {
              md: "24px",
              xs: "0px",
            },
            borderBottom: {
              md: "none",
              xs: "1px solid #00000014",
            },
            padding: {
              md: "0px",
              xs: "8px 16px",
            },
          }}
        >
          <Stack
            direction="row"
            gap="12px"
            alignContent="center"
            alignItems="center"
          >
            <IconButton onClick={props.onBack}>
              <ArrowLeftIcon style={{ width: "20px", color: "#000000DE" }} />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#000000DE" }}>
              Reschedule your session
            </Typography>
          </Stack>
          <IconButton onClick={props.onCloseSidebar}>
            <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
          </IconButton>
        </Stack>
        <Box
          sx={{
            padding: {
              md: "0px",
              xs: "12px 16px",
            },
          }}
        >
          <Box
            sx={{
              padding: "16px",
              background: "#FFFCFA",
              borderRadius: "8px",
              border: "1px solid #00000029",
              position: "relative",
            }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#000000DE",
                }}
              >
                Select date and time ({getLocalGMT()})
              </Typography>
            </Box>
            <Box>
              <TwoWeekCalendar
                isReschedule
                bookingDate={props.bookingInfo?.started_at}
                onSelected={(selectedDate: Date) => {
                  const date = coachSchedules.find(
                    // @ts-ignore
                    (item) =>
                      item.date ===
                      parseFormat(selectedDate.toDateString(), "YYYY-MM-DD")
                  );
                  setSelectedSchedule(date);
                }}
              />
            </Box>
            <Box sx={{ margin: "16px 0px 80px 0px" }}>
              {(selectedSchedule?.items || []).length > 0 ? (
                <Grid container spacing="8px">
                  {selectedSchedule?.items.map((item, index) => (
                    <Grid key={index}>
                      <Box
                        sx={{
                          border: "1px solid #0000004D",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          padding: "8px",
                          color: "#000000DE",
                          borderRadius: "4px",
                          cursor: "pointer",
                          background:
                            item.schedule_id ===
                            selectedScheduleItem?.schedule_id
                              ? "#ED9B003D"
                              : "#FFFFFF",
                          ":hover": {
                            background: "#00000014",
                          },
                        }}
                        onClick={() => setSelectedScheduleItem(item)}
                      >
                        {`${convertToLocalTime(item.start_time)} - ${convertToLocalTime(item.end_time)}`}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box>
                  <Typography>No schedule</Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "16px",
                left: "16px",
                right: "16px",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                disabled={!selectedScheduleItem}
                onClick={() => setIsOpenDialogBooking(true)}
              >
                {selectedScheduleItem
                  ? `Reschedule to  ${parseFormat(
                      selectedScheduleItem?.start_time || "",
                      "DD/MM/YYYY, HH:mm"
                    )} - ${parseFormat(
                      selectedScheduleItem?.end_time || "",
                      "HH:mm"
                    )}`
                  : `Reschedule session`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
      <DialogRescheduleBooking
        isOpen={isOpenDialogBooking}
        date={selectedSchedule?.date || ""}
        bookingID={props.bookingInfo?.booking_id || ""}
        selectedScheduleItem={selectedScheduleItem}
        onClose={(_, reason: string) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
          setIsOpenDialogBooking(false);
        }}
        onCompleted={() => {
          setIsOpenDialogBooking(false);
          setIsOpenBookingSuccess(true);
        }}
      />

      <DialogBookingSuccess
        isOpen={isOpenBookingSuccess}
        date={selectedSchedule?.date || ""}
        selectedScheduleItem={selectedScheduleItem}
        onClose={() => {
          setIsOpenBookingSuccess(false);
          setSelectedScheduleItem(undefined);
          reloadGetCoachList();
          props.onCloseSidebar();
          props.onReloadBookingInfo();
        }}
      />
    </>
  );
}

export default BookingGrowthGuideReschedule;
