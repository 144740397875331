import { LinearProgress, SxProps } from "@mui/material";
import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BoxProgress from "components/box-progress";
import MissionMilestone from "@component/mission-milestone";

interface Props {
  sx?: SxProps;
  isSidebar?: boolean;
  isLocked?: boolean;
  isCompleted?: boolean;
  title: string;
  currentPoint: number;
  maxPointMission?: number;
  progressBar?: number;
  titleLocked: string;
  bgIcon: React.ReactNode;
}

function MilestoneBox(props: Props) {
  return (
    <BoxProgress
      sx={{
        ...props.sx,
        opacity: props.isLocked ? "0.5" : "1",
      }}
      icon={props.bgIcon}
    >
      <Stack direction="column" gap="16px" sx={{ height: "100%" }}>
        <Stack direction="row" justifyContent="space-between" gap="8px">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              color: "#FFFFFFDE",
            }}
          >
            <span style={{ color: "#FFFFFF61" }}>Milestone:</span> {props.title}
          </Typography>
          <Stack direction="row" gap="8px">
            {[...new Array(props.maxPointMission)].map((_, index) => (
              <PuzzlePieceIcon
                key={index}
                style={{
                  width: "20px",
                  color:
                    props.currentPoint >= index + 1 ? "#FFFFFFDE" : "#FFFFFF61",
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack direction="column" gap="8px">
          {!props.isCompleted && !props.isLocked && (
            <Stack direction="column">
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                gap="8px"
                sx={{
                  padding: "8px",
                  borderRadius: "4px",
                  background: "#FFFFFF1F",
                  marginBottom: "16px",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Stack direction="column" gap="2px">
                    <MissionMilestone isCompleted>
                      Set your goal and begin your growth journey
                    </MissionMilestone>
                    <MissionMilestone>
                      Complete your first growth activity
                    </MissionMilestone>
                  </Stack>
                </Box>
                <Box sx={{ width: "85px", textAlign: "right" }}>
                  <Typography variant="h4" sx={{ color: "#FFFFFFDE" }}>
                    1/2
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "500",
                      lineHeight: "15px",
                      letterSpacing: "0.15px",
                      color: "#FFFFFF99",
                    }}
                  >
                    of Foundation I tasks completed
                  </Typography>
                </Box>
              </Stack>
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                gap="8px"
                alignItems="center"
              >
                {[...new Array(props.maxPointMission)].map((_, index) => (
                  <Stack
                    direction="column"
                    alignItems="center"
                    alignContent="center"
                    key={index}
                    sx={
                      props.currentPoint === index + 1
                        ? { flex: 1, position: "relative" }
                        : {
                            position: "relative",
                            width: props.isSidebar
                              ? "4px"
                              : {
                                  md: "88px",
                                  xs: "4px",
                                },
                          }
                    }
                  >
                    {props.currentPoint === index + 1 && (
                      <Box
                        component="span"
                        sx={{
                          width: "12px",
                          height: "16px",
                          borderTop: "12px solid #FFFFFF1F",
                          borderLeft: "12px solid transparent",
                          borderRight: "12px solid transparent",
                          borderBottom: "12px solid transparent",
                          position: "absolute",
                          top: "-16px",
                        }}
                      />
                    )}
                    <LinearProgress
                      sx={{
                        width: "100%",
                        borderRadius: "100px",
                        ".MuiLinearProgress-root": {
                          background: "#FFFFFF61",
                        },
                        ".MuiLinearProgress-bar": {
                          background: "#FFFFFFDE",
                          borderRadius: "100px",
                        },
                      }}
                      variant="determinate"
                      value={
                        props.currentPoint < index + 1
                          ? 0
                          : props.currentPoint === index + 1
                            ? props.progressBar
                            : 100
                      }
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "400",
              lineHeight: "15px",
              letterSpacing: "0.15px",
              color: "#FFFFFFDE",
            }}
          >
            {props.titleLocked}
          </Typography>
        </Stack>
      </Stack>
    </BoxProgress>
  );
}

export default MilestoneBox;
