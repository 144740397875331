import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import SkeletonMUI from "@mui/material/Skeleton";

interface Props {
  sx?: SxProps;
}

function Skeleton(props: Props) {
  return (
    <Box sx={props.sx}>
      <SkeletonMUI
        animation="wave"
        sx={{
          width: "100%",
          height: "100%",
          transform: "unset",
        }}
      />
    </Box>
  );
}

export default Skeleton;
