import { Collapse, SxProps } from "@mui/material";
import { AlertStyled } from "./styled";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  open: boolean;
  content?: string;
  sx?: SxProps;
  onClose: () => void;
}

function Alert(props: Props) {
  return (
    <Collapse in={props.open}>
      <AlertStyled
        sx={{
          ...props.sx,
          ".MuiAlert-action": {
            width: "20px",
            height: "20px",
            color: "#000000DE",
            ".MuiIconButton-root": {
              padding: "0px",
            },
          },
        }}
        severity="info"
        icon={false}
        onClose={props.onClose}
      >
        <AlertTitle
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "#000000DE",
          }}
        >
          {props.title}
        </AlertTitle>
        {props.content !== "" && (
          <Typography
            variant="body1"
            sx={{
              color: "#000000DE",
              fontSize: {
                lg: "16px",
                md: "16px",
                xs: "14px",
              },
            }}
          >
            {props.content}
          </Typography>
        )}
      </AlertStyled>
    </Collapse>
  );
}

export default Alert;
