import { useEffect, useState } from "react";
import { UserNoteItem } from "@type/user";
import { getUserNotes } from "@service/user";
import SessionNoteSidebarList from "./list";
import SessionNoteSidebarDetail from "./detail";

interface Props {
  onCloseSidebar: () => void;
  onBooking: () => void;
}

function SessionNoteSidebar(props: Props) {
  const [selectedUserNote, setSelectedUserNote] = useState<
    UserNoteItem | undefined
  >(undefined);
  const [userNotes, setUserNotes] = useState<UserNoteItem[]>([]);

  useEffect(() => {
    getUserNotes().then((resp) => {
      setUserNotes(resp.data.data.items || []);
    });
  }, []);

  if (selectedUserNote) {
    return (
      <SessionNoteSidebarDetail
        selectedUser={selectedUserNote}
        onBack={() => setSelectedUserNote(undefined)}
        onCloseSidebar={props.onCloseSidebar}
      />
    );
  }

  return (
    <SessionNoteSidebarList
      userNotes={userNotes}
      onSelected={(userNote) => setSelectedUserNote(userNote)}
      onCloseSidebar={props.onCloseSidebar}
      onBooking={props.onBooking}
    />
  );
}

export default SessionNoteSidebar;
