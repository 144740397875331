/* eslint-disable prettier/prettier */
import { UserLifeStatus } from "@type/user";
import { convertToFormattedText } from "@util/convert";
import { Avatar } from "@mui/material";
import { coachAsset } from "config/coach";
import { convertToSnakeCase, removeAsterisks } from "@util/string";
import { useUserProfileContext } from "context/user-profile-context";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "components/skeleton";
import GrowthStatusChart from "@component/growth-status-chart";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Button from "@mui/material/Button";

interface Props {
  isLoading: boolean;
  userLifeStatus?: UserLifeStatus;
}

function LifeFocus(props: Props) {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  return (
    <Stack
      direction={{
        md: "row",
        xs: "column",
      }}
      gap="16px"
      sx={{ marginBottom: "12px" }}
    >
      <GrowthStatusChart
        isLoading={props.isLoading && !!props.userLifeStatus}
        personalGrowth={props.userLifeStatus?.life_score.personal_growth || 0}
        healthWellness={
          props.userLifeStatus?.life_score.health_and_wellness || 0
        }
        mentalWellBeing={
          props.userLifeStatus?.life_score.mental_well_being || 0
        }
        careerGrowth={props.userLifeStatus?.life_score.career_growth || 0}
        relationship={props.userLifeStatus?.life_score.relationship || 0}
      />
      <Box
        sx={{
          width: {
            md: "496px",
            xs: "100%",
          },
          height: {
            md: "316px",
            xs: "auto",
          },
          border: "1px solid #00000066",
          borderRadius: "8px",
          background: "#FAF4EF",
        }}
      >
        <Stack
          direction={{
            md: "row",
            xs: "column",
          }}
          gap={{
            md: "8px",
            xs: "0px",
          }}
          justifyContent="flex-start"
          sx={{
            padding: "12px 16px",
            height: {
              md: "48px",
              xs: "auto",
            },
            borderBottom: "1px solid #00000029",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "0.15px",
                fontWeight: "500",
              }}
            >
              Mantra of the week:
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {props.isLoading ? (
              <Skeleton
                sx={{ width: "100%", height: "16px", marginTop: "5px" }}
              />
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.15px",
                  fontWeight: "700",
                }}
              >
                <b>{props.userLifeStatus?.mantra ?? ""}</b>
              </Typography>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            padding: "12px 16px",
            height: {
              md: "268px",
              xs: "auto",
            },
          }}
        >
          {!props.isLoading ? (
            <>
              {props.userLifeStatus &&
                props.userLifeStatus?.goal !== "" &&
                props.userLifeStatus?.achievement.length > 0 && (
                  <Stack
                    direction={{
                      md: "row",
                      xs: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          md: "148px",
                          xs: "100%",
                        },
                        paddingRight: "16px",
                        height: {
                          md: "240px",
                          xs: "auto",
                        },
                        marginBottom: {
                          md: "0px",
                          xs: "16px",
                        },
                      }}
                    >
                      {/* @ts-ignore */}
                      <Typography variant="body14" sx={{ color: "#000000DE" }}>
                        Your goal:
                      </Typography>
                      <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                        <Box sx={{ marginTop: "12px" }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: 400,
                              color: "#000000DE",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {convertToFormattedText(
                              props.userLifeStatus?.goal || ""
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        paddingLeft: {
                          md: "16px",
                          xs: "0px",
                        },
                        height: {
                          md: "240px",
                          xs: "auto",
                        },
                        borderLeft: {
                          md: "1px dashed #00000029",
                          xs: "unset",
                        },
                      }}
                    >
                      <Box sx={{ paddingBottom: "6px" }}>
                        <Typography
                          // @ts-ignore
                          variant="body14"
                          sx={{ color: "#000000DE" }}
                        >
                          Your 5 recent achievements:
                        </Typography>
                      </Box>
                      <Stack
                        direction="column"
                        gap="8px"
                        sx={{
                          overflow: "hidden",
                          height: {
                            md: "210px",
                            xs: "auto",
                          },
                        }}
                      >
                        <Stack
                          gap="8px"
                          sx={{
                            overflowX: "hidden",
                            overflowY: "scroll",
                            paddingRight: {
                              md: "3px",
                              xs: "0px",
                            },
                            marginRight: {
                              md: "-16px",
                              xs: "0px",
                            },
                          }}
                        >
                          {props.userLifeStatus?.achievement.map(
                            (item, index) => (
                              <Stack
                                key={index}
                                direction="row"
                                sx={{
                                  padding: "6px 8px",
                                  background: "#E9E8D6",
                                  border: "1px solid #00000029",
                                  borderRadius: "4px",
                                  borderTopLeftRadius: "0px",
                                }}
                                gap="8px"
                              >
                                <Box>🎯</Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                      fontWeight: 400,
                                      color: "#000000DE",
                                      letterSpacing: "0.15px",
                                    }}
                                  >
                                    {convertToFormattedText(item)}
                                  </Typography>
                                </Box>
                              </Stack>
                              // eslint-disable-next-line prettier/prettier
                            )
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                )}

              {props.userLifeStatus &&
                props.userLifeStatus?.goal === "" &&
                props.userLifeStatus?.achievement.length === 0 && (
                  <Stack
                    direction="column"
                    gap="8px"
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Avatar sx={{ width: "64px", height: "64px" }}>
                      {!isLoadingUserProfile &&
                        userInfo?.personal_info?.legend_persona.coach !==
                          "" && (
                          <img
                            src={`${process.env.REACT_APP_DOMAIN_ASSET_URL}/coach/${
                              coachAsset[
                                convertToSnakeCase(
                                  removeAsterisks(
                                    userInfo?.personal_info?.legend_persona
                                      .coach ?? ""
                                  )
                                ) as keyof typeof coachAsset
                              ] ?? ""
                            }`}
                            alt={
                              removeAsterisks(
                                userInfo?.personal_info?.legend_persona.coach ??
                                  ""
                              ) ?? ""
                            }
                            style={{ width: "100%" }}
                          />
                        )}
                    </Avatar>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        letterSpacing: "0.15px",
                        color: "#00000099",
                      }}
                    >
                      AI{" "}
                      {removeAsterisks(
                        userInfo?.personal_info?.legend_persona.coach ?? ""
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        letterSpacing: "0.15px",
                        color: "#000000DE",
                      }}
                    >
                      Take the first step in your growth journey!
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "400",
                        letterSpacing: "0.15px",
                        textAlign: "center",
                      }}
                    >
                      Set your goals with{" "}
                      {removeAsterisks(
                        userInfo?.personal_info?.legend_persona.coach ?? ""
                      )}{" "}
                      on WhatsApp to
                      <br /> unlock personalized insights and stay on track.
                    </Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      endIcon={<WhatsAppIcon />}
                      sx={{
                        height: "48px",
                        fontSize: "16px !important",
                        marginTop: "16px",
                      }}
                      onClick={() => {
                        window.location.href = `${process.env.REACT_APP_REDIRECT_TO_CHAT_BOT}?text=Hello!%20I'm%20ready%20to%20get%20started!`;
                      }}
                    >
                      Check in with{" "}
                      {removeAsterisks(
                        userInfo?.personal_info?.legend_persona.coach ?? ""
                      )}
                    </Button>
                  </Stack>
                )}
            </>
          ) : (
            <Stack
              direction={{
                md: "row",
                xs: "column",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  paddingRight: "16px",
                  height: {
                    md: "240px",
                    xs: "auto",
                  },
                  marginBottom: {
                    md: "0px",
                    xs: "16px",
                  },
                }}
              >
                {/* @ts-ignore */}
                <Typography variant="body14" sx={{ color: "#000000DE" }}>
                  Your goal:
                </Typography>
                <Box sx={{ overflow: "hidden", maxWidth: "100%" }}>
                  <Stack gap="8px" sx={{ marginTop: "12px" }}>
                    <Skeleton sx={{ width: "154px", height: "12px" }} />
                    <Skeleton sx={{ width: "216px", height: "12px" }} />
                    <Skeleton sx={{ width: "88px", height: "12px" }} />
                  </Stack>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  paddingLeft: {
                    md: "16px",
                    xs: "0px",
                  },
                  height: {
                    md: "240px",
                    xs: "auto",
                  },
                  borderLeft: {
                    md: "1px dashed #00000029",
                    xs: "unset",
                  },
                }}
              >
                <Box sx={{ paddingBottom: "6px" }}>
                  <Typography
                    // @ts-ignore
                    variant="body14"
                    sx={{ color: "#000000DE" }}
                  >
                    Your 5 recent achievements:
                  </Typography>
                </Box>
                <Stack
                  direction="column"
                  gap="8px"
                  sx={{
                    overflow: "hidden",
                    height: {
                      md: "210px",
                      xs: "auto",
                    },
                  }}
                >
                  <Stack gap="8px">
                    <Skeleton sx={{ width: "100%", height: "52px" }} />
                    <Skeleton sx={{ width: "189px", height: "39px" }} />
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

export default LifeFocus;
