import { styled } from "@mui/material/styles";

export const UlStyled = styled("ul")({
  padding: "0px",
  margin: "0px",
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  li: {
    marginLeft: "20px",
    lineHeight: "10px",
  },
});
