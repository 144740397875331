import { CountryFlag } from "@type/country";
import flagAfghanistan from "@asset/flags/Afghanistan.svg";
import flagAlbania from "@asset/flags/Albania.svg";
import flagAlgeria from "@asset/flags/Algeria.svg";
import flagAndorra from "@asset/flags/Andorra.svg";
import flagAngola from "@asset/flags/Angola.svg";
import flagArgentina from "@asset/flags/Argentina.svg";
import flagArmenia from "@asset/flags/Armenia.svg";
import flagAustralia from "@asset/flags/Australia.svg";
import flagAustria from "@asset/flags/Austria.svg";
import flagAzerbaijan from "@asset/flags/Azerbaijan.svg";
import flagBahamas from "@asset/flags/Bahamas.svg";
import flagBahrain from "@asset/flags/Bahrain.svg";
import flagBangladesh from "@asset/flags/Bangladesh.svg";
import flagBarbados from "@asset/flags/Barbados.svg";
import flagBelarus from "@asset/flags/Belarus.svg";
import flagBelgium from "@asset/flags/Belgium.svg";
import flagBelize from "@asset/flags/Belize.svg";
import flagBenin from "@asset/flags/Benin.svg";
import flagBhutan from "@asset/flags/Bhutan.svg";
import flagBolivia from "@asset/flags/Bolivia.svg";
import flagBotswana from "@asset/flags/Botswana.svg";
import flagBrazil from "@asset/flags/Brazil.svg";
import flagBrunei from "@asset/flags/Brunei.svg";
import flagBulgaria from "@asset/flags/Bulgaria.svg";
import flagBurkina from "@asset/flags/Burkina.svg";
import flagBurundi from "@asset/flags/Burundi.svg";
import flagCambodia from "@asset/flags/Cambodia.svg";
import flagCameroon from "@asset/flags/Cameroon.svg";
import flagCanada from "@asset/flags/Canada.svg";
import flagCapeVerde from "@asset/flags/Cape-Verde.svg";
import flagChad from "@asset/flags/Chad.svg";
import flagChile from "@asset/flags/Chile.svg";
import flagChina from "@asset/flags/China.svg";
import flagColombia from "@asset/flags/Colombia.svg";
import flagComoros from "@asset/flags/Comoros.svg";
import flagCostaRica from "@asset/flags/Costa-Rica.svg";
import flagCroatia from "@asset/flags/Croatia.svg";
import flagCuba from "@asset/flags/Cuba.svg";
import flagCyprus from "@asset/flags/Cyprus.svg";
import flagCzechRepublic from "@asset/flags/Czech-Republic.svg";
import flagDenmark from "@asset/flags/Denmark.svg";
import flagDjibouti from "@asset/flags/Djibouti.svg";
import flagDominica from "@asset/flags/Dominica.svg";
import flagDominicanRepublic from "@asset/flags/Dominican-Republic.svg";
import flagEcuador from "@asset/flags/Ecuador.svg";
import flagEgypt from "@asset/flags/Egypt.svg";
import flagElSalvador from "@asset/flags/El-Salvador.svg";
import flagEquatorialGuinea from "@asset/flags/Equatorial-Guinea.svg";
import flagEritrea from "@asset/flags/Eritrea.svg";
import flagEstonia from "@asset/flags/Estonia.svg";
import flagEthiopia from "@asset/flags/Ethiopia.svg";
import flagFiji from "@asset/flags/Fiji.svg";
import flagFinland from "@asset/flags/Finland.svg";
import flagFrance from "@asset/flags/France.svg";
import flagGabon from "@asset/flags/Gabon.svg";
import flagGambia from "@asset/flags/Gambia.svg";
import flagGeorgia from "@asset/flags/Georgia.svg";
import flagGermany from "@asset/flags/Germany.svg";
import flagGhana from "@asset/flags/Ghana.svg";
import flagGreece from "@asset/flags/Greece.svg";
import flagGrenada from "@asset/flags/Grenada.svg";
import flagGuatemala from "@asset/flags/Guatemala.svg";
import flagGuinea from "@asset/flags/Guinea.svg";
import flagGuineaBissau from "@asset/flags/Guinea-Bissau.svg";
import flagGuyana from "@asset/flags/Guyana.svg";
import flagHaiti from "@asset/flags/Haiti.svg";
import flagHonduras from "@asset/flags/Honduras.svg";
import flagHungary from "@asset/flags/Hungary.svg";
import flagIceland from "@asset/flags/Iceland.svg";
import flagIndia from "@asset/flags/India.svg";
import flagIndonesia from "@asset/flags/Indonesia.svg";
import flagIran from "@asset/flags/Iran.svg";
import flagIraq from "@asset/flags/Iraq.svg";
import flagIreland from "@asset/flags/Ireland.svg";
import flagIsrael from "@asset/flags/Israel.svg";
import flagItaly from "@asset/flags/Italy.svg";
import flagJamaica from "@asset/flags/Jamaica.svg";
import flagJapan from "@asset/flags/Japan.svg";
import flagJordan from "@asset/flags/Jordan.svg";
import flagKazakhstan from "@asset/flags/Kazakhstan.svg";
import flagKenya from "@asset/flags/Kenya.svg";
import flagKiribati from "@asset/flags/Kiribati.svg";
import flagKuwait from "@asset/flags/Kuwait.svg";
import flagKyrgyzstan from "@asset/flags/Kyrgyzstan.svg";
import flagLaos from "@asset/flags/Laos.svg";
import flagLatvia from "@asset/flags/Latvia.svg";
import flagLebanon from "@asset/flags/Lebanon.svg";
import flagLesotho from "@asset/flags/Lesotho.svg";
import flagLiberia from "@asset/flags/Liberia.svg";
import flagLibya from "@asset/flags/Libya.svg";
import flagLiechtenstein from "@asset/flags/Liechtenstein.svg";
import flagLithuania from "@asset/flags/Lithuania.svg";
import flagMadagascar from "@asset/flags/Madagascar.svg";
import flagMalawi from "@asset/flags/Malawi.svg";
import flagMalaysia from "@asset/flags/Malaysia.svg";
import flagMaldives from "@asset/flags/Maldives.svg";
import flagMali from "@asset/flags/Mali.svg";
import flagMalta from "@asset/flags/Malta.svg";
import flagMarshallIslands from "@asset/flags/Marshall-Islands.svg";
import flagMauritania from "@asset/flags/Mauritania.svg";
import flagMauritius from "@asset/flags/Mauritius.svg";
import flagMexico from "@asset/flags/Mexico.svg";
import flagMicronesia from "@asset/flags/Micronesia.svg";
import flagMoldova from "@asset/flags/Moldova.svg";
import flagMonaco from "@asset/flags/Monaco.svg";
import flagMongolia from "@asset/flags/Mongolia.svg";
import flagMontenegro from "@asset/flags/Montenegro.svg";
import flagMorocco from "@asset/flags/Morocco.svg";
import flagMozambique from "@asset/flags/Mozambique.svg";
import flagMyanmar from "@asset/flags/Myanmar.svg";
import flagNamibia from "@asset/flags/Namibia.svg";
import flagNauru from "@asset/flags/Nauru.svg";
import flagNepal from "@asset/flags/Nepal.svg";
import flagNetherlands from "@asset/flags/Netherlands.svg";
import flagNewZealand from "@asset/flags/New-Zealand.svg";
import flagNicaragua from "@asset/flags/Nicaragua.svg";
import flagNiger from "@asset/flags/Niger.svg";
import flagNigeria from "@asset/flags/Nigeria.svg";
import flagNorthKorea from "@asset/flags/North-Korea.svg";
import flagMacedonia from "@asset/flags/Macedonia.svg";
import flagNorway from "@asset/flags/Norway.svg";
import flagOman from "@asset/flags/Oman.svg";
import flagPakistan from "@asset/flags/Pakistan.svg";
import flagPalau from "@asset/flags/Palau.svg";
import flagPanama from "@asset/flags/Panama.svg";
import flagPapuaNewGuinea from "@asset/flags/Papua-New-Guinea.svg";
import flagParaguay from "@asset/flags/Paraguay.svg";
import flagPeru from "@asset/flags/Peru.svg";
import flagPhilippines from "@asset/flags/Philippines.svg";
import flagPoland from "@asset/flags/Poland.svg";
import flagPortugal from "@asset/flags/Portugal.svg";
import flagQatar from "@asset/flags/Qatar.svg";
import flagRomania from "@asset/flags/Romania.svg";
import flagRussia from "@asset/flags/Russia.svg";
import flagRwanda from "@asset/flags/Rwanda.svg";
import flagSaintKittsandNevis from "@asset/flags/Saint-Kitts-and-Nevis.svg";
import flagSaintLucia from "@asset/flags/Saint-Lucia.svg";
import flagSaintVincentandtheGrenadines from "@asset/flags/Saint-Vincent-and-the-Grenadines.svg";
import flagSamoa from "@asset/flags/Samoa.svg";
import flagSanMarino from "@asset/flags/San-Marino.svg";
import flagSaoTomeandPrincipe from "@asset/flags/Sao-Tome-and-Principe.svg";
import flagSaudiArabia from "@asset/flags/Saudi-Arabia.svg";
import flagSenegal from "@asset/flags/Senegal.svg";
import flagSerbia from "@asset/flags/Serbia.svg";
import flagSeychelles from "@asset/flags/Seychelles.svg";
import flagSierraLeone from "@asset/flags/Sierra-Leone.svg";
import flagSingapore from "@asset/flags/Singapore.svg";
import flagSlovakia from "@asset/flags/Slovakia.svg";
import flagSolomonIslands from "@asset/flags/Solomon-Islands.svg";
import flagSomalia from "@asset/flags/Somalia.svg";
import flagSouthAfrica from "@asset/flags/South-Africa.svg";
import flagSouthKorea from "@asset/flags/South-Korea.svg";
import flagSouthSudan from "@asset/flags/South-Sudan.svg";
import flagSpain from "@asset/flags/Spain.svg";
import flagSriLanka from "@asset/flags/Sri-Lanka.svg";
import flagSudan from "@asset/flags/Sudan.svg";
import flagSuriname from "@asset/flags/Suriname.svg";
import flagSwaziland from "@asset/flags/Swaziland.svg";
import flagSweden from "@asset/flags/Sweden.svg";
import flagSwitzerland from "@asset/flags/Switzerland.svg";
import flagSyria from "@asset/flags/Syria.svg";
import flagTaiwan from "@asset/flags/Taiwan.svg";
import flagTajikistan from "@asset/flags/Tajikistan.svg";
import flagTanzania from "@asset/flags/Tanzania.svg";
import flagThailand from "@asset/flags/Thailand.svg";
import flagTogo from "@asset/flags/Togo.svg";
import flagTonga from "@asset/flags/Tonga.svg";
import flagTrinidadandTobago from "@asset/flags/Trinidad-and-Tobago.svg";
import flagTunisia from "@asset/flags/Tunisia.svg";
import flagTurkey from "@asset/flags/Turkey.svg";
import flagTurkmenistan from "@asset/flags/Turkmenistan.svg";
import flagTuvalu from "@asset/flags/Tuvalu.svg";
import flagUganda from "@asset/flags/Uganda.svg";
import flagUkraine from "@asset/flags/Ukraine.svg";
import flagUnitedArabEmirates from "@asset/flags/United-Arab-Emirates.svg";
import flagUnitedKingdom from "@asset/flags/United-Kingdom.svg";
import flagUnitedStates from "@asset/flags/United-States.svg";
import flagUruguay from "@asset/flags/Uruguay.svg";
import flagUzbekistan from "@asset/flags/Uzbekistan.svg";
import flagVanuatu from "@asset/flags/Vanuatu.svg";
import flagVaticanCity from "@asset/flags/Vatican-City.svg";
import flagVenezuela from "@asset/flags/Venezuela.svg";
import flagVietnam from "@asset/flags/Vietnam.svg";
import flagYemen from "@asset/flags/Yemen.svg";
import flagZambia from "@asset/flags/Zambia.svg";
import flagZimbabwe from "@asset/flags/Zimbabwe.svg";

export const listFlags: CountryFlag[] = [
  {
    code: "AF",
    label: "Afghanistan",
    dialCode: "+93",
    flag: flagAfghanistan,
  },
  {
    code: "AL",
    label: "Albania",
    dialCode: "+355",
    flag: flagAlbania,
  },
  {
    code: "DZ",
    label: "Algeria",
    dialCode: "+213",
    flag: flagAlgeria,
  },
  {
    code: "AD",
    label: "Andorra",
    dialCode: "+376",
    flag: flagAndorra,
  },
  {
    code: "AO",
    label: "Angola",
    dialCode: "+244",
    flag: flagAngola,
  },
  {
    code: "AR",
    label: "Argentina",
    dialCode: "+54",
    flag: flagArgentina,
  },
  {
    code: "AM",
    label: "Armenia",
    dialCode: "+374",
    flag: flagArmenia,
  },
  {
    code: "AU",
    label: "Australia",
    dialCode: "+61",
    flag: flagAustralia,
  },
  {
    code: "AT",
    label: "Austria",
    dialCode: "+43",
    flag: flagAustria,
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    dialCode: "+994",
    flag: flagAzerbaijan,
  },
  {
    code: "BS",
    label: "Bahamas",
    dialCode: "+1-242",
    flag: flagBahamas,
  },
  {
    code: "BH",
    label: "Bahrain",
    dialCode: "+973",
    flag: flagBahrain,
  },
  {
    code: "BD",
    label: "Bangladesh",
    dialCode: "+880",
    flag: flagBangladesh,
  },
  {
    code: "BB",
    label: "Barbados",
    dialCode: "+1-246",
    flag: flagBarbados,
  },
  {
    code: "BY",
    label: "Belarus",
    dialCode: "+375",
    flag: flagBelarus,
  },
  {
    code: "BE",
    label: "Belgium",
    dialCode: "+32",
    flag: flagBelgium,
  },
  {
    code: "BZ",
    label: "Belize",
    dialCode: "+501",
    flag: flagBelize,
  },
  {
    code: "BJ",
    label: "Benin",
    dialCode: "+229",
    flag: flagBenin,
  },
  {
    code: "BT",
    label: "Bhutan",
    dialCode: "+975",
    flag: flagBhutan,
  },
  {
    code: "BO",
    label: "Bolivia",
    dialCode: "+591",
    flag: flagBolivia,
  },
  {
    code: "BW",
    label: "Botswana",
    dialCode: "+267",
    flag: flagBotswana,
  },
  {
    code: "BR",
    label: "Brazil",
    dialCode: "+55",
    flag: flagBrazil,
  },
  {
    code: "BN",
    label: "Brunei",
    dialCode: "+673",
    flag: flagBrunei,
  },
  {
    code: "BG",
    label: "Bulgaria",
    dialCode: "+359",
    flag: flagBulgaria,
  },
  {
    code: "BF",
    label: "Burkina Faso",
    dialCode: "+226",
    flag: flagBurkina,
  },
  {
    code: "BI",
    label: "Burundi",
    dialCode: "+257",
    flag: flagBurundi,
  },
  {
    code: "KH",
    label: "Cambodia",
    dialCode: "+855",
    flag: flagCambodia,
  },
  {
    code: "CM",
    label: "Cameroon",
    dialCode: "+237",
    flag: flagCameroon,
  },
  {
    code: "CA",
    label: "Canada",
    dialCode: "+1",
    flag: flagCanada,
  },
  {
    code: "CV",
    label: "Cape Verde",
    dialCode: "+238",
    flag: flagCapeVerde,
  },
  {
    code: "TD",
    label: "Chad",
    dialCode: "+235",
    flag: flagChad,
  },
  {
    code: "CL",
    label: "Chile",
    dialCode: "+56",
    flag: flagChile,
  },
  {
    code: "CN",
    label: "China",
    dialCode: "+86",
    flag: flagChina,
  },
  {
    code: "CO",
    label: "Colombia",
    dialCode: "+57",
    flag: flagColombia,
  },
  {
    code: "KM",
    label: "Comoros",
    dialCode: "+269",
    flag: flagComoros,
  },
  {
    code: "CR",
    label: "Costa Rica",
    dialCode: "+506",
    flag: flagCostaRica,
  },
  {
    code: "HR",
    label: "Croatia",
    dialCode: "+385",
    flag: flagCroatia,
  },
  {
    code: "CU",
    label: "Cuba",
    dialCode: "+53",
    flag: flagCuba,
  },
  {
    code: "CY",
    label: "Cyprus",
    dialCode: "+357",
    flag: flagCyprus,
  },
  {
    code: "CZ",
    label: "Czech Republic",
    dialCode: "+420",
    flag: flagCzechRepublic,
  },
  {
    code: "DK",
    label: "Denmark",
    dialCode: "+45",
    flag: flagDenmark,
  },
  {
    code: "DJ",
    label: "Djibouti",
    dialCode: "+253",
    flag: flagDjibouti,
  },
  {
    code: "DM",
    label: "Dominica",
    dialCode: "+1-767",
    flag: flagDominica,
  },
  {
    code: "DO",
    label: "Dominican Republic",
    dialCode: "+1-809",
    flag: flagDominicanRepublic,
  },
  {
    code: "EC",
    label: "Ecuador",
    dialCode: "+593",
    flag: flagEcuador,
  },
  {
    code: "EG",
    label: "Egypt",
    dialCode: "+20",
    flag: flagEgypt,
  },
  {
    code: "SV",
    label: "El Salvador",
    dialCode: "+503",
    flag: flagElSalvador,
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    dialCode: "+240",
    flag: flagEquatorialGuinea,
  },
  {
    code: "ER",
    label: "Eritrea",
    dialCode: "+291",
    flag: flagEritrea,
  },
  {
    code: "EE",
    label: "Estonia",
    dialCode: "+372",
    flag: flagEstonia,
  },
  {
    code: "ET",
    label: "Ethiopia",
    dialCode: "+251",
    flag: flagEthiopia,
  },
  {
    code: "FJ",
    label: "Fiji",
    dialCode: "+679",
    flag: flagFiji,
  },
  {
    code: "FI",
    label: "Finland",
    dialCode: "+358",
    flag: flagFinland,
  },
  {
    code: "FR",
    label: "France",
    dialCode: "+33",
    flag: flagFrance,
  },
  {
    code: "GA",
    label: "Gabon",
    dialCode: "+241",
    flag: flagGabon,
  },
  {
    code: "GM",
    label: "Gambia",
    dialCode: "+220",
    flag: flagGambia,
  },
  {
    code: "GE",
    label: "Georgia",
    dialCode: "+995",
    flag: flagGeorgia,
  },
  {
    code: "DE",
    label: "Germany",
    dialCode: "+49",
    flag: flagGermany,
  },
  {
    code: "GH",
    label: "Ghana",
    dialCode: "+233",
    flag: flagGhana,
  },
  {
    code: "GR",
    label: "Greece",
    dialCode: "+30",
    flag: flagGreece,
  },
  {
    code: "GD",
    label: "Grenada",
    dialCode: "+1-473",
    flag: flagGrenada,
  },
  {
    code: "GT",
    label: "Guatemala",
    dialCode: "+502",
    flag: flagGuatemala,
  },
  {
    code: "GN",
    label: "Guinea",
    dialCode: "+224",
    flag: flagGuinea,
  },
  {
    code: "GW",
    label: "Guinea-Bissau",
    dialCode: "+245",
    flag: flagGuineaBissau,
  },
  {
    code: "GY",
    label: "Guyana",
    dialCode: "+592",
    flag: flagGuyana,
  },
  {
    code: "HT",
    label: "Haiti",
    dialCode: "+509",
    flag: flagHaiti,
  },
  {
    code: "HN",
    label: "Honduras",
    dialCode: "+504",
    flag: flagHonduras,
  },
  {
    code: "HU",
    label: "Hungary",
    dialCode: "+36",
    flag: flagHungary,
  },
  {
    code: "IS",
    label: "Iceland",
    dialCode: "+354",
    flag: flagIceland,
  },
  {
    code: "IN",
    label: "India",
    dialCode: "+91",
    flag: flagIndia,
  },
  {
    code: "ID",
    label: "Indonesia",
    dialCode: "+62",
    flag: flagIndonesia,
  },
  {
    code: "IR",
    label: "Iran",
    dialCode: "+98",
    flag: flagIran,
  },
  {
    code: "IQ",
    label: "Iraq",
    dialCode: "+964",
    flag: flagIraq,
  },
  {
    code: "IE",
    label: "Ireland",
    dialCode: "+353",
    flag: flagIreland,
  },
  {
    code: "IL",
    label: "Israel",
    dialCode: "+972",
    flag: flagIsrael,
  },
  {
    code: "IT",
    label: "Italy",
    dialCode: "+39",
    flag: flagItaly,
  },
  {
    code: "JM",
    label: "Jamaica",
    dialCode: "+1-876",
    flag: flagJamaica,
  },
  {
    code: "JP",
    label: "Japan",
    dialCode: "+81",
    flag: flagJapan,
  },
  {
    code: "JO",
    label: "Jordan",
    dialCode: "+962",
    flag: flagJordan,
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    dialCode: "+7",
    flag: flagKazakhstan,
  },
  {
    code: "KE",
    label: "Kenya",
    dialCode: "+254",
    flag: flagKenya,
  },
  {
    code: "KI",
    label: "Kiribati",
    dialCode: "+686",
    flag: flagKiribati,
  },
  {
    code: "KW",
    label: "Kuwait",
    dialCode: "+965",
    flag: flagKuwait,
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    dialCode: "+996",
    flag: flagKyrgyzstan,
  },
  {
    code: "LA",
    label: "Laos",
    dialCode: "+856",
    flag: flagLaos,
  },
  {
    code: "LV",
    label: "Latvia",
    dialCode: "+371",
    flag: flagLatvia,
  },
  {
    code: "LB",
    label: "Lebanon",
    dialCode: "+961",
    flag: flagLebanon,
  },
  {
    code: "LS",
    label: "Lesotho",
    dialCode: "+266",
    flag: flagLesotho,
  },
  {
    code: "LR",
    label: "Liberia",
    dialCode: "+231",
    flag: flagLiberia,
  },
  {
    code: "LY",
    label: "Libya",
    dialCode: "+218",
    flag: flagLibya,
  },
  {
    code: "LI",
    label: "Liechtenstein",
    dialCode: "+423",
    flag: flagLiechtenstein,
  },
  {
    code: "LT",
    label: "Lithuania",
    dialCode: "+370",
    flag: flagLithuania,
  },
  {
    code: "MG",
    label: "Madagascar",
    dialCode: "+261",
    flag: flagMadagascar,
  },
  {
    code: "MW",
    label: "Malawi",
    dialCode: "+265",
    flag: flagMalawi,
  },
  {
    code: "MY",
    label: "Malaysia",
    dialCode: "+60",
    flag: flagMalaysia,
  },
  {
    code: "MV",
    label: "Maldives",
    dialCode: "+960",
    flag: flagMaldives,
  },
  {
    code: "ML",
    label: "Mali",
    dialCode: "+223",
    flag: flagMali,
  },
  {
    code: "MT",
    label: "Malta",
    dialCode: "+356",
    flag: flagMalta,
  },
  {
    code: "MH",
    label: "Marshall Islands",
    dialCode: "+692",
    flag: flagMarshallIslands,
  },
  {
    code: "MR",
    label: "Mauritania",
    dialCode: "+222",
    flag: flagMauritania,
  },
  {
    code: "MU",
    label: "Mauritius",
    dialCode: "+230",
    flag: flagMauritius,
  },
  {
    code: "MX",
    label: "Mexico",
    dialCode: "+52",
    flag: flagMexico,
  },
  {
    code: "FM",
    label: "Micronesia",
    dialCode: "+691",
    flag: flagMicronesia,
  },
  {
    code: "MD",
    label: "Moldova",
    dialCode: "+373",
    flag: flagMoldova,
  },
  {
    code: "MC",
    label: "Monaco",
    dialCode: "+377",
    flag: flagMonaco,
  },
  {
    code: "MN",
    label: "Mongolia",
    dialCode: "+976",
    flag: flagMongolia,
  },
  {
    code: "ME",
    label: "Montenegro",
    dialCode: "+382",
    flag: flagMontenegro,
  },
  {
    code: "MA",
    label: "Morocco",
    dialCode: "+212",
    flag: flagMorocco,
  },
  {
    code: "MZ",
    label: "Mozambique",
    dialCode: "+258",
    flag: flagMozambique,
  },
  {
    code: "MM",
    label: "Myanmar",
    dialCode: "+95",
    flag: flagMyanmar,
  },
  {
    code: "NA",
    label: "Namibia",
    dialCode: "+264",
    flag: flagNamibia,
  },
  {
    code: "NR",
    label: "Nauru",
    dialCode: "+674",
    flag: flagNauru,
  },
  {
    code: "NP",
    label: "Nepal",
    dialCode: "+977",
    flag: flagNepal,
  },
  {
    code: "NL",
    label: "Netherlands",
    dialCode: "+31",
    flag: flagNetherlands,
  },
  {
    code: "NZ",
    label: "New Zealand",
    dialCode: "+64",
    flag: flagNewZealand,
  },
  {
    code: "NI",
    label: "Nicaragua",
    dialCode: "+505",
    flag: flagNicaragua,
  },
  {
    code: "NE",
    label: "Niger",
    dialCode: "+227",
    flag: flagNiger,
  },
  {
    code: "NG",
    label: "Nigeria",
    dialCode: "+234",
    flag: flagNigeria,
  },
  {
    code: "KP",
    label: "North Korea",
    dialCode: "+850",
    flag: flagNorthKorea,
  },
  {
    code: "MK",
    label: "North Macedonia",
    dialCode: "+389",
    flag: flagMacedonia,
  },
  {
    code: "NO",
    label: "Norway",
    dialCode: "+47",
    flag: flagNorway,
  },
  {
    code: "OM",
    label: "Oman",
    dialCode: "+968",
    flag: flagOman,
  },
  {
    code: "PK",
    label: "Pakistan",
    dialCode: "+92",
    flag: flagPakistan,
  },
  {
    code: "PW",
    label: "Palau",
    dialCode: "+680",
    flag: flagPalau,
  },
  {
    code: "PA",
    label: "Panama",
    dialCode: "+507",
    flag: flagPanama,
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    dialCode: "+675",
    flag: flagPapuaNewGuinea,
  },
  {
    code: "PY",
    label: "Paraguay",
    dialCode: "+595",
    flag: flagParaguay,
  },
  {
    code: "PE",
    label: "Peru",
    dialCode: "+51",
    flag: flagPeru,
  },
  {
    code: "PH",
    label: "Philippines",
    dialCode: "+63",
    flag: flagPhilippines,
  },
  {
    code: "PL",
    label: "Poland",
    dialCode: "+48",
    flag: flagPoland,
  },
  {
    code: "PT",
    label: "Portugal",
    dialCode: "+351",
    flag: flagPortugal,
  },
  {
    code: "QA",
    label: "Qatar",
    dialCode: "+974",
    flag: flagQatar,
  },
  {
    code: "RO",
    label: "Romania",
    dialCode: "+40",
    flag: flagRomania,
  },
  {
    code: "RU",
    label: "Russia",
    dialCode: "+7",
    flag: flagRussia,
  },
  {
    code: "RW",
    label: "Rwanda",
    dialCode: "+250",
    flag: flagRwanda,
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    dialCode: "+1-869",
    flag: flagSaintKittsandNevis,
  },
  {
    code: "LC",
    label: "Saint Lucia",
    dialCode: "+1-758",
    flag: flagSaintLucia,
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    dialCode: "+1-784",
    flag: flagSaintVincentandtheGrenadines,
  },
  {
    code: "WS",
    label: "Samoa",
    dialCode: "+685",
    flag: flagSamoa,
  },
  {
    code: "SM",
    label: "San Marino",
    dialCode: "+378",
    flag: flagSanMarino,
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    dialCode: "+239",
    flag: flagSaoTomeandPrincipe,
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    dialCode: "+966",
    flag: flagSaudiArabia,
  },
  {
    code: "SN",
    label: "Senegal",
    dialCode: "+221",
    flag: flagSenegal,
  },
  {
    code: "RS",
    label: "Serbia",
    dialCode: "+381",
    flag: flagSerbia,
  },
  {
    code: "SC",
    label: "Seychelles",
    dialCode: "+248",
    flag: flagSeychelles,
  },
  {
    code: "SL",
    label: "Sierra Leone",
    dialCode: "+232",
    flag: flagSierraLeone,
  },
  {
    code: "SG",
    label: "Singapore",
    dialCode: "+65",
    flag: flagSingapore,
  },
  {
    code: "SK",
    label: "Slovakia",
    dialCode: "+421",
    flag: flagSlovakia,
  },
  {
    code: "SB",
    label: "Solomon Islands",
    dialCode: "+677",
    flag: flagSolomonIslands,
  },
  {
    code: "SO",
    label: "Somalia",
    dialCode: "+252",
    flag: flagSomalia,
  },
  {
    code: "ZA",
    label: "South Africa",
    dialCode: "+27",
    flag: flagSouthAfrica,
  },
  {
    code: "KR",
    label: "South Korea",
    dialCode: "+82",
    flag: flagSouthKorea,
  },
  {
    code: "SS",
    label: "South Sudan",
    dialCode: "+211",
    flag: flagSouthSudan,
  },
  {
    code: "ES",
    label: "Spain",
    dialCode: "+34",
    flag: flagSpain,
  },
  {
    code: "LK",
    label: "Sri Lanka",
    dialCode: "+94",
    flag: flagSriLanka,
  },
  {
    code: "SD",
    label: "Sudan",
    dialCode: "+249",
    flag: flagSudan,
  },
  {
    code: "SR",
    label: "Suriname",
    dialCode: "+597",
    flag: flagSuriname,
  },
  {
    code: "SZ",
    label: "Swaziland",
    dialCode: "+268",
    flag: flagSwaziland,
  },
  {
    code: "SE",
    label: "Sweden",
    dialCode: "+46",
    flag: flagSweden,
  },
  {
    code: "CH",
    label: "Switzerland",
    dialCode: "+41",
    flag: flagSwitzerland,
  },
  {
    code: "SY",
    label: "Syria",
    dialCode: "+963",
    flag: flagSyria,
  },
  {
    code: "TW",
    label: "Taiwan",
    dialCode: "+886",
    flag: flagTaiwan,
  },
  {
    code: "TJ",
    label: "Tajikistan",
    dialCode: "+992",
    flag: flagTajikistan,
  },
  {
    code: "TZ",
    label: "Tanzania",
    dialCode: "+255",
    flag: flagTanzania,
  },
  {
    code: "TH",
    label: "Thailand",
    dialCode: "+66",
    flag: flagThailand,
  },
  {
    code: "TG",
    label: "Togo",
    dialCode: "+228",
    flag: flagTogo,
  },
  {
    code: "TO",
    label: "Tonga",
    dialCode: "+676",
    flag: flagTonga,
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    dialCode: "+1-868",
    flag: flagTrinidadandTobago,
  },
  {
    code: "TN",
    label: "Tunisia",
    dialCode: "+216",
    flag: flagTunisia,
  },
  {
    code: "TR",
    label: "Turkey",
    dialCode: "+90",
    flag: flagTurkey,
  },
  {
    code: "TM",
    label: "Turkmenistan",
    dialCode: "+993",
    flag: flagTurkmenistan,
  },
  {
    code: "TV",
    label: "Tuvalu",
    dialCode: "+688",
    flag: flagTuvalu,
  },
  {
    code: "UG",
    label: "Uganda",
    dialCode: "+256",
    flag: flagUganda,
  },
  {
    code: "UA",
    label: "Ukraine",
    dialCode: "+380",
    flag: flagUkraine,
  },
  {
    code: "AE",
    label: "United Arab Emirates",
    dialCode: "+971",
    flag: flagUnitedArabEmirates,
  },
  {
    code: "GB",
    label: "United Kingdom",
    dialCode: "+44",
    flag: flagUnitedKingdom,
  },
  {
    code: "US",
    label: "United States",
    dialCode: "+1",
    flag: flagUnitedStates,
  },
  {
    code: "UY",
    label: "Uruguay",
    dialCode: "+598",
    flag: flagUruguay,
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    dialCode: "+998",
    flag: flagUzbekistan,
  },
  {
    code: "VU",
    label: "Vanuatu",
    dialCode: "+678",
    flag: flagVanuatu,
  },
  {
    code: "VA",
    label: "Vatican City",
    dialCode: "+379",
    flag: flagVaticanCity,
  },
  {
    code: "VE",
    label: "Venezuela",
    dialCode: "+58",
    flag: flagVenezuela,
  },
  {
    code: "VN",
    label: "Vietnam",
    dialCode: "+84",
    flag: flagVietnam,
  },
  {
    code: "YE",
    label: "Yemen",
    dialCode: "+967",
    flag: flagYemen,
  },
  {
    code: "ZM",
    label: "Zambia",
    dialCode: "+260",
    flag: flagZambia,
  },
  {
    code: "ZW",
    label: "Zimbabwe",
    dialCode: "+263",
    flag: flagZimbabwe,
  },
];
