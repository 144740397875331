/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { getCoachListSchedule } from "@service/coach";
import { CoachScheduleItem, ScheduleItem } from "@type/coach";
import { convertToLocalTime, getLocalGMT, parseFormat } from "@util/date";
import { GrowthGuideNote } from "@type/user";
import { useUserProfileContext } from "context/user-profile-context";
import { catchErrorLog } from "@util/sentry";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import DialogBookingGrowthGuide from "components/dialog/dialog-booking-growth-guide";
import DialogBookingSuccess from "components/dialog/dialog-booking-success";
import "react-day-picker/style.css";
import TwoWeekCalendar from "@component/two-week-calendar";

interface Props {
  isWelcomeMessage?: boolean;
  notes?: GrowthGuideNote[];
  onCloseSidebar: () => void;
  onReloadBookingInfo: () => void;
}
function CoachSchedule(props: Props) {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [coachSchedules, setCoachSchedules] = useState<CoachScheduleItem[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<
    CoachScheduleItem | undefined
  >(undefined);
  const [selectedScheduleItem, setSelectedScheduleItem] = useState<
    ScheduleItem | undefined
  >(undefined);
  const [isOpenDialogBooking, setIsOpenDialogBooking] =
    useState<boolean>(false);
  const [isOpenBookingSuccess, setIsOpenBookingSuccess] =
    useState<boolean>(false);

  const reloadGetCoachList = () => {
    if (!isLoadingUserProfile) {
      getCoachListSchedule(userInfo?.booking.assign_growth_guide_id || "")
        .then((resp) => {
          setCoachSchedules(resp.data.data);
          if (resp.data.data.length > 0) {
            setSelectedSchedule(resp.data.data[0]);
          }
        })
        .catch((err) => {
          catchErrorLog(err?.response?.data.message);
        });
    }
  };

  useEffect(() => {
    reloadGetCoachList();
  }, []);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          marginTop: {
            md: "40px",
            xs: "0px",
          },
        }}
      >
        <Stack
          direction="row"
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: {
              md: "24px",
              xs: "0px",
            },
            borderBottom: {
              md: "none",
              xs: "1px solid #00000014",
            },
            padding: {
              md: "0px",
              xs: "8px 16px",
            },
          }}
        >
          <Typography variant="h5" sx={{ color: "#000000DE" }}>
            Book your session
          </Typography>
          <IconButton onClick={props.onCloseSidebar}>
            <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
          </IconButton>
        </Stack>
        <Box
          sx={{
            padding: "16px",
            background: "#FFFCFA",
            borderRadius: "8px",
            border: "1px solid #00000029",
            margin: {
              md: "0px",
              xs: "12px 16px",
            },
          }}
        >
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#000000DE",
              }}
            >
              Select date and time ({getLocalGMT()})
            </Typography>
          </Box>
          <Box>
            <TwoWeekCalendar
              onSelected={(selectedDate: Date) => {
                const date = coachSchedules.find(
                  // @ts-ignore
                  (item) =>
                    item.date ===
                    parseFormat(selectedDate.toDateString(), "YYYY-MM-DD")
                );
                setSelectedSchedule(date);
              }}
            />
          </Box>
          <Box sx={{ margin: "16px 0px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#000000DE",
                userSelect: "none",
              }}
            >
              Latest available date/time:
            </Typography>
          </Box>
          <Box>
            {(selectedSchedule?.items || []).length > 0 ? (
              <Grid container spacing="8px">
                {selectedSchedule?.items.map((item, index) => (
                  <Grid key={index}>
                    <Box
                      sx={{
                        userSelect: "none",
                        border: "1px solid #0000004D",
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        padding: "8px",
                        color: "#000000DE",
                        borderRadius: "4px",
                        cursor: "pointer",
                        background:
                          item.schedule_id === selectedScheduleItem?.schedule_id
                            ? "#D2DFE1"
                            : "#FFFFFF",
                        ":hover": {
                          background: "#00000014",
                        },
                      }}
                      onClick={() => setSelectedScheduleItem(item)}
                    >
                      {`${convertToLocalTime(item.start_time)} - ${convertToLocalTime(item.end_time)}`}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box>
                <Typography>No schedule</Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: "40px" }}>
            <Button
              fullWidth
              variant="contained"
              disabled={!selectedScheduleItem}
              onClick={() => setIsOpenDialogBooking(true)}
            >
              Book session
            </Button>
          </Box>
        </Box>
      </Stack>
      <DialogBookingGrowthGuide
        isOpen={isOpenDialogBooking}
        date={selectedSchedule?.date || ""}
        selectedScheduleItem={selectedScheduleItem}
        notes={props.notes}
        onClose={(_, reason: string) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
          setIsOpenDialogBooking(false);
        }}
        onCompleted={() => {
          setIsOpenDialogBooking(false);
          setIsOpenBookingSuccess(true);
        }}
      />

      <DialogBookingSuccess
        isWelcomeMessage={props.isWelcomeMessage}
        isOpen={isOpenBookingSuccess}
        date={selectedSchedule?.date || ""}
        selectedScheduleItem={selectedScheduleItem}
        onClose={() => {
          setIsOpenBookingSuccess(false);
          setSelectedScheduleItem(undefined);
          reloadGetCoachList();
          props.onCloseSidebar();
          props.onReloadBookingInfo();
        }}
      />
    </>
  );
}

export default CoachSchedule;
