import { getCookie } from "@util/cookie";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_BASE_URL;
export const getUserProfile = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/profile`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserLifeStatus = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/life-status`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserSummary = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/summary`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserNotes = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/notes`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserSubscription = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/subscription`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const cancelUserSubscriptionPlan = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/subscription/cancel`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reactivateUserSubscriptionPlan = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/subscription/reactivate`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserCustomerPortal = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/customer/portal`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserWelcomeMessage = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/user/welcome-message`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
