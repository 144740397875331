/* eslint-disable prettier/prettier */
import { convertToFormattedText } from "@util/convert";
import { useEffect, useState } from "react";
import { UserNoteItem } from "@type/user";
import { parseFormat } from "@util/date";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { BookingInfo } from "@type/booking";
import { getBookingGrowthGuideInfo } from "@service/booking";
import { catchErrorLog } from "@util/sentry";
import { useUserProfileContext } from "context/user-profile-context";
import SessionNoteBox from "@component/session-note-box";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";

interface Props {
  isOpen: boolean;
  currentIndex: number;
  sessionNotes: UserNoteItem[];
  onClose: () => void;
  onBooking: () => void;
}

function DialogSessionNoteDetail(props: Props) {
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number>(0);
  const [isLoadingBookingInfo, setLoadingBookingInfo] =
    useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );

  useEffect(() => {
    if (props.isOpen) {
      setLoadingBookingInfo(true);
      getBookingGrowthGuideInfo()
        .then((resp) => {
          setLoadingBookingInfo(false);
          setBookingInfo(resp.data.data);
        })
        .catch((err) => {
          setLoadingBookingInfo(false);
          catchErrorLog(err?.response?.data.message);
        });
    }
  }, [props.isOpen]);

  useEffect(() => {
    setCurrentNoteIndex(props.currentIndex);
  }, [props.currentIndex]);

  if (!props.isOpen || isLoadingUserProfile || isLoadingBookingInfo) return;
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogContent sx={{ padding: "24px 32px", position: "relative" }}>
        <Box sx={{ minHeight: "600px" }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            gap="8px"
            sx={{ marginBottom: "16px" }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                lineHeight: "30px",
                color: "#000000DE",
              }}
            >
              {parseFormat(
                props.sessionNotes[currentNoteIndex].sessionStartedAt,
                "ddd, DD MMM YYYY"
              )}
            </Typography>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-start"
              gap="8px"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "12px",
                  color: "#000000DE",
                }}
              >
                {parseFormat(
                  props.sessionNotes[currentNoteIndex].sessionStartedAt,
                  "HH:mm A"
                )}
              </Typography>
              <Box>•</Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "12px",
                  color: "#000000DE",
                }}
              >
                {`Session Notes by ${props.sessionNotes[currentNoteIndex].coachName}`}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{ marginBottom: "16px" }}>
            <Stack
              direction="row"
              gap="8px"
              sx={{ width: "100%", marginBottom: "24px" }}
            >
              <SessionNoteBox
                title="Overview"
                content={
                  // @ts-ignore
                  <Typography variant="body14" sx={{ fontWeight: "400" }}>
                    {convertToFormattedText(
                      props.sessionNotes[currentNoteIndex].ourcoachSummary
                        .overview
                    )}
                  </Typography>
                }
              />
              <SessionNoteBox
                title="Action Plans"
                content={
                  props.sessionNotes[currentNoteIndex].ourcoachSummary
                    .action_plan.length > 0 ? (
                    <ul style={{ margin: "0px", paddingLeft: "20px" }}>
                      {props.sessionNotes[
                        currentNoteIndex
                      ].ourcoachSummary.action_plan.map(
                        (action_plan, index) => (
                          <li key={index}>
                            <Typography
                              // @ts-ignore
                              variant="body14"
                              sx={{ fontWeight: "400" }}
                            >
                              {convertToFormattedText(action_plan)}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    // @ts-ignore
                    <Typography variant="body14">
                      No Data Action Plan
                    </Typography>
                  )
                }
              />
            </Stack>
            <Box sx={{ marginBottom: "24px" }}>
              {/* @ts-ignore */}
              <Typography variant="body14" sx={{ fontWeight: "400" }}>
                <ReactMarkdown>
                  {
                    props.sessionNotes[currentNoteIndex].ourcoachSummary
                      .challenges_and_goals
                  }
                </ReactMarkdown>
              </Typography>
              {/* @ts-ignore */}
              <Typography variant="body14" sx={{ fontWeight: "400" }}>
                <ReactMarkdown>
                  {
                    props.sessionNotes[currentNoteIndex].ourcoachSummary
                      .discussion_points
                  }
                </ReactMarkdown>
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: "0px 34px 24px 34px", position: "relative" }}
      >
        <Stack
          direction="column"
          gap="16px"
          sx={{ position: "sticky", width: "100%" }}
        >
          {!bookingInfo && (userInfo?.session_slot || 0) > 0 && (
            <Button
              variant="contained"
              size="large"
              sx={{ height: "48px" }}
              endIcon={<CalendarDaysIcon style={{ width: "24px" }} />}
              onClick={props.onBooking}
            >
              Book your next session
            </Button>
          )}

          <Stack direction="row" gap="16px">
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              sx={{ height: "48px" }}
              disabled={currentNoteIndex === 0}
              startIcon={<ChevronLeftIcon style={{ width: "24px" }} />}
              onClick={() => setCurrentNoteIndex((prev) => prev - 1)}
            >
              Prev note
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              sx={{ height: "48px" }}
              disabled={currentNoteIndex === props.sessionNotes.length - 1}
              endIcon={<ChevronRightIcon style={{ width: "24px" }} />}
              onClick={() => setCurrentNoteIndex((prev) => prev + 1)}
            >
              Next note
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSessionNoteDetail;
