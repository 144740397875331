import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { removeCookie } from "@util/cookie";

function Header() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        background: "#F4EDE3",
        padding: "15px 0px",
        borderBottom: "1px solid #D8D8D8",
        zIndex: "99",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          padding: {
            lg: "0px",
            xs: "0px 24px",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography
            onClick={() => navigate("/")}
            variant="h4"
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            ourcoach
          </Typography>
          <IconButton
            sx={{ padding: "0px" }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <UserCircleIcon style={{ width: "24px", color: "#000000DE" }} />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                sx: {
                  top: "50px !important",
                },
              },
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="start"
              alignItems="start"
              sx={{ width: "155px" }}
            >
              <Button
                fullWidth
                onClick={() => navigate("/profile")}
                sx={{
                  color: "#000000DE",
                  padding: "8px 16px",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  borderRadius: "0px",
                }}
              >
                My Account
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  removeCookie("authToken");
                  sessionStorage.removeItem("phoneVerify");
                  sessionStorage.removeItem("emailVerify");
                  sessionStorage.removeItem("userId");
                  navigate("/", { replace: true });
                }}
                sx={{
                  color: "#D92323",
                  padding: "8px 16px",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  borderRadius: "0px",
                }}
              >
                Log out
              </Button>
            </Stack>
          </Popover>
        </Stack>
      </Container>
    </Box>
  );
}

export default Header;
