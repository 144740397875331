/* eslint-disable prettier/prettier */
import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { parseFormat } from "@util/date";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

interface Props {
  isReschedule?: boolean;
  bookingDate?: string;
  onSelected: (date: Date) => void;
}

function TwoWeekCalendar(props: Props) {
  const today = new Date();
  const currentDate = props.isReschedule
    ? new Date(props.bookingDate || today.toISOString())
    : null;
  const [selectedDate, setSelectedDate] = useState<Date>(today);

  const calculateStartOfWeek = (date: Date) => {
    const start = new Date(date);
    const day = start.getDay();
    const diff = day === 0 ? -6 : 1 - day; // Jika hari Minggu (0), geser ke Senin
    start.setDate(start.getDate() + diff); // Geser tanggal ke awal minggu
    start.setHours(0, 0, 0, 0); // Reset waktu ke tengah malam
    return start;
  };

  function getStartOfWeek(date: Date): Date {
    var day = date.getDay() || 7;
    if (day !== 1) date.setHours(-24 * (day - 1));
    return date;
  }

  getStartOfWeek(today);

  const calculateEndOfTwoWeeks = (start: Date) => {
    const end = new Date(start);
    end.setDate(start.getDate() + 13);
    return end;
  };

  const [currentStart, setCurrentStart] = useState(() =>
    calculateStartOfWeek(today)
  );
  const currentEnd = calculateEndOfTwoWeeks(currentStart);

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const getDatesInRange = (start: Date, end: Date) => {
    const dates = [];
    let date = new Date(start);
    while (date <= end) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const allDates = getDatesInRange(currentStart, currentEnd);

  const handleNext = () => {
    const nextStart = new Date(currentStart);
    nextStart.setDate(currentStart.getDate() + 14);
    setCurrentStart(nextStart);
  };

  const handlePrev = () => {
    const prevStart = new Date(currentStart);
    prevStart.setDate(currentStart.getDate() - 14);
    setCurrentStart(prevStart);
  };

  const isPrevDisabled = currentStart <= today;

  return (
    <Box
      sx={{
        background: "#00000005",
        border: "1px solid #D8D8D8",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          padding: "12px 24px",
          borderBottom: "1px solid #D8D8D8",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#000000DE",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            letterSpacing: "0.15px",
          }}
        >{`${formatDate(currentStart)} - ${formatDate(currentEnd)}`}</Typography>
        <Stack direction="row" gap="8px">
          <IconButton onClick={handlePrev} disabled={isPrevDisabled}>
            <ChevronLeftIcon style={{ width: "16px", color: "#000000DE" }} />
          </IconButton>
          <IconButton onClick={handleNext}>
            <ChevronRightIcon style={{ width: "16px", color: "#000000DE" }} />
          </IconButton>
        </Stack>
      </Box>
      {allDates.length > 7 && (
        <Box sx={{ padding: "12px 16px" }}>
          <Grid container spacing={0.5} justifyContent="center" columns={7}>
            {allDates.slice(0, 7).map((date, index) => (
              <Grid key={index} size={1}>
                <Box
                  sx={{
                    userSelect: "none",
                    fontSize: "13.13px",
                    fontWeight: 400,
                    lineHeight: "18.77px",
                    letterSpacing: "0.16px",
                    textAlign: "center",
                    width: "33.75px",
                    height: "33.75px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    color: "#00000099",
                  }}
                >
                  {["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
                </Box>
              </Grid>
            ))}
            {allDates.map((date, index) => {
              return (
                <Grid key={index} size={1}>
                  <Box
                    onClick={() => {
                      props.onSelected(date);
                      setSelectedDate(date);
                    }}
                    sx={{
                      userSelect: "none",
                      fontSize: "13.13px",
                      fontWeight: 400,
                      lineHeight: "18.77px",
                      letterSpacing: "0.16px",
                      textAlign: "center",
                      width: "33.75px",
                      height: "33.75px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "100px",
                      border:
                        today.getDate() === date.getDate() &&
                        today.getMonth() === date.getMonth()
                          ? "1px solid #2F4B73"
                          : "1px solid transparant",
                      background: props.isReschedule
                        ? parseFormat(
                            currentDate?.toDateString() || "",
                            "YYYY-MM-DD"
                          ) === parseFormat(date.toDateString(), "YYYY-MM-DD")
                          ? "#D2DFE1"
                          : parseFormat(
                                selectedDate?.toDateString() || "",
                                "YYYY-MM-DD"
                              ) ===
                              parseFormat(date.toDateString(), "YYYY-MM-DD")
                            ? "#ED9B003D"
                            : "transparent"
                        : parseFormat(
                              selectedDate?.toDateString() || "",
                              "YYYY-MM-DD"
                            ) === parseFormat(date.toDateString(), "YYYY-MM-DD")
                          ? "#D2DFE1"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "#00000014 ",
                        color: "#000000DE",
                      },
                    }}
                  >
                    {date.getDate()}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default TwoWeekCalendar;
