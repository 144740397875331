/* eslint-disable prettier/prettier */
import { useParams } from "react-router";
import { BookingReport, QuestionSummary } from "@type/booking";
import React, { useEffect, useState } from "react";
import {
  getBookingInfoSumary,
  submitBookingSummaryReport,
} from "@service/booking";
import { parseFormat } from "@util/date";
import AppLayout from "@container/app-layout";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";
import { catchErrorLog } from "@util/sentry";

const BookingSummary: React.FC = (params: any) => {
  let { bookingid } = useParams();
  const [questions, setQuestion] = useState<QuestionSummary[]>([]);
  const [bookingReport, setBookingReport] = useState<BookingReport | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );

  const reloadBookingSummaryReport = async () => {
    getBookingInfoSumary(bookingid || "")
      .then((resp) => {
        setBookingReport(resp.data.data);
      })
      .catch((err) => {
        catchErrorLog(err?.response?.data.message);
      });
  };

  useEffect(() => {
    reloadBookingSummaryReport();
    setQuestion([
      {
        no_question: 1,
        question:
          "What is the user's action plan for addressing the user's current challenges?",
        answer: "",
      },
      {
        no_question: 2,
        question: "Is the user still facing the same challenges?",
        answer: "",
      },
      {
        no_question: 3,
        question: "Is the user facing any new challenges?",
        answer: "",
      },
      {
        no_question: 4,
        question: "Does the user have any other focuses?",
        answer: "",
      },
      {
        no_question: 5,
        question:
          "Based on today’s session, would the user like to continue their current goal? Or work on something else or other focus areas in the coming weeks?",
        answer: "",
      },
      {
        no_question: 6,
        question: "Additional Feedback",
        answer: "",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    try {
      const ggReport = questions.map((item) => ({
        question: item.no_question,
        answer: item.answer,
      }));
      const resp = await submitBookingSummaryReport(bookingid || "", ggReport);
      if (resp.data.code === 200) {
        alert("Thank you! The report has been successfully submitted");
      } else {
        alert(resp.data.message);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // @ts-ignore
        catchErrorLog(err?.response?.data.message);
      }
    }
  };

  const onChangeAnswer = (currentIndex: number, value: string) => {
    const otherQuestions = questions.filter(
      (_, index) => index !== currentIndex
    );
    if (otherQuestions.length > 0) {
      const newQuestion =
        questions.map((item, index) => {
          if (index === currentIndex) {
            return {
              no_question: item.no_question,
              question: item.question,
              answer: value,
            };
          }
          return item;
        }) || [];

      // @ts-ignore
      setQuestion(newQuestion);
    }
  };

  return (
    <AppLayout>
      <Stack
        direction="column"
        sx={{
          paddingTop: {
            md: "48px",
            xs: "34px",
          },
        }}
      >
        <Typography variant="h4">Summary Session</Typography>
        <Box sx={{ margin: "16px 0px" }}>
          <Typography>
            <b>User Name:</b> {bookingReport?.user.name}
          </Typography>
          <Typography>
            <b>Email:</b> {bookingReport?.user.email}
          </Typography>
          <Typography>
            <b>Date:</b>{" "}
            {parseFormat(bookingReport?.sessionStartedAt || "", "DD MMM YYYY")}
          </Typography>
          <Typography>
            <b>Time:</b>{" "}
            {parseFormat(bookingReport?.sessionStartedAt || "", "HH:mm A")}
          </Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{
            padding: "16px",
            marginTop: "16px",
            border: "1px solid #00000029",
            borderRadius: "16px",
            marginBottom: "48px",
          }}
        >
          {questions.map((item, index) => (
            <FormGroup key={index} sx={{ marginBottom: "16px" }}>
              <FormLabel
                color="secondary"
                sx={{
                  marginBottom: "8px",
                  color: "#000000DE",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                {`${index + 1}) ${item.question}`}
              </FormLabel>
              <OutlinedInput
                sx={{
                  "&.MuiInputBase-root": { padding: "0px", fontSize: "14px" },
                }}
                fullWidth
                multiline
                value={item.answer}
                rows={8}
                onChange={(e) => onChangeAnswer(index, e.target.value || "")}
              />
            </FormGroup>
          ))}
          <Stack
            direction="row"
            sx={{ marginTop: "24px" }}
            justifyContent="flex-end"
          >
            <Button
              sx={{ width: "180px" }}
              onClick={onSubmit}
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </AppLayout>
  );
};

export default BookingSummary;
