/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { BookingInfo } from "@type/booking";
import { getBookingGrowthGuideInfo } from "@service/booking";
import { UserSummary } from "@type/user";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import BookingGrowthGuideReschedule from "@container/booking-growth-guide-sidebar/reschedule";
import BookingGrowthGuideDetail from "@container/booking-growth-guide-sidebar/info";
import DialogBookingCancel from "@component/dialog/dialog-booking-cancel";
import { catchErrorLog } from "@util/sentry";

interface Props {
  open?: boolean;
  isLoadingSummary: boolean;
  userSummary?: UserSummary;
  onClose: () => void;
  onReloadBookingInfo: () => void;
}

function DrawerBookingDetailGrowthGuide(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );
  const [isRescheduleBooking, setRescheduleBooking] = useState<boolean>(false);
  const [isOpenDialogBookingCancel, setIsOpenDialogBookingCancel] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.open) {
      setLoading(true);
      getBookingGrowthGuideInfo()
        .then((resp) => {
          setLoading(false);
          setBookingInfo(resp.data.data);
        })
        .catch((err) => {
          setLoading(false);
          catchErrorLog(err?.response?.data.message);
        });
    }
  }, [props.open]);

  return (
    <>
      <Drawer
        open={props.open}
        onClose={props.onClose}
        anchor="bottom"
        sx={{
          position: "relative",
          ".MuiPaper-root": {
            background: "#F4EDE3",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        }}
      >
        <Box
          sx={{
            height: "5px",
            width: "48px",
            borderRadius: "100px",
            background: "#CDCFD0",
            margin: "8px auto 11px",
          }}
        ></Box>
        <Box>
          {isRescheduleBooking ? (
            <BookingGrowthGuideReschedule
              bookingInfo={bookingInfo}
              onReloadBookingInfo={props.onReloadBookingInfo}
              onBack={() => setRescheduleBooking(false)}
              onCloseSidebar={props.onClose}
            />
          ) : (
            <BookingGrowthGuideDetail
              isLoading={isLoading}
              isLoadingSummary={props.isLoadingSummary}
              userSummary={props.userSummary}
              bookingInfo={bookingInfo}
              onCloseSidebar={props.onClose}
              onCancelBooking={() => setIsOpenDialogBookingCancel(true)}
              onRescheduleBooking={() => setRescheduleBooking(true)}
            />
          )}
        </Box>
      </Drawer>
      <DialogBookingCancel
        bookingInfo={bookingInfo}
        isOpen={isOpenDialogBookingCancel}
        onClose={(_, reason: string) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
          if (reason === "close_sidebar") {
            props.onClose();
          }
          setIsOpenDialogBookingCancel(false);
          props.onReloadBookingInfo();
        }}
      />
    </>
  );
}

export default DrawerBookingDetailGrowthGuide;
