/* eslint-disable react/jsx-no-undef */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  content: React.ReactNode;
}

function SessionNoteBox(props: Props) {
  return (
    <Box
      sx={{
        border: "1px solid #00000029",
        overflow: "hidden",
        borderRadius: "8px",
        marginTop: "16px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          background: "#D8E1E3",
          padding: "8px",
          textAlign: "center",
        }}
      >
        {/* @ts-ignore */}
        <Typography variant="body14" sx={{ fontWeight: "700" }}>
          {props.title}
        </Typography>
      </Box>
      <Box sx={{ padding: "12px" }}>{props.content}</Box>
    </Box>
  );
}

export default SessionNoteBox;
