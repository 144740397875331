import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { reschduleBookingGrowthGuide } from "@service/booking";
import { BookingRequest } from "@type/booking";
import { useForm } from "react-hook-form";
import { convertToLocalTime, parseFormat } from "@util/date";
import { ScheduleItem } from "@type/coach";
import { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface Props {
  isOpen: boolean;
  bookingID: string;
  date: string;
  selectedScheduleItem?: ScheduleItem;
  onClose: (event: any, reason: string) => void;
  onCompleted: () => void;
}

function DialogRescheduleBooking(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<BookingRequest>();

  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          color: "#000000DE",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        Confirm your booking
      </DialogTitle>
      <DialogContent sx={{ padding: "24px", paddingTop: "16px !important" }}>
        <form
          onSubmit={handleSubmit(async () => {
            setLoading(true);
            const resp = await reschduleBookingGrowthGuide({
              booking_id: props.bookingID,
              schedule_id: props.selectedScheduleItem?.schedule_id || "",
              is_term: true,
            });

            if (resp.data.code === 200) {
              props.onCompleted();
            }

            setLoading(false);
          })}
        >
          {/* @ts-ignore */}
          <Typography variant="body14" sx={{ color: "#000000DE" }}>
            You have selected the following time:
          </Typography>
          <Stack
            direction="column"
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              padding: "8px 16px",
              background: "#EFEFEF",
              border: "1px solid #D8D8D8",
              borderRadius: "4px",
            }}
          >
            {/* @ts-ignore */}
            <Typography variant="body14" sx={{ color: "#000000DE" }}>
              <b>Date:</b> {parseFormat(props.date || "", "DD MMM YYYY")}
            </Typography>
            {/* @ts-ignore */}
            <Typography variant="body14" sx={{ color: "#000000DE" }}>
              <b>Time:</b>{" "}
              {`${convertToLocalTime(props.selectedScheduleItem?.start_time || "")} - ${convertToLocalTime(props.selectedScheduleItem?.end_time || "")}`}
            </Typography>
          </Stack>
          <FormGroup>
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                margin: "0px",
              }}
              control={
                <Checkbox
                  {...register("is_term", { required: true })}
                  sx={{ padding: "0px" }}
                />
              }
              label={
                <Typography
                  variant="caption"
                  sx={{ color: "#000000DE", letterSpacing: "0.15px" }}
                >
                  I consent to sharing my chat progress and session notes
                  between ourcoach AI and my Growth Guide for an enhanced
                  personalized experience.
                </Typography>
              }
            />
            {errors.is_term && (
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                justifyContent="flex-start"
                gap="6px"
                sx={{ marginTop: "10px" }}
              >
                <ExclamationCircleIcon
                  style={{ width: "16px", color: "#D92323" }}
                />
                <Typography
                  variant="caption"
                  sx={{ color: "#D92323", letterSpacing: "0.15px" }}
                >
                  You have to consent to book a Growth Guide session.
                </Typography>
              </Stack>
            )}
          </FormGroup>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => props.onClose(null, "")}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} variant="contained">
              Confirm booking
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default DialogRescheduleBooking;
