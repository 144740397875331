import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FormActivatePhone from "@container/form-activate-phone";

function ActivateAccountPage() {
  useEffect(() => {
    const body = document.body;
    body.classList.add("base");
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ height: "100%", padding: "16px" }}
    >
      <Box sx={{ position: "absolute", top: "40px" }}>
        <Typography variant="h5">ourcoach</Typography>
      </Box>
      <Box
        sx={{
          marginBottom: "50px",
          width: {
            md: "386px",
            xs: "100%",
          },
        }}
      >
        <Typography variant="h3" sx={{ marginBottom: "16px" }}>
          Activate account
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#000000DE", marginBottom: "32px" }}
        >
          Let’s start with entering your WhatsApp phone number. This is the
          number you have provided when completing the form.
        </Typography>

        <FormActivatePhone />
        <Stack
          direction="row"
          alignItems="center"
          alignContent="center"
          gap={1}
          sx={{ marginTop: "16px" }}
        >
          <Typography variant="body2">
            Already activated your account?
          </Typography>
          <Link href="/login">Log in here</Link>
        </Stack>
      </Box>
    </Stack>
  );
}

export default ActivateAccountPage;
