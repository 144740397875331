/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import { useUserProfileContext } from "context/user-profile-context";
import { useTheme } from "@mui/material/styles";
import { getCoachInfo } from "@service/coach";
import { CoachInfo } from "@type/coach";
import { useUserSubscriptionContext } from "context/user-subscription-context";
import { catchErrorLog } from "@util/sentry";
import { UserSummary } from "@type/user";
import { getUserSummary } from "@service/user";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { getBookingGrowthGuideInfo } from "@service/booking";
import { BookingInfo } from "@type/booking";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppLayout from "@container/app-layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import GrowthGuide from "@container/growth-guide";
import BookGrowthGuide from "@container/book-growth-guide";
import CoachSchedule from "@container/coach-schedule";
import DrawerBookingGrowthGuide from "@container/drawer-booking-growth-guide";
import DrawerBookingDetailGrowthGuide from "@container/drawer-booking-detail";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DialogBookingSkip from "@component/dialog/dialog-booking-skip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

dayjs.extend(relativeTime);

function YourGrowthJourneyPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediaQuerySM = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoadingUserProfile, userInfo] = useUserProfileContext();
  const [retryUserSummaryCall, setRetryUserSummaryCall] = useState<number>(0);
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const [sidebarType, setSidebarType] = useState<string>("");
  const [isLoadingUserSummary, setLoadingUserSummary] =
    useState<boolean>(false);
  const [isLoadingUserSubscription, userSubscriptionInfo] =
    useUserSubscriptionContext();
  const [userSummary, setUserSummary] = useState<UserSummary | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );
  const [isLoadingCoachInfo, setLoadingCoachInfo] = useState<boolean>(true);
  const [coachInfo, setCoachInfo] = useState<CoachInfo | undefined>(undefined);
  const [countReloadBookingInfo, setCountReloadBookingInfo] =
    useState<number>(0);
  const [isOpenDrawerBooking, setOpenDrawerBooking] = useState<boolean>(false);
  const [isOpenDrawerBookingDetail, setOpenDrawerBookingDetail] =
    useState<boolean>(false);
  const [isOpenDialogBookingSkip, setOpenDialogBookingSkip] =
    useState<boolean>(false);
  const [isLoadingBookingInfo, setLoadingBookingInfo] =
    useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );

  const reloadUserSummary = () => {
    setLoadingUserSummary(true);
    getUserSummary()
      .then((resp) => {
        setLoadingUserSummary(false);
        setUserSummary(resp.data.data);
      })
      .catch((e) => {
        catchErrorLog(`failed to get user summary, err: ${e.message}`);
        setRetryUserSummaryCall((prevState) => {
          return prevState + 1;
        });
      });
  };

  useEffect(() => {
    if (retryUserSummaryCall > 0 && retryUserSummaryCall <= 5) {
      reloadUserSummary();
    }
  }, [retryUserSummaryCall]);

  useEffect(() => {
    if (!isLoadingUserProfile) {
      getCoachInfo(userInfo?.booking.assign_growth_guide_id || "")
        .then((resp) => {
          setLoadingCoachInfo(false);
          setCoachInfo(resp.data.data);
        })
        .catch((err) => {
          setLoadingCoachInfo(false);
          catchErrorLog(err?.response?.data.message);
        });
    }
  }, [isLoadingUserProfile, userInfo?.booking.assign_growth_guide_id]);

  const reloadBookingInfo = () => {
    setLoadingBookingInfo(true);
    getBookingGrowthGuideInfo()
      .then((resp) => {
        setLoadingBookingInfo(false);
        setBookingInfo(resp.data.data);
      })
      .catch((err) => {
        setLoadingBookingInfo(false);
        catchErrorLog(err?.response?.data.message);
      });
  };

  useEffect(() => {
    reloadUserSummary();
    reloadBookingInfo();
  }, []);

  const onCloseSidebar = () => {
    setSidebarType("");
    setIsOpenSidebar(false);
  };

  const onHandleBookingSession = () => {
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("BOOKING_SESSION");
    } else {
      setOpenDrawerBooking(true);
    }
  };

  const onHandleBookingDetail = () => {
    if (isMediaQuerySM) {
      setIsOpenSidebar(true);
      setSidebarType("BOOKING_DETAIL");
    } else {
      setOpenDrawerBookingDetail(true);
    }
  };

  return (
    <>
      <AppLayout
        isOpenSidebar={isOpenSidebar}
        isShowAlertSubscription={
          userSubscriptionInfo?.status !== "active" ||
          !!userSubscriptionInfo.canceled_at ||
          false
        }
        sidebar={
          <Box sx={{ width: "360px" }}>
            {sidebarType === "BOOKING_SESSION" && (
              <CoachSchedule
                onCloseSidebar={onCloseSidebar}
                onReloadBookingInfo={() => {
                  setCountReloadBookingInfo((prevState) => {
                    return prevState + 1;
                  });
                }}
                notes={userSummary?.users_growth_guide_preparation_brief}
              />
            )}
          </Box>
        }
      >
        <Box
          sx={{
            marginTop: { md: "100px", xs: "84px" },
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                lg: "42px",
                md: "42px",
                xs: "20px",
              },
              fontWeight: 700,
              lineHeight: {
                lg: "50.4px",
                md: "50.4px",
                xs: "24px",
              },
              color: "#000000DE",
              marginBottom: "8px",
            }}
          >
            {!bookingInfo
              ? "Meet Your Growth Guide"
              : "Your Growth Guide session is booked!"}
          </Typography>
          {!bookingInfo && (
            <Stack direction="column" gap="24px">
              <Typography
                //   @ts-ignore
                variant="body14"
                sx={{ color: "#000000DE", fontWeight: 400 }}
              >
                Congratulations for coming this far!
              </Typography>
              <Typography
                //   @ts-ignore
                variant="body14"
                sx={{ color: "#000000DE", fontWeight: 400 }}
              >
                We’ve assigned <b>{coachInfo?.fullName ?? ""}</b> to you as your{" "}
                <b>Growth Guide</b>, your dedicated human guide, to kick off
                your growth journey and provide tailored insights for your
                journey.
              </Typography>
              <Typography
                //   @ts-ignore
                variant="body14"
                sx={{ color: "#000000DE", fontWeight: 400 }}
              >
                Your first session is <b>free</b>.{" "}
                <b>Book your first session</b> now to start unlocking your
                potential and get better clarity.
              </Typography>
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            marginTop: { md: "32px", xs: "16px" },
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          <Box
            sx={{
              marginBottom: "16px",
              borderRadius: "8px",
              background: "#FFFCFA",
              border: "1px solid #2F4B73",
              overflow: "hidden",
              opacity:
                userSubscriptionInfo?.status === "canceled" ||
                userSubscriptionInfo?.status === "not_subscribed"
                  ? 0.6
                  : 1,
            }}
          >
            <Stack direction="row">
              <Box sx={{ flex: 1, height: "8px", background: "#557097" }}></Box>
              <Box
                sx={{ flex: 1, height: "8px", background: "#103138 " }}
              ></Box>
              <Box sx={{ flex: 1, height: "8px", background: "#DFA17C" }}></Box>
            </Stack>
            <Stack
              direction={{ md: "row", xs: "column" }}
              sx={{
                height: {
                  md: "328px",
                  xs: "100%",
                },
              }}
            >
              <GrowthGuide
                isWelcomeMessage
                coachInfo={coachInfo}
                isLoading={isLoadingCoachInfo}
              />
              <BookGrowthGuide
                isWelcomeMessage
                isLoadingSummary={
                  isLoadingUserProfile ||
                  isLoadingUserSummary ||
                  isLoadingCoachInfo ||
                  isLoadingUserSubscription ||
                  isLoadingBookingInfo
                }
                isLock={
                  userSubscriptionInfo?.status === "canceled" ||
                  userSubscriptionInfo?.status === "not_subscribed"
                }
                userSubscriptionInfo={userSubscriptionInfo}
                userSummary={userSummary}
                sessionSlot={userInfo?.session_slot || 0}
                countReload={countReloadBookingInfo}
                onBooking={onHandleBookingSession}
                onBookingDetail={onHandleBookingDetail}
              />
            </Stack>
          </Box>
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          gap="12px"
          sx={{ marginTop: "32px", marginBottom: "32px" }}
        >
          {!bookingInfo ? (
            <>
              <Button
                fullWidth
                disabled={
                  isLoadingUserProfile ||
                  isLoadingUserSummary ||
                  isLoadingCoachInfo ||
                  isLoadingUserSubscription ||
                  isLoadingBookingInfo
                }
                onClick={onHandleBookingSession}
                variant="contained"
                sx={{ height: "48px", fontSize: "16px !important" }}
                endIcon={<CalendarDaysIcon style={{ width: "20px" }} />}
              >
                Book my free session
              </Button>
              <Button
                fullWidth
                disabled={
                  isLoadingUserProfile ||
                  isLoadingUserSummary ||
                  isLoadingCoachInfo ||
                  isLoadingUserSubscription
                }
                variant="contained"
                color="secondary"
                onClick={() => setOpenDialogBookingSkip(true)}
                sx={{ height: "48px", fontSize: "16px !important" }}
              >
                Let me explore first
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              disabled={
                isLoadingUserProfile ||
                isLoadingUserSummary ||
                isLoadingCoachInfo ||
                isLoadingUserSubscription ||
                isLoadingBookingInfo
              }
              onClick={() => navigate("/")}
              variant="contained"
              sx={{ height: "48px", fontSize: "16px !important" }}
            >
              Continue to Revelation Dashboard
            </Button>
          )}
        </Stack>
      </AppLayout>
      <DrawerBookingGrowthGuide
        isWelcomeMessage
        open={isOpenDrawerBooking}
        notes={userSummary?.users_growth_guide_preparation_brief}
        onClose={() => setOpenDrawerBooking(false)}
        onReloadBookingInfo={() => {
          setCountReloadBookingInfo((prevState) => {
            return prevState + 1;
          });
        }}
      />
      <DrawerBookingDetailGrowthGuide
        open={isOpenDrawerBookingDetail}
        isLoadingSummary={isLoadingUserSummary || isLoadingCoachInfo}
        userSummary={userSummary}
        onClose={() => setOpenDrawerBookingDetail(false)}
        onReloadBookingInfo={() => {
          setCountReloadBookingInfo((prevState) => {
            return prevState + 1;
          });
        }}
      />
      <DialogBookingSkip
        isOpen={isOpenDialogBookingSkip}
        onClose={() => {
          setOpenDialogBookingSkip(false);
          if (isMediaQuerySM) {
            setIsOpenSidebar(true);
            setSidebarType("BOOKING_SESSION");
          } else {
            setOpenDrawerBooking(true);
          }
        }}
      />
    </>
  );
}

export default YourGrowthJourneyPage;
