import { GrowthGuideNote } from "@type/user";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CoachSchedule from "@container/coach-schedule";

interface Props {
  open?: boolean;
  isWelcomeMessage?: boolean;
  notes?: GrowthGuideNote[];
  onClose: () => void;
  onReloadBookingInfo: () => void;
}

function DrawerBookingGrowthGuide(props: Props) {
  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      anchor="bottom"
      sx={{
        position: "relative",
        ".MuiPaper-root": {
          background: "#F4EDE3",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      }}
    >
      <Box
        sx={{
          height: "5px",
          width: "48px",
          borderRadius: "100px",
          background: "#CDCFD0",
          margin: "8px auto 11px",
        }}
      ></Box>
      <Box>
        <CoachSchedule
          isWelcomeMessage
          onCloseSidebar={props.onClose}
          onReloadBookingInfo={props.onReloadBookingInfo}
          notes={props.notes}
        />
      </Box>
    </Drawer>
  );
}

export default DrawerBookingGrowthGuide;
