import { BoxStyled } from "./styled";
import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BookingReviewRequest } from "@type/booking";
import { submitBookingReviewGrowthGuide } from "@service/booking";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from "components/alert";
import { catchErrorLog } from "@util/sentry";

interface Props {
  bookingID: string;
}

function RateSession(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isRateCompleted, setRateCompleted] = useState<boolean>(false);
  const [isCloseRate, setCloseRate] = useState<boolean>(true);
  const [rate, setRate] = useState<number>(0);

  const { handleSubmit, register } = useForm<BookingReviewRequest>();

  const onClose = () => {
    setCloseRate(false);
  };

  if (isRateCompleted) {
    return (
      <Alert
        open={isCloseRate}
        title="🙌 Thank you for your feedback!"
        sx={{
          alignItems: "center",
          padding: {
            md: "24px !important",
            xs: "16px !important",
          },
          ".MuiAlert-action": {
            width: "20px",
            height: "20px",
            color: "#000000DE",
            ".MuiIconButton-root": {
              padding: "0px",
            },
          },
        }}
        onClose={onClose}
      />
    );
  }

  return (
    <BoxStyled
      sx={{
        padding: {
          md: "24px",
          xs: "16px",
        },
      }}
    >
      <Stack
        direction={{ md: "row", xs: "column" }}
        justifyContent={{
          md: "space-between",
          xs: "center",
        }}
        alignContent={{
          md: "flex-start",
          xs: "center",
        }}
        alignItems={{
          md: "flex-start",
          xs: "center",
        }}
        gap={{
          md: "0px",
          xs: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              md: "16px",
              xs: "14px",
            },
            fontWeight: "600",
            lineHeight: {
              md: "24px",
              xs: "21px",
            },
            letterSpacing: "0.15px",
            color: "#000000DE",
            textAlign: {
              md: "left",
              xs: "center",
            },
          }}
        >
          🎉 Growth Guide session completed! Let us know how it went.
        </Typography>
        {!isRateCompleted && (
          <Rating
            size="large"
            defaultValue={rate}
            emptyIcon={<StarIconOutline style={{ width: "24px" }} />}
            icon={<StarIconSolid style={{ width: "24px", color: "#ED9B00" }} />}
            // @ts-ignore
            onChange={(_, val: number) => {
              setRate(val);
            }}
            sx={{
              gap: {
                md: "8px",
                xs: "24px",
              },
            }}
          />
        )}
      </Stack>
      <Collapse in={rate > 0}>
        <form
          onSubmit={handleSubmit(async (data: BookingReviewRequest) => {
            setLoading(true);
            try {
              const resp = await submitBookingReviewGrowthGuide({
                booking_id: props.bookingID,
                rate: rate,
                comment: data.comment,
              });
              if (resp.data.code === 200) {
                setRate(0);
                setRateCompleted(true);
              }
            } catch (err) {
              if (axios.isAxiosError(err)) {
                catchErrorLog(err?.response?.data.message);
              }
            }
            setLoading(false);
          })}
        >
          <Box
            sx={{
              borderTop: {
                md: "none",
                xs: "1px solid #00000029",
              },
              marginTop: {
                md: "32px",
                xs: "16px",
              },
              paddingTop: {
                md: "0px",
                xs: "16px",
              },
            }}
          >
            {/* @ts-ignore */}
            <Typography variant="body14" sx={{ color: "#000000DE" }}>
              Please elaborate on your ratings:
            </Typography>

            <OutlinedInput
              placeholder="(Optional)"
              sx={{
                padding: "0px",
                width: "100%",
                marginTop: "8px",
                marginBottom: "16px",
                ".MuiInputBase-input": { padding: "12px", fontSize: "14px" },
              }}
              {...register("comment")}
              multiline
              rows={3}
            />

            <Stack
              direction="row"
              justifyContent={{
                md: "flex-end",
                xs: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={rate === 0 || isLoading}
                sx={{
                  minWidth: {
                    md: "200px",
                    xs: "100%",
                  },
                  maxWidth: "100%",
                }}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </Collapse>
    </BoxStyled>
  );
}

export default RateSession;
