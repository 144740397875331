import { getCookie } from "@util/cookie";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_BASE_URL;
export const getSubscriptionPlan = () => {
  const token = getCookie("authToken");
  return axios({
    url: `${apiURL}/subscription/plan`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
