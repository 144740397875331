/* eslint-disable prettier/prettier */
import { Widget } from "@typeform/embed-react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

interface Props {
  isOpen: boolean;
  onClose: (event: any, reason: string) => void;
}

function DialogSubscriptionEnterprise(props: Props) {
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          margin: "16px",
          width: "100%",
        },
      }}
      onClose={props.onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #D8D8D8",
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#000000DE",
          }}
        >
          Enterprise: Custom Pricing
        </Typography>
        <IconButton onClick={() => props.onClose(null, "")}>
          <XMarkIcon
            style={{ width: "16px", height: "16px", color: "#000000DE" }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0px", overflow: "hidden", background: "#fef5e3" }}
      >
        <Widget
          id={process.env.REACT_APP_TYPEFORM_ID_ENTRERPRISE || ""}
          style={{ width: "100%", height: "500px" }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DialogSubscriptionEnterprise;
