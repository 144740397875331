import AppLayout from "@container/app-layout";
import PageInternalServer500 from "@asset/images/page_internal_server_500.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function PageInternaServerError() {
  return (
    <AppLayout>
      <Stack
        direction="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        gap="32px"
        sx={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <img style={{ width: "350px" }} src={PageInternalServer500} alt="404" />
        <Stack
          direction="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          gap="16px"
          sx={{
            width: {
              lg: "468px",
              sm: "382px",
              xs: "100%",
            },
          }}
        >
          <Typography variant="h3">Something went wrong!</Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            We’ve run into an error on our end. Rest assured, we’re on it!
            Please try again later.
          </Typography>
        </Stack>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          size="large"
          variant="contained"
        >
          Return to Dashboard
        </Button>
      </Stack>
    </AppLayout>
  );
}

export default PageInternaServerError;
