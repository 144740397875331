import { ChevronDoubleUpIcon } from "@heroicons/react/24/solid";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface Props {
  count: number;
  isFocus?: boolean;
  sx?: SxProps;
}

function CountGrowthStatus(props: Props) {
  return (
    <Box
      sx={{
        ...props.sx,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="row"
        gap="2px"
        sx={{
          background: props.isFocus ? "#ED9B003D" : "#0000000A",
          padding: "2px 4px",
          borderRadius: "100px",
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: "500",
          letterSpacing: "0.15px",
        }}
      >
        {props.isFocus && (
          <Box>
            <ChevronDoubleUpIcon
              style={{ width: "12px", height: "12px", color: "#B97900" }}
            />
          </Box>
        )}
        {props.count}
      </Stack>
    </Box>
  );
}

export default CountGrowthStatus;
