import { RadarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useEffect, useRef } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CountGrowthStatus from "@component/count-growth-status";
import * as echarts from "echarts/core";
import Stack from "@mui/material/Stack";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  RadarChart,
  CanvasRenderer,
]);

const MAX_POINT_GROWTH_STATUS = 200;
const MIN_POINT_GROWTH_STATUS = 0;

interface Props {
  isLoading: boolean;
  personalGrowth: number;
  healthWellness: number;
  mentalWellBeing: number;
  careerGrowth: number;
  relationship: number;
}

function GrowthStatusChart(props: Props) {
  const chartRef = useRef<ReactEChartsCore>(null);

  useEffect(() => {
    if (!props.isLoading && chartRef.current) {
      const echartInstance = chartRef.current.getEchartsInstance();
      echartInstance.resize();
    }
  }, [props.isLoading, chartRef]);

  const option = {
    color: "#184E9F80",
    radar: {
      indicator: [
        { name: "Personal Growth", max: MAX_POINT_GROWTH_STATUS },
        { name: "Health &\nWellness", max: MAX_POINT_GROWTH_STATUS },
        { name: "Mental\nWell-being", max: MAX_POINT_GROWTH_STATUS },
        { name: "Relationship", max: MAX_POINT_GROWTH_STATUS },
        { name: "Career\nGrowth", max: MAX_POINT_GROWTH_STATUS },
      ],
      nameGap: 10,
      center: ["50%", "50%"],
      radius: 80,
      axisName: {
        color: "#000000DE",
        fontSize: "12px",
        fontWeight: "600",
      },
      splitArea: {
        areaStyle: {
          color: ["#00000008"],
        },
      },
      axisLine: {
        lineStyle: {
          color: "#D3CDC5",
        },
      },
      splitLine: {
        lineStyle: {
          color: "#D3CDC5",
        },
      },
    },
    series: [
      {
        type: "radar",
        data: [
          {
            value: [
              props.personalGrowth > MAX_POINT_GROWTH_STATUS
                ? MAX_POINT_GROWTH_STATUS
                : props.personalGrowth || MIN_POINT_GROWTH_STATUS,
              props.healthWellness > MAX_POINT_GROWTH_STATUS
                ? MAX_POINT_GROWTH_STATUS
                : props.healthWellness || MIN_POINT_GROWTH_STATUS,
              props.mentalWellBeing > MAX_POINT_GROWTH_STATUS
                ? MAX_POINT_GROWTH_STATUS
                : props.mentalWellBeing || MIN_POINT_GROWTH_STATUS,
              props.relationship > MAX_POINT_GROWTH_STATUS
                ? MAX_POINT_GROWTH_STATUS
                : props.relationship || MIN_POINT_GROWTH_STATUS,
              props.careerGrowth > MAX_POINT_GROWTH_STATUS
                ? MAX_POINT_GROWTH_STATUS
                : props.careerGrowth || MIN_POINT_GROWTH_STATUS,
            ],
            name: "Your Growth Status",
          },
        ],
        label: {
          show: false,
          backgroundColor: "#ED9B003D",
          padding: [4, 7],
          borderRadius: 100,
          formatter: function (params: any) {
            return params.value;
          },
        },
        areaStyle: {
          color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
            {
              color: "#194D9C29",
              offset: 1,
            },
            {
              color: "#5AC3A729",
              offset: 0,
            },
          ]),
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: {
          md: "288px",
          xs: "100%",
        },
        height: "316px",
        border: "1px solid #00000066",
        borderRadius: "8px",
        background: "#EFE8DF",
      }}
    >
      <Stack
        direction="row"
        gap="8px"
        alignContent="center"
        alignItems="center"
        sx={{ position: "absolute", top: "8px", left: "8px" }}
      >
        <InformationCircleIcon style={{ width: "20px", color: "#00000099" }} />
        <Typography
          sx={{
            fontSize: "10px",
            lineHeight: "15px",
            letterSpacing: "0.15px",
            fontWeight: 400,
            color: "#00000099",
          }}
        >
          Your progress from the last 30 days
        </Typography>
      </Stack>
      <Box
        sx={{
          display: "none",
          position: "absolute",
          zIndex: 9,
          height: "100%",
          width: "100%",
        }}
      >
        {/* PERSONAL GROWTH */}
        <CountGrowthStatus
          count={props.personalGrowth || MIN_POINT_GROWTH_STATUS}
          isFocus
          sx={{
            top: "25px",
            left: 0,
            right: 0,
          }}
        />
        {/* CAREER GROWTH */}
        <CountGrowthStatus
          count={props.careerGrowth || MIN_POINT_GROWTH_STATUS}
          sx={{
            top: "-20px",
            bottom: 0,
            left: 0,
            right: "-210px",
          }}
        />
        {/* RELATIONSHIP */}
        <CountGrowthStatus
          count={props.relationship || MIN_POINT_GROWTH_STATUS}
          sx={{
            top: "-20px",
            bottom: "-190px",
            left: 0,
            right: "-180px",
          }}
        />
        {/* MENTAL WELL-BEING */}
        <CountGrowthStatus
          count={props.mentalWellBeing || MIN_POINT_GROWTH_STATUS}
          sx={{
            top: "-20px",
            bottom: "-200px",
            left: 0,
            right: "160px",
          }}
        />
        {/* HEALTH WELLNESS */}
        <CountGrowthStatus
          count={props.healthWellness || MIN_POINT_GROWTH_STATUS}
          sx={{
            top: "-20px",
            bottom: 0,
            left: 0,
            right: "230px",
          }}
        />
      </Box>
      <ReactEChartsCore
        ref={chartRef}
        echarts={echarts}
        notMerge={false}
        lazyUpdate={true}
        option={option}
        opts={{ renderer: "svg" }}
      />
    </Box>
  );
}
export default GrowthStatusChart;
