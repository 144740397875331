/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-target-blank */
import { XMarkIcon } from "@heroicons/react/24/solid";
import { BookingInfo } from "@type/booking";
import { parseFormat } from "@util/date";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { UlStyled } from "@container/book-growth-guide/styled";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { UserSummary } from "@type/user";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { convertToFormattedText } from "@util/convert";

interface Props {
  isLoading: boolean;
  bookingInfo?: BookingInfo;
  isLoadingSummary: boolean;
  userSummary?: UserSummary;
  onCloseSidebar: () => void;
  onCancelBooking: () => void;
  onRescheduleBooking: () => void;
}

function BookingGrowthGuideDetail(props: Props) {
  return (
    <Stack direction="column" sx={{ marginTop: { md: "40px", xs: "0px" } }}>
      <Stack
        direction="row"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          marginBottom: {
            md: "24px",
            xs: "0px",
          },
          borderBottom: {
            md: "none",
            xs: "1px solid #00000014",
          },
          padding: {
            md: "0px",
            xs: "8px 16px",
          },
        }}
      >
        <Typography variant="h5" sx={{ color: "#000000DE" }}>
          Your booking details
        </Typography>
        <IconButton onClick={props.onCloseSidebar}>
          <XMarkIcon style={{ width: "20px", color: "#000000DE" }} />
        </IconButton>
      </Stack>
      <Box
        sx={{
          padding: {
            md: "0px",
            xs: "12px 16px",
          },
        }}
      >
        {!props.isLoading && (
          <Box
            sx={{
              padding: "16px",
              background: "#FFFCFA",
              borderRadius: "8px",
              border: "1px solid #00000029",
            }}
          >
            <Stack direction="column" gap="2px">
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.15px",
                }}
              >
                <b>Date:</b>
                {"  "}
                {parseFormat(
                  props.bookingInfo?.started_at || "",
                  "DD/MM/YYYY (ddd)"
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.15px",
                }}
              >
                <b>Time:</b>{" "}
                {`${parseFormat(props.bookingInfo?.started_at || "", "HH:mm")} - ${parseFormat(props.bookingInfo?.ended_at || "", "HH:mm")}`}
              </Typography>
              {props.bookingInfo && (
                <Stack direction="row" gap="8px">
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "0.15px",
                    }}
                  >
                    <b>Link:</b>
                  </Typography>
                  {props.bookingInfo?.remaining_time >
                  Number(process.env.REACT_APP_BOOKING_REMAINING_JOIN_TIME) ? (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Available{" "}
                      {process.env.REACT_APP_BOOKING_REMAINING_JOIN_TIME} mins
                      before session
                    </Typography>
                  ) : (
                    <Stack direction="row" gap="8px">
                      <a
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "21px",
                          letterSpacing: "0.15px",
                          textDecoration: "none",
                          color: "#2F4B73",
                        }}
                        target="_blank"
                        href={props.bookingInfo?.meeting_link}
                      >
                        Join the Zoom meeting
                      </a>
                      <IconButton sx={{ padding: "0px" }}>
                        <DocumentDuplicateIcon
                          style={{ width: "16px", color: "#000000DE" }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              )}

              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.15px",
                }}
              >
                <b>Code:</b> {props.bookingInfo?.code}
              </Typography>
            </Stack>
            <Box sx={{ marginBottom: "12px", marginTop: "16px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.15px",
                  color: "#000000DE",
                }}
              >
                Topics you could explore with your Growth Guide:
              </Typography>
            </Box>
            <Box
              sx={{
                borderRadius: "4px",
                padding: "12px 8px",
                background: "#0000000A",
                border: "1px solid #E5E5E5",
                marginBottom: "16px",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  overflowY: "hidden",
                  paddingRight: "18px",
                  "&:hover": {
                    paddingRight: "6px",
                    overflowY: "scroll",
                  },
                }}
              >
                {!props.isLoadingSummary && (
                  <UlStyled>
                    {props.userSummary?.users_growth_guide_preparation_brief.map(
                      (note, index) => (
                        <li key={index}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: "400",
                              color: "#000000DE",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {convertToFormattedText(note.value)}
                          </Typography>
                        </li>
                      )
                    )}
                  </UlStyled>
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: "#00000099",
                  lineHeight: "18px",
                  letterSpacing: "0.15px",
                }}
              >
                Cancellation/reschedule policy:
              </Typography>
              <ul
                style={{
                  margin: "0px",
                  marginTop: "8px",
                  padding: "0px",
                  paddingLeft: "24px",
                }}
              >
                <li
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    color: "#00000099",
                  }}
                >
                  Reschedule or cancel up to 24 hours before the scheduled time
                  to rebook within the month.
                </li>
                <li
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    color: "#00000099",
                  }}
                >
                  Cancelling within 24 hours will forfeit your session for the
                  month.
                </li>
                <li
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    color: "#00000099",
                  }}
                >
                  Please cancel promptly to notify your coach if you can’t
                  attend.
                </li>
                <li
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    color: "#00000099",
                  }}
                >
                  For further enquiries or help, please contact our support team
                  at{" "}
                  <a
                    target="_blank"
                    style={{ textDecoration: "none", color: "#2F4B73" }}
                    href="mailto:support@ourcoach.ai"
                  >
                    support@ourcoach.ai
                  </a>
                  .
                </li>
              </ul>
            </Box>
            <Stack direction="row" sx={{ marginTop: "16px" }} gap="12px">
              {props.bookingInfo && (
                <>
                  <Button
                    size="small"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      letterSpacing: "0.15px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                    fullWidth
                    color="error"
                    variant={
                      props.bookingInfo?.remaining_time <
                      Number(process.env.REACT_APP_BOOKING_REMAINING_CANCELED)
                        ? "outlined"
                        : "contained"
                    }
                    onClick={props.onCancelBooking}
                  >
                    Cancel session
                  </Button>
                  {props.bookingInfo?.remaining_time >
                  Number(process.env.REACT_APP_BOOKING_REMAINING_JOIN_TIME) ? (
                    <Button
                      size="small"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={props.onRescheduleBooking}
                    >
                      Reschedule session
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      fullWidth
                      variant="contained"
                      endIcon={
                        <ArrowTopRightOnSquareIcon style={{ width: "20px" }} />
                      }
                      onClick={() =>
                        // @ts-ignore
                        window
                          .open(props.bookingInfo?.meeting_link || "", "_blank")
                          .focus()
                      }
                    >
                      Join session
                    </Button>
                  )}
                </>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </Stack>
  );
}

export default BookingGrowthGuideDetail;
