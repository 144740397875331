/* eslint-disable prettier/prettier */
import {
  EyeIcon,
  EyeSlashIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { authLogin } from "@service/auth";
import { AuthLoginInput } from "@type/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CountryFlag } from "@type/country";
import { listFlags } from "components/flag";
import { getCookie, setCookie } from "@util/cookie";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import axios from "axios";
import PopoverFlag from "components/popover-flag";
import { catchErrorLog } from "@util/sentry";

function FormLogin() {
  const selectedCountry = getCookie("selectedCodeCountry") || "US";
  const navigate = useNavigate();
  const [showPassword, setShowPassowrd] = useState<boolean>(false);
  const [country, setCountry] = useState(
    listFlags.find((item) => item.code === selectedCountry)
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [errorForm, setErrorForm] = useState<string | undefined>(undefined);

  const open = Boolean(anchorEl);

  const {
    handleSubmit,
    reset,
    register,
    setError,
    formState: { errors },
  } = useForm<AuthLoginInput>();

  const handleOpenPhoneFlag = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerClosePhoneFlag = () => {
    setAnchorEl(null);
  };

  const handleChangeCountry = (item: CountryFlag) => {
    setCountry(item);
    setAnchorEl(null);
  };

  return (
    <>
      {errorForm && (
        <Box mb="24px">
          <Alert
            variant="standard"
            severity="error"
            sx={{ alignItems: "flex-start" }}
            icon={
              <ExclamationCircleIcon
                style={{ width: "16px", color: "#B30909" }}
              />
            }
          >
            {errorForm}
          </Alert>
        </Box>
      )}

      <form
        onSubmit={handleSubmit(async (data: AuthLoginInput) => {
          if (data.phoneNo === "") {
            setError("phoneNo", { message: "Please enter your phone number." });
            return;
          }
          if (data.password === "") {
            setError("password", { message: "Please enter your password." });
            return;
          }
          setLoading(true);
          try {
            const resp = await authLogin({
              phoneNo: `${country?.dialCode}${data.phoneNo}`,
              password: data.password,
            });
            if (resp.status === 200) {
              setCookie("authToken", resp.data.data.token);
              setCookie("selectedCodeCountry", country?.code);
              reset();
              navigate("/", { replace: true });
            } else {
              setErrorForm(resp.data.message);
            }
          } catch (err) {
            if (axios.isAxiosError(err)) {
              // @ts-ignore
              setErrorForm(err?.response?.data.message);
              catchErrorLog(err?.response?.data.message);
            }
          }
          setLoading(false);
        })}
      >
        <Stack direction="column">
          <FormControl sx={{ marginBottom: "16px" }}>
            <FormLabel color="secondary" sx={{ marginBottom: "8px" }}>
              Phone number
            </FormLabel>
            <Stack alignItems="center" sx={{ width: "100%" }}>
              <OutlinedInput
                color="secondary"
                sx={{ paddingLeft: "0px", width: "100%" }}
                autoComplete="off"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={!!errors.phoneNo}
                {...register("phoneNo", { required: true })}
                startAdornment={
                  <>
                    <button
                      type="button"
                      style={{
                        background: "#FFFFFF",
                        border: "none",
                        margin: "0px",
                        display: "flex",
                        flexDirection: "row",
                        width: "72px",
                        height: "46px",
                        padding: "8px 12px",
                        borderRight: "1px solid #D8D8D8",
                        marginRight: "12px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        cursor: "pointer",
                        gap: "8px",
                        placeContent: "center space-between",
                        alignItems: "center",
                      }}
                      onClick={handleOpenPhoneFlag}
                    >
                      <img
                        src={country?.flag}
                        alt="flag"
                        style={{ userSelect: "none" }}
                      />
                      <ChevronDownIcon
                        style={{ width: "16px", color: "#000000DE" }}
                      />
                    </button>
                    <PopoverFlag
                      open={open}
                      selected={country}
                      anchorEl={anchorEl}
                      onClose={handlerClosePhoneFlag}
                      onChange={(item: CountryFlag) =>
                        handleChangeCountry(item)
                      }
                      sxPaper={{
                        marginTop: "8px",
                      }}
                    />
                    <InputAdornment position="start" sx={{ margin: "0px" }}>
                      {country?.dialCode}
                    </InputAdornment>
                  </>
                }
              />
            </Stack>
            {errors.phoneNo && (
              <FormHelperText error sx={{ margin: "8px 0px" }}>
                <Stack direction="row" gap="6px">
                  <ExclamationCircleIcon
                    style={{ width: "16px", color: "#B30909" }}
                  />
                  Please enter your phone number.
                </Stack>
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ marginBottom: "24px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              sx={{ marginBottom: "8px" }}
            >
              <FormLabel color="secondary">Password</FormLabel>

              <Link href="/forgot-password" underline="none">
                Forgot password?
              </Link>
            </Stack>
            <OutlinedInput
              color="secondary"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              // onChange={handleChangePassword}
              error={!!errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassowrd(!showPassword)}>
                    {showPassword ? (
                      <EyeIcon style={{ width: "20px" }} />
                    ) : (
                      <EyeSlashIcon style={{ width: "20px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <FormHelperText error sx={{ margin: "8px 0px" }}>
                <Stack direction="row" gap="6px">
                  <ExclamationCircleIcon
                    style={{ width: "16px", color: "#B30909" }}
                  />
                  Please enter your password.
                </Stack>
              </FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={isLoading}
            color="primary"
            size="large"
          >
            Log in
          </Button>
        </Stack>
      </form>
    </>
  );
}

export default FormLogin;
