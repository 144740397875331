/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { getUserSubscription } from "@service/user";
import { useSnackbar } from "notistack";
import { UserSubscriptionInfo } from "@type/user";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const UserSubscriptionContext = createContext<[boolean, UserSubscriptionInfo?]>(
  [false, undefined]
);

export const useUserSubscriptionContext = () => {
  return useContext(UserSubscriptionContext);
};

interface Props {
  children: React.ReactNode;
}

function UserSubscriptionProvider(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userSubscriptionInfo, setUserSubscriptionInfo] = useState<
    UserSubscriptionInfo | undefined
  >(undefined);

  useEffect(() => {
    setLoading(true);
    getUserSubscription()
      .then((resp) => {
        setUserSubscriptionInfo(resp.data.data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          enqueueSnackbar(e?.response?.data.message, { variant: "error" });
        }
        setLoading(false);
      });
  }, []);

  return (
    // @ts-ignore
    <UserSubscriptionContext.Provider value={[isLoading, userSubscriptionInfo]}>
      {props.children}
    </UserSubscriptionContext.Provider>
  );
}

export default UserSubscriptionProvider;
