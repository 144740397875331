/* eslint-disable prettier/prettier */
import {
  ArrowRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { calculateWeeksInRange, parseFormat } from "@util/date";
import { useState } from "react";
import { useUserSubscriptionContext } from "context/user-subscription-context";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import DialogSubscriptionCancel from "@component/dialog/dialog-subscription-cancel";
import DialogSubscriptionReactivate from "@component/dialog/dialog-subscription-reactivate";

function BannerSubscription() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isLoadingUserSubscription, userSubscriptionInfo] =
    useUserSubscriptionContext();
  const [isOpenDialogSubscriptionCancel, setOpenDialogSubscriptionCancel] =
    useState<boolean>(false);
  const [
    isOpenDialogSubscriptionReactivate,
    setOpenDialogSubscriptionReactivate,
  ] = useState<boolean>(false);

  const handleShowOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHideOption = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (isLoadingUserSubscription) {
    return;
  }

  return (
    <>
      <Box
        sx={{
          padding: "16px 24px",
          background: "#FFFFFFDE",
          border: "1px solid #2F4B7380",
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Stack
          direction={{
            lg: "row",
            md: "row",
            xs: "column",
          }}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          gap="16px"
        >
          <Stack
            direction="column"
            sx={{
              flexGrow: 1,
            }}
          >
            {userSubscriptionInfo?.status === "trialing" && (
              <>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  <span style={{ fontWeight: 700, color: "#000000DE" }}>
                    {calculateWeeksInRange(
                      new Date(userSubscriptionInfo?.trial_start || ""),
                      new Date(userSubscriptionInfo?.trial_end || "")
                    )}
                    -week Free Trial -
                  </span>{" "}
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#00000099",
                      fontStyle: "italic",
                    }}
                  >
                    ending{" "}
                    {parseFormat(
                      userSubscriptionInfo?.trial_end || "",
                      "DD/MM/YYYY"
                    )}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#000000DE",
                  }}
                >
                  Includes daily AI coaching and a Growth Guide session
                </Typography>
              </>
            )}
            {userSubscriptionInfo?.status === "active" && (
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                }}
              >
                <b>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {userSubscriptionInfo?.interval}
                  </span>{" "}
                  subscription -
                </b>{" "}
                {userSubscriptionInfo?.canceled_at ? (
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#D92323",
                      fontStyle: "italic",
                    }}
                  >
                    expiring{" "}
                    {parseFormat(
                      userSubscriptionInfo?.canceled_at || "",
                      "DD/MM/YYYY"
                    )}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#00000099",
                      fontStyle: "italic",
                    }}
                  >
                    next billing date{" "}
                    {parseFormat(
                      userSubscriptionInfo?.current_period_end || "",
                      "DD/MM/YYYY"
                    )}
                  </span>
                )}
              </Typography>
            )}
            {(userSubscriptionInfo?.status === "canceled" ||
              userSubscriptionInfo?.status === "not_subscribed") && (
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  You currently have no access to the services.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Subscribe today to unlock exclusive benefits with ourcoach
                </Typography>
              </Box>
            )}
          </Stack>
          {(userSubscriptionInfo?.status === "canceled" ||
            userSubscriptionInfo?.status === "not_subscribed" ||
            userSubscriptionInfo?.status === "trialing") && (
            <Button
              sx={{
                width: {
                  lg: "auto",
                  md: "auto",
                  xs: "100%",
                },
              }}
              endIcon={<ArrowRightIcon style={{ width: "20px" }} />}
              variant="contained"
              onClick={() => navigate("/subscription/plan")}
            >
              Upgrade to Premium
            </Button>
          )}
          {!userSubscriptionInfo?.canceled_at &&
            userSubscriptionInfo?.status === "active" && (
              <Stack
                direction="row"
                gap="16px"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Button
                  sx={{
                    width: {
                      lg: "auto",
                      md: "auto",
                      xs: "100%",
                    },
                  }}
                  endIcon={<ArrowRightIcon style={{ width: "20px" }} />}
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/subscription/plan")}
                >
                  View plans
                </Button>
                <IconButton
                  onClick={handleShowOption}
                  sx={{ padding: "5px", width: "34px", height: "34px" }}
                >
                  <EllipsisVerticalIcon style={{ width: "24px" }} />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleHideOption}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        top: "326px !important",
                      },
                    },
                  }}
                >
                  <Button
                    fullWidth
                    size="large"
                    sx={{ color: "#D92323", fontWeight: 400 }}
                    onClick={() => {
                      setOpenDialogSubscriptionCancel(true);
                      handleHideOption();
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </Popover>
              </Stack>
            )}
          {userSubscriptionInfo?.canceled_at &&
            userSubscriptionInfo?.status === "active" && (
              <Button
                sx={{
                  width: {
                    lg: "auto",
                    md: "auto",
                    xs: "100%",
                  },
                }}
                variant="contained"
                onClick={() => setOpenDialogSubscriptionReactivate(true)}
              >
                Reactivate plan
              </Button>
            )}
        </Stack>
      </Box>
      <DialogSubscriptionReactivate
        isOpen={isOpenDialogSubscriptionReactivate}
        userSubscriptionInfo={userSubscriptionInfo}
        onClose={() => {
          setOpenDialogSubscriptionReactivate(false);
        }}
      />
      <DialogSubscriptionCancel
        isOpen={isOpenDialogSubscriptionCancel}
        userSubscriptionInfo={userSubscriptionInfo}
        onClose={() => {
          setOpenDialogSubscriptionCancel(false);
          handleHideOption();
        }}
      />
    </>
  );
}

export default BannerSubscription;
