/* eslint-disable prettier/prettier */
import { authRequestOTP, authVerifyOTP } from "@service/auth";
import { AuthOTP } from "@type/auth";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { hideEmail, hidePhoneNumber } from "@util/string";
import { setCookie } from "@util/cookie";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { catchErrorLog } from "@util/sentry";

interface Props {
  onCallback: Function;
}

function FormInputOTP(props: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [errorForm, setErrorForm] = useState<string | undefined>(undefined);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const { handleSubmit, reset } = useForm<AuthOTP>();

  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value) || value.length > 1) return;
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    event:
      | React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined,
    // eslint-disable-next-line prettier/prettier
    index: number
  ) => {
    // @ts-ignore
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleResendCode = async () => {
    setOtp(new Array(6).fill(""));
    setErrorForm(undefined);
    setLoading(true);
    try {
      await authRequestOTP(
        // eslint-disable-next-line prettier/prettier
        sessionStorage.getItem("phoneVerify") ?? ""
      );
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // @ts-ignore
        setErrorForm(err?.response?.data.message);
        catchErrorLog(err?.response?.data.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: "16px" }}>
        Verify phone number
      </Typography>
      {process.env.REACT_APP_OTP_METHOD === "email" ? (
        <Typography
          variant="body1"
          sx={{ color: "#000000DE", marginBottom: "32px" }}
        >
          We sent you a 6-digit verification code to your email address{" "}
          <b>{hideEmail(sessionStorage.getItem("emailVerify") ?? "")}</b>. Enter
          it below to verify your number.
        </Typography>
      ) : (
        <Typography
          variant="body1"
          sx={{ color: "#000000DE", marginBottom: "32px" }}
        >
          We sent you a 6-digit verification code to your WhatsApp number{" "}
          <b>{hidePhoneNumber(sessionStorage.getItem("phoneVerify") ?? "")}</b>.
          Enter it below to verify your number.
        </Typography>
      )}

      <form
        onSubmit={handleSubmit(async () => {
          setLoading(true);
          try {
            const isForgotPassword: boolean =
              Boolean(sessionStorage.getItem("is_forgot_password")) ?? false;
            const phoneNo: string = sessionStorage.getItem("phoneVerify") ?? "";

            const resp = await authVerifyOTP(phoneNo, otp.join(""));
            if (resp.status === 200) {
              props.onCallback();
              reset();
              setOtp(new Array(6).fill(""));

              if (!isForgotPassword) {
                setCookie("authToken", resp.data.data.token);
                sessionStorage.setItem("userId", resp.data.data.userId);
              } else {
                sessionStorage.removeItem("is_forgot_password");
              }
              sessionStorage.setItem(
                "token_reset_password",
                resp.data.data.token_reset_pass
              );
            } else {
              setErrorForm(resp.data.message);
            }
          } catch (err) {
            if (axios.isAxiosError(err)) {
              // @ts-ignore
              setErrorForm(err?.response?.data.message);
              catchErrorLog(err?.response?.data.message);
            }
          }
          setLoading(false);
        })}
      >
        <Stack direction="row" gap={1} sx={{ marginBottom: "24px" }}>
          <Typography variant="body2">Didn’t receive it?</Typography>
          <Button
            variant="text"
            color="inherit"
            disabled={isLoading}
            disableRipple
            disableTouchRipple
            disableElevation
            sx={{
              padding: "0px",
              fontFamily: `Inter, sans-serif`,
              fontSize: "14px",
              fontWeight: "400",
              height: "22px",
              lineHeight: "21px",
              letterSpacing: "0.15px",
              color: "#2F4B73",
              "&:hover": {
                background: "transparent",
                textDecoration: "underline",
              },
            }}
            onClick={handleResendCode}
          >
            Resend code
          </Button>
        </Stack>
        <Stack direction="column" justifyContent="space-between">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            gap={1}
            sx={{ width: "100%" }}
          >
            {otp.map((digit, index) => (
              <>
                <OutlinedInput
                  key={index}
                  error={!!errorForm}
                  value={digit}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleChange(e.target.value, index)}
                  // @ts-ignore
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      fontSize: "1.5rem",
                      width: "22px",
                      height: "22px",
                    },
                  }}
                  sx={{
                    "input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    margin: "0",
                    marginRight: {
                      md: index === 2 ? "34px" : "0px",
                      xs: index === 2 ? "20px" : "0px",
                    },
                  }}
                />
              </>
            ))}
          </Stack>
          {errorForm && (
            <Alert
              severity="error"
              variant="standard"
              sx={{
                border: "none !important",
                background: "transparent !important",
                color: "#D92323",
                alignContent: "center",
                alignItems: "flex-start",
                gap: "6px",
                paddingLeft: "0px",
                paddingRight: "0px",
                ".MuiAlert-icon": {
                  padding: "0px",
                  paddingTop: "3px",
                  marginRight: "0px",
                },
                ".MuiAlert-message": {
                  padding: "0px",
                },
              }}
              icon={
                <ExclamationCircleIcon
                  style={{ width: "16px", color: "#D92323" }}
                />
              }
            >
              {errorForm}
            </Alert>
          )}
        </Stack>
        <Button
          sx={{ marginTop: "24px" }}
          fullWidth
          type="submit"
          variant="contained"
          disabled={isLoading}
          color="primary"
          size="large"
        >
          Verify number
        </Button>
      </form>
      <Box sx={{ marginTop: "16px" }}>
        <Typography
          variant="caption"
          sx={{ color: "#00000099", letterSpacing: "0.15px" }}
        >
          To keep your account secure, your phone number must be verified before
          getting started.
        </Typography>
      </Box>
    </>
  );
}

export default FormInputOTP;
