import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";

export const AlertStyled = styled(Alert)({
  "&.MuiAlert-root": {
    backgroundColor: "#FDF8F4 !important",
    border: "1px solid #2F4B73",
    padding: "20px",
    borderRadius: "16px",
    borderTopLeftRadius: "0px",
    gap: "18px",
    justifyContent: "space-between",
    ".MuiAlertTitle-root": {
      color: "#000000DE",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    ".MuiAlert-action": {
      padding: "0px",
      margin: "0px",
      width: "20px",
    },
    ".MuiAlert-message": {
      padding: "0px",
    },
  },
});
