import { useEffect, useState } from "react";
import { getBookingGrowthGuideInfo } from "@service/booking";
import { BookingInfo } from "@type/booking";
import { getEndOfMonth, parseFormat } from "@util/date";
import { UserSubscriptionInfo, UserSummary } from "@type/user";
import { convertToFormattedText } from "@util/convert";
import { UlStyled } from "./styled";
import { catchErrorLog } from "@util/sentry";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "components/skeleton";

interface Props {
  isWelcomeMessage?: boolean;
  isLoadingSummary: boolean;
  isLock?: boolean;
  userSubscriptionInfo?: UserSubscriptionInfo;
  userSummary?: UserSummary;
  countReload: number;
  sessionSlot: number;
  onBooking: () => void;
  onBookingDetail: () => void;
}

function BookGrowthGuide(props: Props) {
  const [isLoadingBookingInfo, setLoadingBookingInfo] =
    useState<boolean>(false);
  const [bookingInfo, setBookingInfo] = useState<BookingInfo | undefined>(
    // eslint-disable-next-line prettier/prettier
    undefined
  );

  const reloadBookingInfo = () => {
    setLoadingBookingInfo(true);
    getBookingGrowthGuideInfo()
      .then((resp) => {
        setLoadingBookingInfo(false);
        setBookingInfo(resp.data.data);
      })
      .catch((err) => {
        setLoadingBookingInfo(false);
        catchErrorLog(err?.response?.data.message);
      });
  };

  useEffect(() => {
    reloadBookingInfo();
  }, []);

  useEffect(() => {
    if (props.countReload > 0) {
      reloadBookingInfo();
    }
  }, [props.countReload]);

  return (
    <Box sx={{ padding: "16px", width: { md: "490px", xs: "auto" } }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        {isLoadingBookingInfo || props.isLoadingSummary ? (
          <Skeleton sx={{ width: "100%", height: "24px" }} />
        ) : (
          <>
            {!bookingInfo ? (
              <>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#000000DE",
                    marginBottom: props.isWelcomeMessage ? "4px" : "0px",
                  }}
                >
                  {props.userSubscriptionInfo?.status === "canceled" ||
                  props.userSubscriptionInfo?.status === "not_subscribed"
                    ? "You have no access to the sessions"
                    : props.sessionSlot === 0
                      ? "Session completed!"
                      : !props.isWelcomeMessage
                        ? "Schedule a Growth Guide session"
                        : "Book a Growth Guide session"}
                </Typography>
                {!props.isWelcomeMessage && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      letterSpacing: "0.15px",
                      color: "#00000099",
                      margin: "8px 0px",
                    }}
                  >
                    {props.userSubscriptionInfo?.status === "canceled" ||
                    props.userSubscriptionInfo?.status === "not_subscribed"
                      ? "Subscribe today to unlock exclusive benefits with ourcoach"
                      : props.sessionSlot === 0
                        ? `Your next session opens for booking on ${parseFormat(props.userSubscriptionInfo?.current_period_end || "", "DD/MM/YYYY")}`
                        : `Schedule your session for this month before ${getEndOfMonth()}`}
                  </Typography>
                )}
              </>
            ) : (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  color: "#000000DE",
                  paddingBottom: "8px",
                }}
              >
                {`Upcoming: ${parseFormat(bookingInfo?.started_at || "", "ddd, DD MMM YYYY, HH:mm")}`}
              </Typography>
            )}
          </>
        )}

        <Divider sx={{ color: "#00000014", marginBottom: "12px" }} />
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "18px",
            letterSpacing: "0.15px",
            color: "#000000DE",
            marginBottom: "12px",
          }}
        >
          Topics you could explore with your Growth Guide:
        </Typography>
        <Box
          sx={{
            borderRadius: "4px",
            padding: "12px 8px",
            background: "#0000000A",
            border: "1px solid #E5E5E5",
            marginBottom: props.isWelcomeMessage ? "0px" : "16px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {props.isLoadingSummary ? (
            <Stack gap="8px">
              <Skeleton sx={{ width: "80%", height: "12px" }} />
              <Skeleton sx={{ width: "100%", height: "12px" }} />
              <Skeleton sx={{ width: "40%", height: "12px" }} />
            </Stack>
          ) : (
            <Box
              sx={{
                height: "100%",
                overflowY: "hidden",
                paddingRight: "16px",
                "&:hover": {
                  paddingRight: "6px",
                  overflowY: "scroll",
                },
              }}
            >
              <UlStyled>
                {props.userSummary?.users_growth_guide_preparation_brief.map(
                  (note, index) => (
                    <li key={index}>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#000000DE",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {convertToFormattedText(note.value)}
                      </Typography>
                    </li>
                    // eslint-disable-next-line prettier/prettier
                  )
                )}
              </UlStyled>
            </Box>
          )}
        </Box>
        {!props.isWelcomeMessage && (
          <>
            {!bookingInfo ? (
              props.sessionSlot > 0 && (
                <Button
                  disabled={props.isLoadingSummary || props.isLock}
                  variant="contained"
                  onClick={props.onBooking}
                >
                  {props.isWelcomeMessage
                    ? "Schedule Growth Guide session"
                    : "Book a session"}
                </Button>
              )
            ) : (
              <Button
                disabled={isLoadingBookingInfo}
                variant="contained"
                color="secondary"
                onClick={props.onBookingDetail}
              >
                See booking details
              </Button>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}

export default BookGrowthGuide;
